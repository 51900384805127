import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function Freedom() {
  return (
    <div className='main-container'>
      <div className='breadcrumb container'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/opinion18' className='breadcrumb-active'>
          Մայրեր
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong> Մայրեր</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item15.jpg' alt='icon' className='img-fluid' />
        <p className='mt-3'>ԵՐԵՒԱՆ</p>
        <p className='mt-3'>ԱՐԵՒՄՏԱՀԱՅԵՐԷՆ ԹԱՏԵՐԱԿԱՆ ՀՆՉԵՂՈՒԹԵԱՆ ԿԱՐՕՏ ԱՆՁԵՐ</p>
        <p>
          Ընթացիկ ամսուան 26-ին, Երեւանի ՆՓԱԿ (Նորարար Փորձառական Արուեստի Կեդրոն) կեդրոնէն ներս տեղի ունեցաւ
          արեւմտահայերէն առաջին թատերական ընթերցումը:
        </p>
        <p>
          Վահան Թէքէեանի «Մայրեր» թատերակի ընթերցումը մեկնարկն էր շարունակական շարքի մը, որուն մտայղացումը յառաջացած է
          Արբի Յովհաննիսեանի երկարատեւ պրպտումներով: Արբին տարիներէ ի վեր արխիւներէն կը պեղէ թատերգութիւն հեղինակած
          գրողներու, ստուերի մէջ մնացած գրութիւնները, որոնցմէ ընտրանի մը արդէն հրատարակուած է ՄԷԿ ԱՐԱՐ եւ ՏԱՍՆԵՐԿՈՒ
          ԻՒՐՕՐԻՆԱԿ ԹԱՏԵՐԿՆԵՐ շարքերուն մէջ, Հրատարակուած Փարիզի Հայ Թատրոնի ընկերակցութեան կողմէ։ Ահաւասիկ, այդ շարքէն
          երեք թատերգութիւններ հանրութեան պիտի մատուցուին «Թեք Տեղ»-ի նախաձեռնութեամբ եւ Գալուստ Կիւլպէնկեան հիմնարկի
          հովանաւորութեամբ:
        </p>
        <p>
          “Արեւմտահայերէնը արեւելահայերէնին համարժէք պէտք է հնչէ Հայաստանի մէջ, պէտք է հնչէ առանց լեզուն կապկելու՝
          Արեւմտահայերու շրթներէն, անոր մեղեդայնութիւնը պէտք է հասանելի ըլլայ բոլորին։ Արեւմտահայերէնը ունի մշակութային
          մեծ ժառանգութիւն, որ անպայման Հայաստանի մէջ լսելի եւ տեսանելի ներկայութիւն մը պէտք է ունենայ”,– յայտնեց
          բեմադիր-ղեկավար Նանոր Պետրոսեան, օրուան թատերական ընթերցումին համադրողը:
        </p>
        <p>
          Դիմագիրքով բաց ծանուցումին արձագանգող հայրենադարձ Սփիւռքահայերու՝ Սուրիահայ, Ֆրանսահայ, Լիբանանահայ, յոյլ մը
          անձերու պատկերաւոր ընթերցանութեամբ, տաւիղի թովիչ հնչիւններուն զուգահեռ, ներկայացուեցաւ Վահան Թէքէեանի «Մայրեր»
          թատերակը, որ փոխանցեց հեղինակին ապրած ու ցաւած պատկերները, որոնք որքա՜ն համահունչ են մեր ցաւոտ ներկային: Բեմին
          վրայ էին՝ Զեփիւռ Շահրոզեան, Դալար Սլիխանեան, Ազնիւ Ստեփանեան, Հրակ Կոճիկեան, Նուռ Անի, Աւետիս Ափոշեան, Մայտա
          Պոշկեզէնեան, Իգնասիօ Անալեան. երաժշտական ընկերակցութիւն՝ Սիփան Քէօրօղլեանի:
        </p>
        <p>
          Յաջորդ ընթերցումը Յակոբ Օշականի «Անվերնագիր» թատերգութիւնն է, որ տեղի պիտի ունենայ Նոյեմբեր ամսուն, որուն
          թատերական ընթերցանութեան բեմադրութիւնը պիտի ապահովէ Ֆրանսայէն Ժիրայր Չոլաքեան, որ Արբի Յովհաննիսեանի երկար
          տարիներու աշխատակիցը եղած է։ Ապա յառաջիկայ տարուան Մարտ ամսուն տեղի պիտի ունենայ Ժիրայր Աճէմեանի ստուերի մէջ
          մնացած թատերական մէկ ստեղծագործութեան ընթերցումը:
        </p>
        <p>
          Հետաքրքրական էր նկատել որ ակնդիրներուն մեծամասնութիւնը Արեւմտահայեր էին, արեւմտահայերէնով թատերական
          հնչեղութեան կարօտ անձեր:
        </p>
        <p>Անի Բրդոյեան-Ղազարեան </p>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freedom));
