import React from 'react';

import { withRouter } from 'react-router-dom';
import Topnav from './Topnav';

const MainLayout = ({ children }) => {
  return (
    <div className="root-inner">
      <div className='pr-3 float-right'>
        <Topnav  cls="button_language"/>
      </div>
      <header>
        <h1>
          <a className='navbar-brand' href='/'>
            <img src='images/logo.svg' className='img-fluid' alt='logo' title='logo' />
          </a>
        </h1>
      </header>
      {children}
    </div>
  );
};

export default withRouter(MainLayout);
