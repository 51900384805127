import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

let data = require('./lang/lang.json');

function Writing() {
  const text = data[localStorage.getItem('language')];
  const state ={
    title: text.writing,
    desc: text.writing_desc
  }

  const hosts = [
    {
        title: text.writing_title_1,
        desc: text.writing_desc_1,
        host: text.writing_host_1,
        image: "1.png"
    }
  ]
  return (
    <section className='main-container'>
      <div className='description-banner'>
        <h2 className='title-generic'>{state.title}</h2>
        <p dangerouslySetInnerHTML={{ __html: state.desc }}></p>
      </div>
      <div>
        {hosts.map(({title, desc, host, image}) =>
        <div className='generic-container'>
          <div className='row m-0'>
            <div className='we-read-box col-lg-6'>
              <img src={`images/writing/${image}`} className='img-fluid' alt='item' title='item' />
            </div>
            <div className='we-read-box col-lg-6'>
              <p>{title}</p>
              <p className='mb-0'>{host}</p>
              <div className='we-read-info'>
                <p dangerouslySetInnerHTML={{ __html: desc }}></p>
              </div>
            </div>
          </div>
        </div>
        )}
        <div className='col-12'>
          <img src='images/we-read/line1.svg' className='img-fluid' alt='item' title='item' />
        </div>
      </div>
    </section>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Writing));
