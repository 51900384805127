import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function Freedom() {
  return (
    <div className='main-container'>
      <div className='breadcrumb container'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/opinion18' className='breadcrumb-active'>
          ԱՆՎԵՐՆԱԳԻՐԸ
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong> ԱՆՎԵՐՆԱԳԻՐԸ</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item15.jpg' alt='icon' className='img-fluid' />
        <p className='mt-3'>
          Արեւմտահայերէնով թատերական ընթերցումի շարքին երկրորդ թատերգութեան ընթերցումը տեղի ունեցաւ 23 Օգոստոս 2021-ին
          Նորարար Փորձառական Արուեստի Կեդրոնին՝ ՆՓԱԿ-ի մէջ, Թեք Տեղ-ի նախաձեռնութեամբ եւ Գալուստ Կիւլպէնեան հիմնարկի
          հովանաւորութեամբ: Երկրորդ ընթերցումը Յակոբ Օշականի «Անվերնագիր» թատրոնն էր:
        </p>
        <p>
          Հանդիսատեսն ու դերասանը միաձուլող կլոր շարուած աթոռները կը շրջապատէին համեստ տեսքը թատերական հարթակի վերածուած
          դահլիճին: Դերասանները՝ դիտողներ եւ միեւնոյն ատեն շարժուն մասնակիցներ էին: Լռութիւն կը տիրէր, բան մը, որ
          անհրաժեշտ է դերերու անբարձրախօս հնչեղութեան, ձայներուն, ըստ դերերու, ելեւէջներուն, ինչպէս նաեւ փաստ մը,
          յաջողութեան մը, որ կ'ընթանայ դերերու ընթերցմամբ եւ կը լարէ ուշքն ու աչքը հանդիսատեսին, կը սրէ հետաքրքրութիւնը
          դէպքերու հերթականութեան տեղի ունեցող շղթային օղակները դիւրութեամբ կապելու:{' '}
        </p>
        <p>
          Երեւելի է աղէտը, այս պարագային, ապազգային անձի մը միջոցով, որ իր ձեռքերով իսկ վերջ դրաւ ընտանիքին պատուին եւ
          գոյութեան:
        </p>
        <p> Անմարդկային ձեւերէն միայն մէկը, որ կ'օգտագործուէր ի վնաս մեզի, մեր դէմ մեզ հանելով, թշնամացնելով:</p>
        <p>
          Դերերը արեւմտահայերէն հնչեղութեամբ, Պոլսոյ բարբառով, կը մեկնաբանուէին սահուն ընթերցմամբ, երբեմն նաեւ
          վարպետօրէն, հակառակ անոր որ ընթերցողներէն շատեր արեւելահայ էին եւ առաջին բեմական փորձառութիւնն էր անոնց:
        </p>
        <p>
          Հանդիսատեսը հաղորդ դարձաւ բարբառային լեզուին, այդ ժամանակամիջոցի դէպքերուն, անցուդարձերուն՝ ազգայինին,
          ապազգայինին, անբարոյականութեան, յեղափոխականին, իմաստունին, անգէտին եւ պահիկ մը ապրեցաւ աղէտին զուգահեռ սիրոյ
          ջերմութիւնը, գոյութիւնը, կեանքի դառնութիւններուն զուգահեռ քաղցրութիւնն զգաց սիրոյ, որուն ո՛չ աղէտը, ո՛չ
          սպառնալիքը, ո՛չ ալ մահը վերջակէտ մը կրնայ հանդիսանալ:
        </p>
        <p>
          Հանդիսութեան աւարտին ընթերցողներէն մին կը հարցնէր շնորհաւորողներուն՝ «Ձանցրացուցի՞չ էր», վստահաբար նկատի
          ունենալով թատերակին ժամէ մը աւելի տեւողութիւնը, պատասխանը հարկաւ ժխտական էր, փաստ մը, երեք շաբաթուան տքնանքին
          դրական արդիւնքին, իսկ դահլիճէն դուրս կը լսուէին՝
        </p>
        <p>-Անահիտին դերակատարը ի՛նչ լաւ խաղաց...</p>
        <p>-Վարպետը մեղմ ձայնով շատ տպաւորիչ էր...</p>
        <p>-Գերման զինուորին դերասանը իսկական գերմանի կը նմանէր...</p>
        <p>-Հապա Շուքրի պէյը՞...</p>
        <p>-Հապա Վահրա՞մը...</p>
        <p>-Տանտիկի՞նը... </p>
        <p>Եւ այսպէս բոլորը կը յիշուէին դրոշմուելով միտքերուն մէջ:</p>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freedom));
