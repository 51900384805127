const langReducer = (state = { language: null }, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      let x = { ...state, language: action.language };
      localStorage.setItem('language', action.language);
      return x;
    default:
      return state;
  }
};

export default langReducer;