import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './weconnect.css';
import './bem.css';
const data = require('./lang/lang.json');

class KondFest4 extends Component {
  state = {
    showItem: '',
  };

  render() {
    const text = data[localStorage.getItem('language')];

    return (
      <section className='main-container current-container slider-centered'>
        <div className='breadcrumb container'>
          <a href='/kond_fest'>{text.kond}/</a>
          <a href='/kondfest1' className='breadcrumb-active'>
            {text.kond1}
          </a>
        </div>
        <div className='section-details modal-slider'>
          <div className='row m-0 position-relative'>
            <div className='white-decoration'>
              <img src='images/white-svg.svg' alt='asv' />
            </div>
            <div className='col-lg-6 p-0 pr-lg-4'>
              <img src='images/kondFest1/img.jpg' alt='kondfest' />
            </div>
            <div className='main-description-banner col-lg-5'>
              <b>
                <p dangerouslySetInnerHTML={{ __html: text.kond1 }}></p>
              </b>
              <div className='info-block'>
                <p dangerouslySetInnerHTML={{ __html: text.kond_fest_1_desc }}></p>
              </div>
              <div className='info-block'>
                <p className='text-right' dangerouslySetInnerHTML={{ __html: text.kond_fest_1_desc_ad }}></p>
              </div>
              <br />

              <div className='row'>
                <i style={{ backgroundImage: "url('images/audio.png')", height: ' 25px', width: '25px' }}></i>
                <div className='info-block'>
                  <a
                    href='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1387722277&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true'
                    download={text.kond}
                  >
                    {text.downloadaudio}
                  </a>
                </div>
              </div>
              <br />
              <div className='row'>
                <i style={{ backgroundImage: "url('images/audio.png')", height: ' 25px', width: '25px' }}></i>
                <div className='info-block'>
                  <a
                    href='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1387724992&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true'
                    download={text.kond}
                  >
                    {text.downloadaudio}
                  </a>
                </div>
              </div>
              <br />
              <div className='row'>
                <i style={{ backgroundImage: "url('images/audio.png')", height: ' 25px', width: '25px' }}></i>
                <div className='info-block'>
                  <a
                    href='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1387725583&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true'
                    download={text.kond}
                  >
                    {text.downloadaudio}
                  </a>
                </div>
              </div>
              <br />
              <div className='row'>
                <i style={{ backgroundImage: "url('images/pdf.png')", height: ' 25px', width: '25px' }}></i>
                <div className='info-block'>
                  <a href={`images/kondFest1/${localStorage.getItem('language')}.pdf`} download={text.kond}>
                    {text.download}
                  </a>
                </div>
              </div>
            </div>
            <br />
            <a href='/kondFest2' className='col-lg-1 p-0 next-page desktop-next'>
              <p> {text.next}</p>
              <img src='images/arrow.svg' alt='' />
            </a>
          </div>

          <a href='/kondFest2' className='col-lg-1 p-0 next-page mobile-next'>
            <p> {text.next}</p>
            <img src='images/arrow.svg' alt='' />
          </a>
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KondFest4));
