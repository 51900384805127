import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
let data = require('./lang/lang.json');

function weRead() {
  const text = data[localStorage.getItem('language')];
  const prefix = 'we_read';

  return (
    <section className='main-container'>
      <div className='description-banner'>
        <h2 className='title-generic'>{text.weread}</h2>
        <p dangerouslySetInnerHTML={{ __html: text.read_desc }}></p>
      </div>
      <div className='col-12'>
        <img src='images/we-read/line2.svg' className='img-fluid' alt='item' title='item' />
      </div>
      <div className='generic-container'>
        <div className='row m-0 '>
          <div className='we-read-box col-lg-6'>
            <img src='images/we-read/1.png' className='img-fluid' alt='item' title='logo' />
          </div>
          <div className='we-read-box col-lg-6'>
            <p className='mb-0'>{text.read_8}</p>
            <p dangerouslySetInnerHTML={{ __html: text.read_playwright_8 }}></p>
            <p dangerouslySetInnerHTML={{ __html: text.read_cast_8 }}></p>
          </div>
        </div>
      </div>
      <div className='col-12'>
        <img src='images/we-read/line1.svg' className='img-fluid' alt='item' title='item' />
      </div>
      <div className='generic-container'>
        <div className='row m-0 flex-reverse'>
          <div className='we-read-box col-lg-6'>
            <p className='mb-0'>{text.read_9}</p>
            <p dangerouslySetInnerHTML={{ __html: text.read_playwright_9 }}></p>
            <p dangerouslySetInnerHTML={{ __html: text.read_cast_9 }}></p>
          </div>
          <div className='we-read-box col-lg-6'>
            <img src='images/we-read/2.png' className='img-fluid' alt='item' title='logo' />
          </div>
        </div>
      </div>
      <div className='col-12'>
        <img src='images/we-read/line2.svg' className='img-fluid' alt='item' title='item' />
      </div>
      <div className='generic-container'>
        <div className='row m-0 '>
          <div className='we-read-box col-lg-6'>
            <img src='images/we-read/we-read-item-6.jpg' className='img-fluid' alt='item' title='logo' />
          </div>
          <div className='we-read-box col-lg-6'>
            <p className='mb-0'>{text.read_7}</p>
            <p dangerouslySetInnerHTML={{ __html: text.read_playwright_7 }}></p>
            <p dangerouslySetInnerHTML={{ __html: text.read_cast_7 }}></p>
          </div>
        </div>
      </div>
      <div className='col-12'>
        <img src='images/we-read/line1.svg' className='img-fluid' alt='item' title='item' />
      </div>
      <div className='generic-container'>
        <div className='row m-0 flex-reverse'>
          <div className='we-read-box col-lg-6'>
            <p className='mb-0'>{text.read_6}</p>
            <p dangerouslySetInnerHTML={{ __html: text.read_playwright_6 }}></p>
            <p dangerouslySetInnerHTML={{ __html: text.read_by + text.read_cast_6 }}></p>
          </div>
          <div className='we-read-box col-lg-6'>
            <img src='images/we-read/we-read-item8.png' className='img-fluid' alt='item' title='logo' />
          </div>
        </div>
      </div>
      <div className='col-12'>
        <img src='images/we-read/line2.svg' className='img-fluid' alt='item' title='item' />
      </div>
      <div className='generic-container'>
        <div className='row m-0'>
          <div className='we-read-box col-lg-6'>
            <img src='images/we-read/we-read-item5.jpeg' className='img-fluid' alt='item' title='item' />
          </div>
          <div className='we-read-box col-lg-6'>
            <p className='mb-0'>{text.read_4}</p>
            <p dangerouslySetInnerHTML={{ __html: text.read_playwright_4 }}></p>
            <p dangerouslySetInnerHTML={{ __html: text.read_by + text.read_cast_4 }}></p>
          </div>
        </div>
      </div>
      <div className='col-12'>
        <img src='images/we-read/line1.svg' className='img-fluid' alt='item' title='item' />
      </div>
      <div className='generic-container'>
        <div className='row m-0 flex-reverse'>
          <div className='we-read-box col-lg-6'>
            <p className='mb-0'>{text.read_5}</p>
            <p dangerouslySetInnerHTML={{ __html: text.read_playwright_5 }}></p>
            <p dangerouslySetInnerHTML={{ __html: text.read_by + text.read_cast_5 }}></p>
          </div>
          <div className='we-read-box col-lg-6'>
            <img src='images/we-read/we-read-item4.jpeg' className='img-fluid' alt='item' title='item' />
          </div>
        </div>
      </div>
      <div className='col-12'>
        <img src='images/we-read/line2.svg' className='img-fluid' alt='item' title='item' />
      </div>
      <div className='generic-container'>
        <div className='row m-0'>
          <div className='we-read-box col-lg-6'>
            <img src='images/we-read/we-read-item3.jpeg' className='img-fluid' alt='item' title='item' />
          </div>
          <div className='we-read-box col-lg-6'>
            <p className='mb-0'>{text.read_2}</p>
            <p dangerouslySetInnerHTML={{ __html: text.read_playwright_2 }}></p>
            <p dangerouslySetInnerHTML={{ __html: text.read_by + text.read_cast_2 }}></p>
          </div>
        </div>
      </div>
      <div className='col-12'>
        <img src='images/we-read/line1.svg' className='img-fluid' alt='item' title='item' />
      </div>
      <div className='generic-container'>
        <div className='row m-0 flex-reverse'>
          <div className='we-read-box col-lg-6'>
            <p className='mb-0'>{text.read_3}</p>
            <p dangerouslySetInnerHTML={{ __html: text.read_playwright_3 }}></p>
            <p dangerouslySetInnerHTML={{ __html: text.read_by + text.read_cast_3 }}></p>
          </div>

          <div className='we-read-box col-lg-6'>
            <img src='images/we-read/we-read-item2.jpeg' className='img-fluid' alt='item' title='item' />
          </div>
        </div>
      </div>
      <div className='col-12'>
        <img src='images/we-read/line2.svg' className='img-fluid' alt='item' title='item' />
      </div>
      <div className='generic-container'>
        <div className='row m-0'>
          <div className='we-read-box col-lg-6'>
            <img src='images/we-read/we-read-item1.jpeg' className='img-fluid' alt='item' title='logo' />
          </div>
          <div className='we-read-box col-lg-6'>
            <p className='mb-0'>{text.read_1}</p>
            <p dangerouslySetInnerHTML={{ __html: text.read_playwright_1 }}></p>
            <p dangerouslySetInnerHTML={{ __html: text.read_by + text.read_cast_1 }}></p>
          </div>
        </div>
      </div>
    </section>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(weRead));
