import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function Freedom() {
  return (
    <div className='main-container'>
      <div className='breadcrumb container'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/opinion16' className='breadcrumb-active'>
          Թատերական կյանք, թե կյանքանման թատրոն
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong> Թատերական կյանք, թե կյանքանման թատրոն</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item13.jpeg' alt='icon' className='img-fluid' />
        <p className='mt-3'>
          Թատերական կյանք, թե կյանքանման թատրոն: Վաղուց թատրոն չէինք գնացել և ահա, մի օր, մի քանի հիացական ստատուսներ
          կարդալով Թեք տեղ թատերախմբի նոր ներկայացման մասին, առա տոմսեր: Թեք տեղի ներկայացումները (առաջին անգամ այցելողի
          համար) սկսվում են արդեն ուղղակի շենքը գտնելու պահից: Հին, պոստապոկալիպկիտ հսկա շենք՝ նախկին Կույրերի ֆաբրիկա,
          կիսամութ ու կիսալքված միջացքներով ու նեղ աստիճաններով: Երբ գտանք ճիշտ հարկը, մի քանի րոպե չէինք կարողանում
          գտնել ճիշտ դուռը: Սկզբում մտանք անմարդաբնակ մի հացի փուռ, հետո մի դուռ բացեցինք՝ վերելակ էր (էն Միլա Յովովիչով
          վատ կինոներից ոնց որ լիներ, ուղղակի առանց զոմբիների), մյուսը դռները փակ էին և հանկարծ՝ Կահույքի պատվերներ
          ցուցանակի կողքի դուռը մի փոքր ուշացումով մեզ հասցրեց Թեք տեղի բեմ: Դե, ոչ էնքան բեմ: Որքան սև պատերով ու մեծ
          պատուհանով դահլիճ, որտեղ շրջանով շարված աթոռների նստած մարդիկ (մեծամասամբ կանայք) էին: Էդ մարդկանցից մեկը
          խոսում էր: Երիտասարդ կին էր, պատմում էր մոր հետ իր լարված հարաբերությունների մասին: Որ մայրը մահացել էր, իսկ
          ինքը կարող էր գնալ գերեզմանոց ու կռվել մահացածի հետ, որը ծնել էր էդ կնոջը առանց վերջինիս ցանկության: Մոնոլոգի
          ընթացքում ինչ-որ տեղից ջրի չփչփոցներ էին լսվում, իսկ առաստաղին էդ ջուրն երևում էր՝ լրիվ պոֆիգիստավարի ծփում էր
          իրա համար, մինչև տիկինը պատմում էր իրեն՝ ջրին, իր դարդերը: Մի քիչ միթավուր էր սկզբում, որովհետև, դե ոնց որ էն
          անանուն մարդկանց խմբակներում լինես, որտեղ մարդիկ իրանց ռեալ դարդերն են պատմում, իսկ դու չգիտես ինչով օգնես: Դե
          որտև հոգեբան չես: Ու հետո, մտածում ես, ինչի՞ ա ինձ պատմում իրա էս անձնական խնդիրները: Հետո մյուս աղջիկը սկսեց
          պատմել թե ոնց ընտանեկան լոքշից փրկվելու համար՝ ամուսնուն սիրելով, սկսեց լևի գնալ: Էլի միտեսակ միթավուր էր:
          Հետո կողքինս հանկարծ խոսեց դիկտատորի տատիկի մասին, որը նման չէր մեր պատկերացրած տատիկներին ու թույնում էր
          աղջկա կյանքը: Թատրո՞ն էր, կյա՞նք էր, միջի՞ն ինչ-որ բան էր: Բայց լսում ես, լսում ես: Հոգեբան չես, իհարկե, բայց
          նաև էդքան էլ հանդիսատես չես, հանդիսատեսը կողքից ա նայում, իսկ ստեղ դու մեջն ես: Հերոսները ոչ թե բարձր բեմից
          անդեմ դահլիճին են պատմում, այլ իրանց կողքը նստած (իրանց նմա՞ն) մարդկանց: Ու մի երկու մոնոլոգ հետո հասկանում
          ես, որ սրանք ոչ թե հերոսներ են, ոչ թե դրամատուրգի հորինվածքներ, այլ հենց իսկական, մեր մեջ ապրող, հայ, հայոց
          կանայք են, դու էլ ջուրն ես, որովհետև խեղճերը իրանց հուզող պատմությունները (բնավ ոչ միշտ դարդոտ ու ողբալի,
          ուղղակի հուզող, անհանգստացնող) մեծամասմբ կարող են պատմել միայն ջրին, որը կլսի, ավելորդ հարցեր չի տա, կխշխշա ու
          կգնա: Էդ կանայք էլ մի քիչ իրանց հանգիստ կզգան, որովհեջև ջուրը հանգստացնող ա: Ջուրը բարի ա: Իսկ հետո պարզում
          ես, որ էս բոլոր մոնոլոգները իսկապես իրական կանանց պատմություններ էին, իսկ Թեք տեղի դերասանները սիրողներ են,
          որոնք իրանց սովորական կյանքում տեղ են գտնում անսովոր թատրոնում մի հավես բան անելու համար: Դու էլ ինչ լավ ա, որ
          գտար մի երկու ժամ, որ Կույրերի ֆաբրիկայում քեզ համբերատար լսող ջուր զգաս:
        </p>
        <p>Արտավազդ Եղիազարյան</p>
        <p>Medium.com</p>
        <p>24.03.2019</p>
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freedom));
