import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function Freedom() {
  return (
    <div className='main-container'>
      <div className='breadcrumb container'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/opinion15' className='breadcrumb-active'>
          Թումանյանի “Մարոն” ՆՓԱԿ-ում
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong> Թումանյանի “Մարոն” ՆՓԱԿ-ում</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item12.jpeg' alt='icon' className='img-fluid' />
        <p className='mt-3'>
          Հովհաննես Թումանյանի “Մարոն” պոեմի հիման վրա նոյեմբերի 9ին ՆՓԱԿում “Մարո” ներկայացումն էր: Բեմադրման ռեժիսորը
          Նանոր Պետրոսյանն է, ում հասարակությունը կարող է ճանաչել “Բռնաբարություն” ներկայացումից, որ բեմադրվել է անցյալ
          տարի: Երկու ներկայացումները ինձ թույլ են տալիս կարծել, որ ռեժիսորի աշխատանքային մեթոդն է խնդրականացնել
          թատրոնը` որպես միջոց, մակերես բերել այն, ինչ դասական թատրոնում գաղտնի է մնում: Այլ կերպ ասած` գաղտնազերծել,
          կամ ինչ-որ առումով կարելի է ասել նաև` ապամիֆականացնել թատրոն-միջոցը: Ինձ հավակնություն թույլ կտամ ասելու, որ
          քանի որ արվեստի միջոցի խնդրականացումը մոդեռնիստական քայլ է, “Մարոն” էլ մոդեռնիստական բեմադրություն է, որ հաճախ
          չի հանդիպում հայ թատրոնում: Ուրեմն ինչպե՞ս էր խնդրականացվում միջոցը: Մարոյի 12 դերասաններից երկուսն են միայն,
          որ թատերական կրթություն ունեն: ԴերԱՍԱՆ-ի ֆունկցիան կարծես փոխվում է այս ներկայացման մեջ: Նկատում ենք, որ այս
          բառն ինքնին իր մեջ ունի “ուրիշ մի դեր ասելու” իմաստը, իսկ ասելը ենթադրում է կոնկրետ բան ասել: Սակայն այս
          բեմում ամեն չդերասան ասում է իր սեփական դերը ու որոշակի ազատություն ունի սպոնտան ստեղծագործելու միջոցով
          կառուցել իր կերպարը: Հարկ է շեշտել, իհարկե, նախորդ նախադասության “որոշակին”, որովհետև ամեն դեպքում
          ներկայացումից ռեժիսորը հստակ կառուցվածք է ստացել: “Դերասանական խաղը”, որ հաճախ առօրյայում նույնիսկ որպես
          կեղծության, շինծու լինելու հոմանիշ է օգտագործվում, այստեղ հասցված է գրոտեսկի: Կերպարները բացել են խաղաքարտերն
          ու աչքդ են մտցնում, որ խաղում են ու բեմում կատարվածն ամենևին էլ ըստ քո պատկերացումների կյանքի մի կտոր չէ`
          բարձրացված վեր: Բեմում ոչինչ չի աշխատում բացի չդերասանից: Համարյա ոչ մի դեկոր օգտագործված չէ, ձայները դուրս են
          գալիս ՄԻԱՅՆ դերասանների բերանից: Թատրոնի բնականության պատրանքը կոտրվում է, երբ, օրինակ, դերասանը առանց ավելի
          ավլում է հատակը և կողքից մեկը անթաքույց ավելի ձայն է հանում: Մինչև ներկայացումը շատերի մոտ էր տարակուսանք, թե
          երեք էջանոց մի պոեմ, ուր խոսում է միայն հեղինակը, ինչպե՞ս է բեմադրվելու տասներկու հոգով` առանց Թումանյանից մի
          բառ այս կողմ-այն կողմի: Ռեժիսորն այստեղ օգտագործել էր Ջիբրիշ կոչվող թատերական լեզուն` առանց բառերի,
          քերականության, իմաստների, հիմնված զգացոողությունների վրա և ավելի շատ ձայնարկությունների տպավորություն ստեղծող:
          Այս ամենը նշանակում է նաև լեզու` առանց տեղային կոնկրետ հիմք շեշտելու, ինչը անխուսափելի կլիներ ցանկացած այլ
          լեզվի դեպքում: Այս իմաստով ներկայացումը կարծես թափառաշրջիկ լիներ` առանց կոնկրետ տեղ նշելու կախվում էր
          յուրաքանչյուր համայնքի գլխին: Համայնքի որոշիչ դերը յուրաքանչյուրի ճակատագրում: Սրա մասին էին պոեմը բանբասում
          տարբեր հերոսները` մարոյի ընկերուհիները, բանբասկոտ կինը, ջադու պառավը, հովիվները, տիրացուն, խենթը:
        </p>

        <p>նարե սահակյան</p>
        <p>culture.am</p>
        <p>14.11.2013</p>
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freedom));
