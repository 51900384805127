import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import Slider from 'react-slick';
import './weplay.css';
import './bem.css';
let data = require('./lang/lang.json');

function coming() {
  const text = data[localStorage.getItem('language')];
  const slickerSettings = {
    customPaging: (i) => {
      if (i > 16) {
        return <div></div>;
      } else {
        return <button>i</button>;
      }
    },
    infinite: true,
    centerPadding: '0',
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 500,
    centerMode: true,
    variableWidth: false,
    className: 'bem',
    centerMargin: '0',
    adaptiveHeight: false,
    // autoplay: true,
    dots: true,
    prevArrow: <div />,
    nextArrow: <div />,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '0',
          slidesToShow: 1,
          slidesToScroll: 0,
          adaptiveHeight: false,
          dots: true,
          prevArrow: <div />,
          nextArrow: <div />,
        },
      },
    ],
  };

  const comingData = [
    {
      title: text.coming_unicef,
      date: text.date_unicef,
      image: 'unicef.png',
    },
    {
      title: text.coming_haykuhi,
      date: text.date_haykuhi,
      image: 'haykuhi.png',
    },
    {
      title: text.coming_xalaphyan,
      date: text.date_xalaphyan,
      image: 'xalaphyan.png',
    },
    {
      title: text.coming_clothes,
      date: text.date_clothes,
      image: 'clothes.png',
    },
    {
      title: text.coming_pics,
      date: text.date_pics,
      image: 'pics.png',
    },
    {
      title: text.coming_attar,
      date: text.date_attar,
      image: 'attar.png',
    },
  ];
  return (
    <section className='main-container main-container-decoration we-play-container position-relative  container-not-hover'>
      <div className='description-banner'>
        <h2 className='title-generic m-auto '>{text.coming}</h2>
      </div>
      <section className='m-auto center-slider coming-container'>
        <Slider {...slickerSettings}>
          {comingData.map(({ title, date, image }) => (
            <div className='slide' key={title}>
              <div className='slider-title'>
                <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
                <p className='date text-center '>{date}</p>
              </div>
              <div className='child-element'>
                <div>
                  <img src={`images/coming-soon/${image}`} alt='icon' className='img-fluid' />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </section>
    </section>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(coming));
