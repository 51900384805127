import Slider from 'react-slick';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import './bem.css';
const data = require('./lang/lang.json');

class Corps extends Component {
  state = {
    showItem: '',
  };

  render() {
    const text = data[localStorage.getItem('language')];
    const slickerSettings = {
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 2,
      className: 'bem slider-nav the-corps-slick',
      centerMode: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            adaptiveHeight: true,
          },
        },
      ],
    };
    return (
      <section className='main-container current-container'>
        <div className='breadcrumb'>
          <a href='/weConnect'>{text.we_connect} /</a>
          <a href='/corps' className='breadcrumb-active'>
            {text.connect_corpse_title}
          </a>
        </div>
        <div className='section-details modal-slider'>
          <div className='row m-0'>
            <div className='col-lg-6 pl-0'>
              <img src='images/the-corps/the-corps.jpeg' alt='' />
            </div>
            <div className='main-description-banner col-lg-5 p-lg-0'>
              <h2 className='title-generic text-uppercase'>{text.connect_corpse_title}</h2>
              <p>{text.connect_corpse_subtitle}</p>
              <div className='info-block pre'>{text.connect_corpse_text}</div>
            </div>
            <a href='/animalMasquerade' className='col-lg-1 p-0 next-page'>
              <p> {text.next}</p>
              <img src='images/arrow.svg' alt='' />
            </a>
          </div>

          <Slider {...slickerSettings}>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/the-corps/the-corps1.jpeg' alt='' data-target='#carouselGallery' data-slide-to='0' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/the-corps/the-corps2.jpeg' alt='' data-target='#carouselGallery' data-slide-to='1' />
            </div>
          </Slider>
          <div className='modal fade' id='GalleryModal' tabIndex='-1' role='dialog' aria-hidden='true'>
            <div className='modal-dialog' role='document'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                <div className='modal-body'>
                  <div id='carouselGallery' className='carousel slide' data-ride='carousel'>
                    <div className='carousel-inner'>
                      <div className='carousel-item active'>
                        <img className='d-block w-100' src='images/the-corps/the-corps1.jpeg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/the-corps/the-corps2.jpeg' alt='' />
                      </div>
                    </div>
                    <a className='carousel-control-prev' href='#carouselGallery' role='button' data-slide='prev'>
                      <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                      <span className='sr-only'>Previous</span>
                    </a>
                    <a className='carousel-control-next' href='#carouselGallery' role='button' data-slide='next'>
                      <span className='carousel-control-next-icon' aria-hidden='true'></span>
                      <span className='sr-only'>Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Corps));
