import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './bem.css';
const data = require('./lang/lang.json');

const Unread = () => {
  const text = data[localStorage.getItem('language')];
  const state = data[localStorage.getItem('language')];
  return (
    <section className='main-container current-container'>
      <div className='breadcrumb container'>
        <a href='/kond_fest'>{text.kond}/</a>
        <a href='/unread' className='breadcrumb-active'>
          {text.unread_title}
        </a>
      </div>
      <div className='section-details'>
        <div className='row m-0 position-relative'>
          <div className='white-decoration'>
            <img src='images/white-svg.svg' alt='asv' />
          </div>
          <div className='col-lg-6 p-0 pr-lg-4'>
            <img src='images/unread/image.png' alt='' />
          </div>
          <div className='main-description-banner col-lg-5 p-0'>
            <p className='mb-0'> {text.unread_title}</p>
            <div className='info-block' dangerouslySetInnerHTML={{ __html: text.unread_text_1 }}></div>
            <div className='info-block' dangerouslySetInnerHTML={{ __html: text.unread_text_2 }}></div>
            <div className='info-block' dangerouslySetInnerHTML={{ __html: text.unread_text_3 }}></div>
          </div>
        </div>
        <div className='col-lg-6  m-auto'>
          {[...Array(7)].map((e, i) => {
            const title = `unread_story_${i + 1}_title`;
            const desc = `unread_story_${i + 1}_description`;
            const link = `unread_story_${i + 1}_link`;
            return (
              <div className='unrecognized-container'>
                <h2 dangerouslySetInnerHTML={{ __html: text[title] }}></h2>
                <h2 dangerouslySetInnerHTML={{ __html: text[desc] }}></h2>
                <div className='video-link'>
                  <iframe
                    width='560'
                    height='315'
                    src={text[link]}
                    title='YouTube video player'
                    frameborder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Unread));
