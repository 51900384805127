import Slider from 'react-slick';
import { withRouter } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import './bem.css';
let data = require('./lang/lang.json');

function TechAmateur() {
  const text = data[localStorage.getItem('language')];
  const slickerSettings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    centerMode: true,
    className: 'bem',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          adaptiveHeight: true,
        },
      },
    ],
  };
  return (
    <section className='main-container current-container'>
      <div className='breadcrumb'>
        <a href='/lab'>Լաբ /</a>
        <a href='/techAmateur' className='breadcrumb-active'>
          {text.lab_tech_amateur_title}
        </a>
      </div>
      <div className='section-details modal-slider'>
        <div className='row m-0'>
          <div className='col-2'></div>
          <div className='col-lg-3 pl-0'>
            <img src='images/tech-amateur/tech-amateur-item.jpg' alt='' />
          </div>
          <div className='main-description-banner col-lg-5'>
            <h2 className='title-generic text-uppercase'>{text.lab_tech_amateur_title}</h2>
            <p>{text.lab_tech_amateur_desc}</p>
            <div className='row sml-block'>
              <a href='/line' className='col-lg-5'>
                <p>«Գիծը» 2017</p>
                <p>
                  Ըստ Ի․ Հորովիցի <br /> համանուն թատերգության
                </p>
                <img src='images/slider-item4.jpg' alt='' />
              </a>
              <div className='col-lg-1'></div>
              <a href='/maro2' className='col-lg-5'>
                <p> «Մարոն» 2013 </p>
                <p>
                  Հեղինակ՝ Հովհաննես <br /> Թումանյան
                </p>
                <img src='images/slider-item6.jpg' alt='' />
              </a>
            </div>
          </div>
          <a href='/adolescentsclub' className='col-lg-1 p-0 next-page'>
            <p> {text.next}</p>
            <img src='images/arrow.svg' alt='' />
          </a>
        </div>
        <Slider {...slickerSettings}>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tech-amateur/tech-amateur-item1.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='0'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tech-amateur/tech-amateur-item2.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='1'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tech-amateur/tech-amateur-item3.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='2'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tech-amateur/tech-amateur-item4.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='3'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tech-amateur/tech-amateur-item5.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='4'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tech-amateur/tech-amateur-item1.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='5'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tech-amateur/tech-amateur-item2.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='6'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tech-amateur/tech-amateur-item3.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='7'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tech-amateur/tech-amateur-item4.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='8'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tech-amateur/tech-amateur-item5.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='9'
            />
          </div>
        </Slider>
        <div className='modal fade' id='GalleryModal' tabIndex='-1' role='dialog' aria-hidden='true'>
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>×</span>
                </button>
              </div>
              <div className='modal-body'>
                <div id='carouselGallery' className='carousel slide' data-ride='carousel'>
                  <div className='carousel-inner'>
                    <div className='carousel-item active'>
                      <img className='d-block w-100' src='images/tech-amateur/tech-amateur-item1.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/tech-amateur/tech-amateur-item2.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/tech-amateur/tech-amateur-item3.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/tech-amateur/tech-amateur-item4.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/tech-amateur/tech-amateur-item5.jpeg' alt='' />
                    </div>
                    <div className='carousel-item '>
                      <img className='d-block w-100' src='images/tech-amateur/tech-amateur-item1.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/tech-amateur/tech-amateur-item2.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/tech-amateur/tech-amateur-item3.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/tech-amateur/tech-amateur-item4.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/tech-amateur/tech-amateur-item5.jpeg' alt='' />
                    </div>
                  </div>
                  <a className='carousel-control-prev' href='#carouselGallery' role='button' data-slide='prev'>
                    <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                    <span className='sr-only'>Previous</span>
                  </a>
                  <a className='carousel-control-next' href='#carouselGallery' role='button' data-slide='next'>
                    <span className='carousel-control-next-icon' aria-hidden='true'></span>
                    <span className='sr-only'>Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TechAmateur));
