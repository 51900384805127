import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './home.js';
import MainLayout from './MainLayout.js';
import MainLayoutWithIcons from './MainLayoutWithIcons.js';
import WePlay from './wePlay';
import Belated from './belated.js';
import WeRead from './weRead';
import WeConnect from './weConnect';
import Platform from './platform';
import Lab from './lab';
import Tribune from './Tribune';
import We from './we';
import Coming from './coming';
import Rape from './Rape';
import Opinion from './opinion';
import TellToWater from './tell-to-the-water';
import RewritingTheHistory from './rewriting-the-history';
import TheRegimeIsInPanic from './the-regime-is-in-panic';
import Maro2 from './maro2';
import Maro from './maro';
import Line from './the-line';
import MasterOhan from './master-ohan';
import Eurydice from './eurydice';
import Unrecognized from './unrecognized';
import AdolescentsClub from './adolescents-club';
import Asv from './a-s-v-and-the-rest';
import AnimalMasquerade from './animal-masquerade';
import Corps from './the-corps';
import EdmonHabetyan from './edmon-habetyan';
import Freedom from './freedom';
import Kickpack from './kickpack';
import Dansteele from './dansteele';
import ArmenKirakosyan from './armen_kirakosyan';
import Ostruzina from './ostruzina';
import Tarasyan from './tarasyan';
import TechAmateur from './tech-amateur';
import UlrichMeyerHorsch from './ulrich-meyer-horsch';
import Opinion1 from './opinion1';
import Opinion2 from './opinion2';
import Opinion3 from './opinion3';
import Opinion4 from './opinion4';
import Opinion5 from './opinion5';
import Opinion6 from './opinion6';
import Opinion7 from './opinion7';
import Opinion8 from './opinion8';
import Opinion9 from './opinion9';
import Opinion10 from './opinion10';
import Opinion11 from './opinion11';
import Opinion12 from './opinion12';
import Opinion13 from './opinion13';
import Opinion14 from './opinion14';
import Opinion15 from './opinion15';
import Opinion16 from './opinion16';
import Opinion17 from './opinion17';
import Opinion18 from './opinion18';
import Opinion19 from './opinion19';
import Gibrion from './gibrion';
import Mothers from './mothers';
import TalkToMe from './talkToMe';
import Plenar from './Plenar.js';
import Tarasyan2 from './tarasyan2.js';
import KondEvent from './kondEvent';
import KondFestival from './kondFestival.js';
import KondFestival5 from './kondFestival5.js';
import KondFestival4 from './kondFestival4.js';
import KondFestival3 from './kondFestival3.js';
import KondFestival2 from './kondFestival2.js';
import KondFestival1 from './kondFestival1.js';
import Unexampled from './unexampled.js';
import Belgrad from './belgrad.js';
import Confused from './confused.js';
import Someone from './someone.js';
import Nur from './nur.js';
import Writing from './Writing.js';
import ExpositionPage from './ExpositionPage';
import { Platform9 } from './platform/index.jsx';
import Unread from './Unread.js';
import KondFestival6 from './kondFestival6.js';
import Hercules from './hercules.jsx';

export default function App() {
  return (
    <Switch>
      <Route path='/ulrichMeyerHorsch'>
        <MainLayoutWithIcons>
          <UlrichMeyerHorsch />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/techAmateur'>
        <MainLayoutWithIcons>
          <TechAmateur />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/tarasyan'>
        <MainLayoutWithIcons>
          <Tarasyan />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/dansteele'>
        <MainLayoutWithIcons>
          <Dansteele />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/armenKirakosyan'>
        <MainLayoutWithIcons>
          <ArmenKirakosyan />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/ostruzina'>
        <MainLayoutWithIcons>
          <Ostruzina />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/kickpack'>
        <MainLayoutWithIcons>
          <Kickpack />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/freedom'>
        <MainLayoutWithIcons>
          <Freedom />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/edmonHabetyan'>
        <MainLayoutWithIcons>
          <EdmonHabetyan />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/corps'>
        <MainLayoutWithIcons>
          <Corps />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/animalMasquerade'>
        <MainLayoutWithIcons>
          <AnimalMasquerade />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/asv'>
        <MainLayoutWithIcons>
          <Asv />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/adolescentsclub'>
        <MainLayoutWithIcons>
          <AdolescentsClub />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/weconnect'>
        <MainLayoutWithIcons>
          <WeConnect />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/unrecognized'>
        <MainLayoutWithIcons>
          <Unrecognized />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/masterOhan'>
        <MainLayoutWithIcons>
          <MasterOhan />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/eurydice'>
        <MainLayoutWithIcons>
          <Eurydice />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/line'>
        <MainLayoutWithIcons>
          <Line />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/tellToTheWater'>
        <MainLayoutWithIcons>
          <TellToWater />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/regimeInPanic'>
        <MainLayoutWithIcons>
          <TheRegimeIsInPanic />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/rewritingthehistory'>
        <MainLayoutWithIcons>
          <RewritingTheHistory />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/maro'>
        <MainLayoutWithIcons>
          <Maro />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/maro2'>
        <MainLayoutWithIcons>
          <Maro2 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/rape'>
        <MainLayoutWithIcons>
          <Rape />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion'>
        <MainLayoutWithIcons>
          <Opinion />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/coming'>
        <MainLayoutWithIcons>
          <Coming />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/we'>
        <MainLayoutWithIcons>
          <We />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/lab'>
        <MainLayoutWithIcons>
          <Lab />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/tribune'>
        <MainLayoutWithIcons>
          <Tribune />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/platform/:id'>
        <MainLayoutWithIcons inner>
          <Platform9 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/platform'>
        <MainLayoutWithIcons>
          <Platform />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/weread'>
        <MainLayoutWithIcons>
          <WeRead />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/weplay'>
        <MainLayoutWithIcons>
          <WePlay />
        </MainLayoutWithIcons>
      </Route>

      <Route path='/opinion1'>
        <MainLayoutWithIcons>
          <Opinion1 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion2'>
        <MainLayoutWithIcons>
          <Opinion2 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion3'>
        <MainLayoutWithIcons>
          <Opinion3 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion4'>
        <MainLayoutWithIcons>
          <Opinion4 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion5'>
        <MainLayoutWithIcons>
          <Opinion5 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion6'>
        <MainLayoutWithIcons>
          <Opinion6 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion7'>
        <MainLayoutWithIcons>
          <Opinion7 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion8'>
        <MainLayoutWithIcons>
          <Opinion8 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion9'>
        <MainLayoutWithIcons>
          <Opinion9 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion10'>
        <MainLayoutWithIcons>
          <Opinion10 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion11'>
        <MainLayoutWithIcons>
          <Opinion11 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion12'>
        <MainLayoutWithIcons>
          <Opinion12 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion13'>
        <MainLayoutWithIcons>
          <Opinion13 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion14'>
        <MainLayoutWithIcons>
          <Opinion14 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion15'>
        <MainLayoutWithIcons>
          <Opinion15 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion16'>
        <MainLayoutWithIcons>
          <Opinion16 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion17'>
        <MainLayoutWithIcons>
          <Opinion17 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion18'>
        <MainLayoutWithIcons>
          <Opinion18 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/opinion19'>
        <MainLayoutWithIcons>
          <Opinion19 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/gibrion'>
        <MainLayoutWithIcons>
          <Gibrion />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/mothers'>
        <MainLayoutWithIcons>
          <Mothers />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/talkToMe'>
        <MainLayoutWithIcons>
          <TalkToMe />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/plenar'>
        <MainLayoutWithIcons>
          <Plenar />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/tarasyan2'>
        <MainLayoutWithIcons>
          <Tarasyan2 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/kondevent/:id'>
        <MainLayoutWithIcons inner>
          <KondEvent />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/kondfest5'>
        <MainLayoutWithIcons>
          <KondFestival5 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/kondfest4'>
        <MainLayoutWithIcons>
          <KondFestival4 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/kondfest3'>
        <MainLayoutWithIcons>
          <KondFestival3 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/kondfest2'>
        <MainLayoutWithIcons>
          <KondFestival2 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/kondfest1'>
        <MainLayoutWithIcons>
          <KondFestival1 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/kondfest6'>
        <MainLayoutWithIcons>
          <KondFestival6 />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/kond_fest'>
        <MainLayoutWithIcons>
          <KondFestival />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/unexampled'>
        <MainLayoutWithIcons>
          <Unexampled />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/belgrad'>
        <MainLayoutWithIcons>
          <Belgrad />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/confused'>
        <MainLayoutWithIcons>
          <Confused />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/someone'>
        <MainLayoutWithIcons>
          <Someone />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/nur'>
        <MainLayoutWithIcons>
          <Nur />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/writing'>
        <MainLayoutWithIcons>
          <Writing />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/exposition'>
        <MainLayoutWithIcons>
          <ExpositionPage />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/unread'>
        <MainLayoutWithIcons>
          <Unread />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/hercules'>
        <MainLayoutWithIcons>
          <Hercules />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/belated'>
        <MainLayoutWithIcons>
          <Belated />
        </MainLayoutWithIcons>
      </Route>
      <Route path='/'>
        <MainLayout>
          <Home />
        </MainLayout>
      </Route>
    </Switch>
  );
}
