import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function Freedom() {
  return (
    <div className='main-container'>
      <div className='breadcrumb container'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/opinion7' className='breadcrumb-active'>
          Մտածումներ մի ներկայացման մասին
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong> Մտածումներ մի ներկայացման մասին</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item7.jpeg' alt='icon' className='img-fluid' />
        <p>
          Մի տաս օր առաջ ՆՓԱԿ-ում Նանոր Պետրոսյանի ներկայացումն էր՝ թե պիեսն ա իրանը, թե բեմականացումը: Սկզբում հեղինակը
          դուրս եկավ բեմ, կարճ խոսեց պիեսի մասին, ասելով որ մեր անցած տարիների մասին ա՝ թե ինչի միջով անցանք եւ ուր
          հասանք: Նանորի ասածը բառացի չմեջբերեցի որովհետեւ բառացի չեմ հիշում, նույնիսկ միտքը վստահ չեմ որ ճշգրիտ եմ
          փոխանցում, բայց հաստատ հիշում եմ որ ասվեց, թե մեր՝ հայաստանցիներիս անցած տարիների մասին ա: Սա հաստատ եմ
          հիշում, քանի որ ներկայացումը նայելիս մտածում էի՝ եթե էդ փոքրիկ նախաբանը չլիներ, ես գլխի կընկնեի՞ որ իմ ապրածի
          մասին ա: Նման հարց առաջանում էր, որովհետեւ իրականությունը ուղղակի չէր ներկայացված, այլ վերացարկված, քանդված
          հավաքված էր որոշակի սկզբունքով: Բացման խոսքում էդ սկզբունքը բացատրվել էր. երեք հերոսները ներկայացնում են
          մարդկային կեցության երեք կերպ՝ Մտածողին, Երազողին, Գործողին: Մտածողը ջահել տղա էր, ներկայացման ամբողջ
          ընթացքում հիմնականում նստած էր հատակին փռված գրքերի կողքին եւ ձեռքին էլ հաճախ գիրք էր: Երազողը ջահել աղջիկ էր,
          սենյակ ուներ, մեկ մեկ հեռանում էր դեպի էդ սենյակը, իսկ ավելի հաճախ՝ դուրս էր գալիս սենյակից ճամպրուկը ձեռքին,
          հրաժեշտ էր տալիս տղային որ գնա բայց չէր գնում, մի բան հնարում էր որ ժամանակ անցնի, հետո էլ ասում էր՝ ուշ ա
          էսօր արդեն, գնացքը գնաց: Հաջորդ անգամ՝ նույն պատմությունը, մինչեւ որ եկավ ներկայացման կուլմինացիան, որի մասին
          ավելի ուշ կպատմեմ: Երրորդ պերսոնաժը Գործողն էր, որին տեսնում էինք ոչ թե անմիջապես բեմի վրա, այլ բեմին
          տեղավորված էկրանից: Դա իհարկե հեռուստատեսային էկրան չէր, այլ թատերական պայմանականություն եւ հավանաբար
          խորհրդանշում էր բեմի վրա գտնվող Մտածողի եւ Երազողի իրականության կտրվածությունը Գործողի իրականությունից:
          Գործողը ինչ որ գործողություն էր կատարել, որի համար ոստիկանությունը իրան փնտրում էր: Էդ գործողությունը Գործողն
          ինքը պիեսի ընթացքում պատմեց սկզբում մի տարբերակով, հետո, երբ որ ոստիկաններն արդեն հայտնվել էին, կարծեմ մեկ
          ուրիշ տարբերակով, բայց ես էդ գործողությունը մանրամասն չեմ հիշում որ ստեղ շարադրեմ, մենակ հիշում եմ որ մեջը
          թռիչք կար, ու նաեւ հստակ հիշում եմ որ էդ գործողությունը, ներկայացման մնացած դետալների նման,
          վերացարկված-անկոնկրետ էր, ճանաչելի սոցիալ-քաղաքական կոնտեքստ չուներ: Հիշում եմ, մի պահ կար, զգացի որ էդ
          անճանաչելիությունն ինձ դուր ա գալիս՝ ամեն ինչ ծայրահեղացված հասցված էր աբսուրդի, որը կոնկրետ տեղ չունի, կարող
          ա պատահել ամեն տեղ: Քանի որ հարեւանները պարբերաբար կենցաղային առարկաներ էին խնդրում՝ «միկրովալնովկա» կամ ֆեն,
          պետք էր ենթադրել որ բազմաբնակարան շենք էր: Բայց մեկ մեկ էլ թվում էր թե ոչ թե շենքի մեջ ենք, այլ կայարանի
          բակում …
        </p>
        <p>
          Թատրոն գնալիս ես ամենաշատը վախենում եմ, որ կտեսնեմ կեղծիք: Ներկայացումը կամ լավն ա լինում, կամ չէ, ու եթե չէ՝
          թատրոնը վերածվում ա կեղծիքի, դառնում ա անտանելի: Էս ներկայացման ժամանակ էլ, ոնց որ միշտ, սկզբում փշերի վրա էի՝
          վախ կար մեջս: Բայց վախը հիշում եմ որ շատ արագ անցավ: Կեղծիքը նախ եւ առաջ խոսքի մեջ ա լինում, բայց էս անգամ
          չկար, ոչ աղջկա խոսքն էր անբնական, ոչ տղայի, ես շատ արագ հանգստացա, լարվածությունս անցավ, ու զգացի որ սկսում եմ
          հաճույք ստանալ ներկայացումից: Մի որոշ ժամանակ լավ էր, աղջիկը արդեն չեմ հիշում որերորդ անգամ ճամպրուկը ձեռքին
          դուրս էր եկել որ գնա, ճամպրուկը դրել էր ու չէր գնում, հանկարծ զգացի որ ուզում եմ մի բան պատահի: Հետո բեմի վրա
          էկրան հայտնվեց, էկրանին էլ՝ Գործողը, ու ես մտածեցի՝ հեսա հայտնվեց պատահելիքն ու գործողությունը: Բայց չէ,
          չեղավ: Ավելի շուտ՝ եղավ, բայց էն չէր: Գործողությունը էն չէր: Երեւի դրա համար էլ հստակ չեմ հիշում էդ
          գործողությունը, կարծեմ ինչ որ անհավանական թռիչք կար մեջը, թռիչք կամ դրա նման մի բան, փոխարենը հստակ հիշում եմ
          որ նորից փշերի վրա հայտնվեցի՝ զգալով որ կեղծիք ա մտնում: Բայց չէ, կեղծիքի առաջին նշույլները զգացել էի մի քիչ
          ավելի շուտ, երբ որ բնակարանից օգտվելու համար վճարել պահանջողի ձայնն էր հայտնվել՝ կարծես որպես չարիքը
          մարմնավորող կողմ: Հետո արդեն ոստիկանների ձայնը հայտնվեց՝ արդեն հստակորեն որպես չարիքը մարմնավորող կողմ: Բայց
          էդ ձայները կեղծ էին հնչում, քանի որ ներկայացնում էին մի բան, որը ներկայացման մեջ չկար՝ բուն հակառակ կողմը
          չկար:
        </p>
        <p>
          Քանի որ, ոնց որ ասեցի, մի քիչ ջղայնացել էի, երեւի սկսել էի պակաս ուշադիր հետեւել բեմում կատարվողին ու լավ չեմ
          հիշում ինչը նախորդեց կուլմինացիային, ոնց եղավ որ աղջիկը վերջապես վերցրեց ճամպրուկն ու դուրս եկավ: Հետո ինչ որ
          բաներ տեղի ունեցան, եւ աղջիկը նորից հայտնվեց մեր առաջ՝ բեմի վրա, ձեռքին նորից նույն ճամպրուկն էր, բայց բացված,
          ու միջից փետուրները դեռ թափվում էին: Պարզվեց՝ աղջկա ճամպրուկի մեջ փետուրներ էին, ու ինքը երկար դրանք հավաքել
          հավաքել էր: Իսկ թափվել էին փետուրները ճամպրուկի միջից, որովհետեւ աղջիկը գնալու համար պետք ա անցներ կայարանով,
          իսկ կայարանում բախում էր՝ մի կողմը փորձում էր կայարանը քանդել վերացնել, մյուս կողմը հավաքվել դիմադրում էր,
          բայյց աղջիկը ոչ մի կողից չէր, աղջիկը հայտնվել էր երկու կողմերի արանքում՝ օղակման մեջ, աղջկա վրա ծիծաղել էին,
          ստորացրել էին, ճամպրուկը ոնց որ թե ուզեցել էին խլել, ճամպրուկը բացվել էր, փետուրները թափվում էին: Հիշում եմ՝
          ոնց էր աղջիկը, բեմին կանգնած, բացված ճամպրուկը ձեռքին, էս ամեն ինչը պատմում, ու իմ համար դժվար էր հասկանալ, թե
          ինչ եմ զգում: Մի կողմից էդ բմբուլները ինձ ջղայնացնում էին, մյուս կողմից զգում էի որ դրանք ինչ որ տեղ ճշգրիտ
          են, ճշգրիտ ինչ-որ բան են արտահայտում: Իսկ ամենազարմանալին աղջկա ձայնն էր, որ ներկայացման ժամանակ հնչող ձայնը
          չէր, փոխվել էր, դարձել էր սուր ու անբնական, բայց դրա մեջ էլ ոնց որ ճշգրիտ մի բան կար... Աղջիկն ավարտեց
          ճամպրուկի դատարկման/ հույսերի խորտակման պատմությունը, հեռացավ դեպի իրա սենյակը: Տղան, որ դրանից առաջ գրքերը
          բաժանել էր առաջին շարքի հանդիսատեսին, դրանք հետ վերցրեց ու հեռացավ գնաց: Արդեն հասկանում էի որ ներկայացումն
          ավարտվում ա, ու մեջս անբավարվածության զգացում էր, փորձում էի ինքս իմ համար ջղայնությունս ձեւակերպել, մեկ էլ
          հենց իմ հետեւից (ես նստած էի ահագին վերեւ, բեմի աջ մասում) շատ բարձր գմփոց լսվեց: Կամ տղային/Մտածողին խփեցին,
          կամ, ինչն ավելի հավանական ա, Գործողին: Ներկայացումն ավարտվեց: Դուրս գալիս, աստիճաններից իջնելիս, հիշում եմ
          մտածում էի, որ էդ գմփոցը լավն էր, շատ շատ լավն էր, բայց ափսոս ուշ էր, ներկայացման մեջ գմփոց չկար,
          ներկայացումով հասունացած չէր էդ գմփոցը: Ինձ թվում էր, սրանով էլ ամեն ինչ կավարտվեր, բայց հաջորդ օրը հասկացա
          որ՝ չէ, ներկայացումը չի մոռացվել, դրա հատվածները նորից անցնում են աչքիս առաջով: Ու ամենից շատ էն տեսարանը, երբ
          որ աղջիկը կանգնած էր՝ բմբլաթափ ճամպրուկը ձեռքին, եւ սուր ձայնով ու զարմացած աչքերով պատմում էր կատարվածը: Դա
          հենց կուլմինացիայի տեսարանն էր, ու ես էդ կուլմինացիան անհամոզիչ ու անբավարար էի համարել, որովհետեւ չէի
          կարողանում ինձ նույնացնել հերոսներից ոչ մեկի հետ, էդ թվում՝ աղջկա հետ, որն էլ հենց գլխավոր հերոսն էր: Ու
          հանկարծ իմ էդ մտածումների մեջ հիշեցի ներկայացման վերնագիրը՝ «Ա-ն, Ս-ն, Վ-ն և մնացածը»: Շշմելու վերնագիր: Ա-ն:
          Ս-ն: Վ-ն: Եւ մնացածը: Ա-ն մենակ էր, Ս-ն մենակ էր, Վ-ն մենակ էր: Մնացածը մնացածն էր: Ահա թե ինչու ինձ թվացել էր
          կոնֆլիկտը չի ներկայացված, անբավարար ա ներկայացված, գործողություն չկա: Որովհետեւ իմ մտքում կոնֆլիկտը ուրիշ էր:
          Որովհետեւ ինչքան էլ որ Նանորի ներկայացման մեջ ամեն ինչ հիմնականում վերացարկված, իրականության կոնկրետ նշաններից
          հեռացված էր, մեկ ա՝ դա իմ համար Հայաստանն էր (ես հիմա մտածում եմ՝ եթե անգամ Նանորի բացման խոսքը չլիներ, երեւի
          էլի Հայաստանն էր լինելու), մինչեւ ներկա ձգվող ոչ հեռու անցյալի Հայաստանն էր, որտեղ ես ունեմ իմ ապրած
          պատմությունը, եւ իմ ապրած պատմությունը լրիվ ուրիշ պատմություն ա, քան պատմվում ա էս ներկայացման մեջ: Իմ
          Հայաստանում կոնֆլիկտը լրիվ ուրիշ տեղ ա, քան էս ներկայացման մեջ: Էս ներկայացման մեջ կենտրոնական հերոսը
          Աղջիկը/Երազողն ա, որն էլ ի վերջո հայտնվում ա կայարանում հավաքված Մնացածի օղակի մեջ: Այնինչ իմ ապրած եւ ճանաչած
          Հայաստանի կոնֆլիկտը հենց էդ «մնացածի» մեջ էր, «մնացածի» ներսում, էն «մնացածի» ներսում, որը էս ներկայացման մեջ
          իրականում չկա, չի ներկայացված: Թվում էր՝ Գործողը պետք ա որ էդ «մնացածին» ներկայացնի, բայց չէ, չէր
          ներկայացնում, Գործողի որտեղ լինելը տենց էլ չհասկացվեց: Ու հիմա ես հասկանում եմ կարծես, թե ինչու ներկայացումը
          դիտելուս հաջորդ օրը դրա հատվածները անցնում էին նորից աչքիս առջեւով: Որովհետեւ ես ուշացումով նկատել էի հայացքը,
          որ նայում էր իմ ապրած իրականությանը, բայց նայում էր լրիվ ուրիշ կողմից, ավելի շուտ՝ ուրիշ տեղից: Մենության
          տեղից: Իմ ապրած/ հիշած իրականության մեջ չկար մենություն, ընդհակառակը՝ կար անծանոթների հետ մեկ ու միասին զգալու
          վիճակ, որը լրիվ ուրիշ պատմություն ա, լրիվ ուրիշ տեղ: Չնայած աշխարհագրական/նյութական իմաստով տեղը նույնն ա:
        </p>
        <p>Մարինե Պետրոսյան</p>
        <p>Tert.am</p>
        <p>05.06.2014</p>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freedom));
