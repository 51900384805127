
import Slider from 'react-slick';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './weconnect.css';
import { scrollIntoView } from './ScrollHelper';
const data = require('./lang/lang.json');

class Hercules extends Component {
  state = {
    showItem: '',
    prefix: "hercules",
    next: "belated",
    mainImage: "images/hercules/main.png",
  };

  render() {
    const text = data[localStorage.getItem('language')];
    const slickerSettings = {
      customPaging: (i) => {
        if (i > 4) {
          return <div></div>;
        } else {
          return <button>i</button>;
        }
      },
      slidesToShow: 5,
      slidesToScroll: 1,
      speed: 500,
      centerPadding: '0',
      centerMode: true,
      centerMargin: '0',
      variableWidth: false,
      className: 'bem',
      adaptiveHeight: false,
      autoplay: true,
      dots: true,
      arrow: false,
      autoplaySpeed: 2500,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerPadding: '0',
            centerMode: true,
            centerMargin: '0',
            slidesToShow: 3,
            slidesToScroll: 0,
            adaptiveHeight: false,
            dots: true,
            arrow: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            centerPadding: '0',
            centerMode: true,
            centerMargin: '0',
            slidesToShow: 1,
            slidesToScroll: 0,
            adaptiveHeight: false,
            dots: true,
            arrow: false,
          },
        },
      ],
    };
    const prefix = this.state.prefix
    const next = this.state.next
    const mainImage = this.state.mainImage
    const images = [
        "images/hercules/1.jpg",
        "images/hercules/2.jpg",
        "images/hercules/3.jpg",
        "images/hercules/4.jpg",
        "images/hercules/5.jpg"
    ]
    const enlargedImages = [
        "images/hercules/1.jpg",
        "images/hercules/2.jpg",
        "images/hercules/3.jpg",
        "images/hercules/4.jpg",
        "images/hercules/5.jpg"
    ]
    return (
      <section className='main-container current-container'>
        <div className='breadcrumb container'>
          <a href='/weplay'>{text.weplay_title}/</a>
          <a href={`/${prefix}`} className='breadcrumb-active'>
            {text[prefix]}
          </a>
        </div>
        <div className='section-details modal-slider slider-centered'>
          <div className='row m-0 position-relative'>
            <div className='white-decoration'>
              <img src='images/white-svg.svg' alt='asv' />
            </div>
            <div className='col-lg-6 p-0 pr-lg-4'>
              <img src={mainImage} alt={prefix}/>
            </div>
            <div className='main-description-banner col-lg-5 p-lg-0'>
              <p className='mb-0'> {text[prefix]}</p>
              <p> {text[`${prefix}_author`]}</p>
              <div className='info-block'>
                <p dangerouslySetInnerHTML={{ __html: text[`${prefix}_desc`] }}></p>
                <p dangerouslySetInnerHTML={{ __html: text[`${prefix}_date`] }}></p>

                <p dangerouslySetInnerHTML={{ __html: text[`${prefix}_cast`]}}></p>
              </div>
            </div>
            <a href={`/${next}`} className='col-lg-1 p-0 next-page desktop-next'>
              <p> {text.next}</p>
              <img src='images/arrow.svg' alt='' />
            </a>
          </div>

          <Slider {...slickerSettings}>
            {images.map((image, index) => (
                 <div data-toggle='modal' data-target='#GalleryModal'>
                 <img
                   src={image}
                   alt=''
                   data-target='#carouselGallery'
                   data-slide-to={index}
                 />
               </div>
            ))}
          </Slider>
          <div className='modal fade' id='GalleryModal' tabIndex='-1' role='dialog' aria-hidden='true'>
            <div className='modal-dialog' role='document'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                <div className='modal-body'>
                  <div id='carouselGallery' className='carousel slide' data-ride='carousel'>
                    <div className='carousel-inner'>
                    {
                        enlargedImages.map((image, index) =>
                          <div className={`carousel-item ${index === 0 ? 'active':''}`}>
                            <img className='d-block w-100' src={image} alt='' />
                          </div>
                        )
                      }
                    </div>
                    <a className='carousel-control-prev' href='#carouselGallery' role='button' data-slide='prev'>
                      <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                      <span className='sr-only'>Previous</span>
                    </a>
                    <a className='carousel-control-next' href='#carouselGallery' role='button' data-slide='next'>
                      <span className='carousel-control-next-icon' aria-hidden='true'></span>
                      <span className='sr-only'>Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a href={`/${next}`} className='col-lg-1 p-0 next-page mobile-next'>
            <p> {text.next}</p>
            <img src='images/arrow.svg' alt='' />
          </a>
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Hercules));
