import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './bem.css';
const data = require('./lang/lang.json');

class Unrecognized extends Component {
  state = {
    showItem: '',
  };

  render() {
    const text = data[localStorage.getItem('language')];
    return (
      <section className='main-container current-container'>
        <div className='breadcrumb container'>
          <a href='/wePlay'>{text.weplay_title}/</a>
          <a href='/unrecognized' className='breadcrumb-active'>
            {text.unrecognized_title}
          </a>
        </div>
        <div className='section-details'>
          <div className='row m-0 position-relative'>
            <div className='white-decoration'>
              <img src='images/white-svg.svg' alt='asv' />
            </div>
            <div className='col-lg-6 p-0 pr-lg-4'>
              <img src='images/unrecognized/unrecognized-bg.jpeg' alt='' />
            </div>
            <div className='main-description-banner col-lg-5 p-0'>
              <p className='mb-0'> {text.unrecognized_title}</p>
              <div className='info-block' dangerouslySetInnerHTML={{ __html: text.unrecognized_text_1 }}></div>
              <div className='info-block' dangerouslySetInnerHTML={{ __html: text.unrecognized_text_2 }}></div>
              <div className='info-block' dangerouslySetInnerHTML={{ __html: text.unrecognized_text_3 }}></div>
            </div>
            <a href='/gibrion' className='col-lg-1 p-0 next-page desktop-next'>
              <p> {text.next}</p>
              <img src='images/arrow.svg' alt='' />
            </a>
          </div>
          <div className='col-lg-6  m-auto'>
            <div className='unrecognized-container'>
              <h2 dangerouslySetInnerHTML={{ __html: text.basement }}></h2>
              <h2 dangerouslySetInnerHTML={{ __html: text.syuzi }}></h2>
              <p dangerouslySetInnerHTML={{ __html: text.basement_text }}></p>
              <div className='video-link'>
                <iframe
                  title='basement'
                  width='860'
                  height='415'
                  src='https://www.youtube.com/embed/xwKcAUsHgVA'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className='unrecognized-container'>
              <h2 dangerouslySetInnerHTML={{ __html: text.my_hand }}></h2>
              <h2 dangerouslySetInnerHTML={{ __html: text.nanor }}></h2>
              <p dangerouslySetInnerHTML={{ __html: text.my_hand_text }}></p>
              <div className='video-link'>
                <iframe
                  title='my_hand'
                  width='860'
                  height='415'
                  src='https://www.youtube.com/embed/_q1udMorQaY'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className='unrecognized-container'>
              <h2 dangerouslySetInnerHTML={{ __html: text.questions_to }}></h2>
              <h2 dangerouslySetInnerHTML={{ __html: text.nare }}></h2>
              <p dangerouslySetInnerHTML={{ __html: text.questions_to_text }}></p>
              <div className='video-link'>
                <iframe
                  title='questions_to'
                  width='860'
                  height='415'
                  src='https://www.youtube.com/embed/oUgI8QojT9c'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className='unrecognized-container'>
              <h2 dangerouslySetInnerHTML={{ __html: text.thing }}></h2>
              <h2 dangerouslySetInnerHTML={{ __html: text.silvana }}></h2>
              <p dangerouslySetInnerHTML={{ __html: text.thing_text }}></p>
              <div className='video-link'>
                <iframe
                  title='thing'
                  width='860'
                  height='415'
                  src='https://www.youtube.com/embed/3z6_i5kzhI8'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className='unrecognized-container'>
              <h2 dangerouslySetInnerHTML={{ __html: text.january_one }}></h2>
              <h2 dangerouslySetInnerHTML={{ __html: text.gayane }}></h2>
              <p dangerouslySetInnerHTML={{ __html: text.january_one_text }}></p>

              <div className='video-link'>
                <iframe
                  title='january_one'
                  width='860'
                  height='415'
                  src='https://www.youtube.com/embed/bkjvLKZY_XE'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
              </div>
              <a href='/gibrion' className='col-lg-1 p-0 next-page mobile-next'>
                <p> {text.next}</p>
                <img src='images/arrow.svg' alt='' />
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Unrecognized));
