import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function aniMaro() {
  return (
    <div className='main-container'>
      <div className='breadcrumb container'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/opinion1' className='breadcrumb-active'>
          Նանորի Մարոն
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong>Նանորի Մարոն</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item1.jpeg' alt='icon' className='img-fluid' />
        <p>
          <em>
            Նախքան ներկայացմանը գնալը վերընթերցեցի Թումանյանի ամենահայտնի ու ողբերգական պոեմներից մեկը՝ «Մարոն».
            դպրոցական ընկալումս թարմացման կարիք ուներ: Ճանապարհին մտքում պտտում էի պոեմը բեմադրելու զանազան տարբերակներ,
            բայց պարզվեց՝ սպասելիքներս շատ ավելի համեստ էին, քան այն, ինչի համար արժեր արհամարհել անձրևոտ եղանակն ու
            հասնել ՆՓԱԿ-ի դահլիճ:
          </em>
        </p>
        <p className='mt-3'>
          Բեմում ի սկզբանե տիրող մինիմալիզմը՝ թե՛ ռեկվիզիտի, թե՛ դերասանների հագուկապի հարցում հանդիսատեսի
          ուշադրությունը սևեռում էր բացառապես սյուժեի ու խաղի վրա: Երիտասարդ ռեժիսոր Նանոր Պետրոսյանն իր բեմադրության
          համար մեծ մասամբ ոչ պրոֆեսիոնալ դերասանների էր ընտրել... և չէր սխալվել: Նրանց ապրումներն առավել քան համոզիչ
          էին, իսկ նվիրվածությունը դերին՝ հարգանքի արժանի: Ներկայացման մեջ գործարկված գյուղացիներն իրար հերթ չտալով
          պատմում էին իրենց Մարոյի մասին՝ հարազատ մնալով Թումանյանի շարադրած բնագրին: Պատմողական մասին հաջորդում էր
          խաղարկայինը, որտեղ այլևս տեքստի կարիք չկար. կերպարները հնչյունների լեզվով էին հաղորդակցվում, ոչ միայն
          դիմախաղով ու ժեստերով, այլև ձայնային խաղով մասնակցում գործողությանը: Գրեթե մեկուկես դար առաջ գրված պոեմն իր
          դրամատիզմով ու հերոսների հույզերով այսօր էլ ժամանակավրեպ չէր: Հանդիսատեսը մինչև վերջին պահը կլանված հետևում էր
          գործողությանը, հանգչող լույսով ընդմիջվող տեսարանները մեկը մյուսի հետևից կառուցում էին կարմրազգեստ աղջկա
          պատմությունն ու տանում դեպի տրամաբանական ավարտը՝ բոլորին կարեկից դարձնելով սկզբում Մարոյի, հետո նրա ծնողների
          ողբերգությանը: Երբ լույսերը մարեցին ու հնչեց Մարոյի վախճանը սգացողների երգը, դահլիճում չոր աչք չմնաց…
        </p>
        <p>Անի Սմբատի</p>
        <p>Imyerevan.com</p>
        <p>10.11.2012</p>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(aniMaro));
