import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import $ from 'jquery';
import './home.css';
import './css/responsive.css';
import HomeMobile from './homeMobile';

let data = require('./lang/lang.json');

class Home extends Component {
  state = {
    showItem: '',
  };

  onMobileClick = () => {
    $('.mobile-stage-inner').slideToggle();
  };
  onMobileClickSchool = () => {
    $('.mobile-school-inner').slideToggle();
  };
  onMobileClickWe = () => {
    $('.mobile-we-inner').slideToggle();
  };
  onMobileClickKond = () => {
    $('.mobile-kond-inner').slideToggle();
  };
  onHamburgerClick = () => {
    $('.mobile-menu').fadeIn('slow');
  };
  onCloseClick = () => {
    $('.mobile-menu').fadeOut('slow');
  };
  setShowItem = (item) => {
    this.setState({
      showItem: item,
    });
  };

  setHideItem = (item) => {
    this.setState({
      showItem: '',
    });
  };

  render = () => {
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', 'arm');
    }
    const text = data[localStorage.getItem('language')];
    return (
      <div>
        <div className='overflow-hidden home'>
          {this.state.showItem !== '' ? (
            this.state.showItem === 'school' ? (
              <div className='school-item animate animate__animated  animate__delay-1  animate__fadeIn'>
                <img src='images/school-gif.gif' alt='icon' className='img-fluid ' />
              </div>
            ) : this.state.showItem === 'stage' ? (
              <div className='stage-item animate animate__animated  animate__delay-1  animate__fadeIn  '>
                <img src='images/on-stage-gif.gif' alt='icon' className='img-fluid' />
              </div>
            ) : (
              <div className='about-us-item animate animate__animated  animate__delay-1  animate__fadeIn'>
                <img src='../images/about-us-gif.gif' alt='icon' className='img-fluid' />
              </div>
            )
          ) : (
            ''
          )}

          <div className='container-fluid'>
            <div className='line animate animate__animated animate__bounce animate__delay-0 animate__fadeInUp first'>
              <img src='images/menu-line-1.svg' className='img-fluid' alt='' />
            </div>

            <div className='line animate animate__animated animate__bounce animate__delay-1s animate__fadeInDown second'>
              <img src='images/menu-line-2.svg' className='img-fluid' alt='' />
            </div>
            <div className=''>
              <div
                className='stage menu-item animate animate__animated animate__bounce animate__delay-1s animate__fadeInDown'
                onMouseEnter={() => this.setShowItem('stage')}
                onMouseLeave={() => this.setHideItem()}
              >
                <p dangerouslySetInnerHTML={{ __html: text.on_stage }}></p>
                <img src='images/on-stage-circle.svg' className='img-fluid' alt='logo' width='41px' title='logo' />
                <div className='menu-inner  animate animate__animated animate__bounce animate__delay-0s animate__fadeInUp'>
                  <a href='/weplay'>{text.weplay_title}</a>
                  <a href='/weread'>{text.weread}</a>
                  <a href='/weconnect'> {text.we_connect}</a>
                </div>
              </div>
              <div
                className='school menu-item animate animate__animated animate__bounce animate__delay-0s animate__fadeInDown'
                onMouseEnter={() => this.setShowItem('school')}
                onMouseLeave={() => this.setHideItem()}
              >
                <p>{text.the_school}</p>
                <img src='images/school-circle.svg' className='img-fluid' alt='' width='15px' title={text.the_school} />
                <div className='menu-inner   animate animate__animated animate__bounce animate__delay-0s animate__fadeInUp'>
                  <a href='/platform'>{text.platform}</a>
                  <a href='/lab'>{text.lab}</a>
                  <a href='/tribune'>{text.tribune}</a>
                  <a href='/writing'>{text.writing}</a>
                  <a href='/exposition'>{text.exposition}</a>
                </div>
              </div>
              <div
                className='about-us  menu-item  animate animate__animated animate__bounce animate__delay-0s animate__fadeInUp'
                onMouseEnter={() => this.setShowItem('about-us')}
                onMouseLeave={() => this.setHideItem()}
              >
                <p>{text.about_us}</p>
                <img src='images/about-us-circle.svg' className='img-fluid' alt='logo' width='41px' title='logo' />
                <div className='menu-inner  animate animate__animated animate__bounce animate__delay-0s animate__fadeInUp'>
                  <a href='/we'>{text.we}</a>
                  <a href='/coming'>{text.coming}</a>
                  <a href='/opinion'>{text.opinion}</a>
                </div>
              </div>
              <div className='kond-festival menu-item animate animate__animated animate__bounce animate__delay-0s animate__fadeInUp'>
                <p>{text.projects}</p>
                <img src='images/kond-circle.svg' className='img-fluid' alt='logo' width='41px' title='logo' />
                <div className='menu-inner  animate animate__animated animate__bounce animate__delay-0s animate__fadeInUp'>
                  <a href='/kond_fest'>{text.kond}</a>
                  <a href='/unread'>{text.unread}</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='home-mobile'>
          <HomeMobile />
        </div>
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
