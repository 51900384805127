import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import React from 'react';
import './weplay.css';

let data = require('./lang/lang.json');

function NextArrow(props) {
  const { onClick } = props;
  return (
    <button type='button' className='slick-next pull-right slick-arrow d-block mt-5' onClick={onClick}>
      <img src='images/next-arrow.svg' className='img-fluid' alt='arrow' title='arrow' />
    </button>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <button type='button' className='slick-prev pull-left slick-arrow d-block mt-5' onClick={onClick}>
      <img src='images/prev-arrow.svg' className='img-fluid' alt='arrow' title='arrow' />
    </button>
  );
}

function opinion() {
  const text = data[localStorage.getItem('language')];
  const slickerSettings = {
    customPaging: (i) => {
      if (i > 14) {
        return <div></div>;
      } else {
        return <button>i</button>;
      }
    },
    infinite: true,
    centerPadding: '6px',
    slidesToShow: 6,
    slidesToScroll: 1,
    speed: 500,
    centerMode: true,
    variableWidth: false,
    // className: 'bem',
    centerMargin: '0',
    adaptiveHeight: false,
    autoplay: true,
    dots: true,
    prevArrow: <div></div>,
    nextArrow: <div></div>,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '0',
          slidesToShow: 1,
          slidesToScroll: 0,
          adaptiveHeight: false,
          dots: true,
          prevArrow: <PrevArrow />,
          nextArrow: <NextArrow />,
        },
      },
    ],
  };

  return (
    <section className='main-container main-container-decoration we-play-content we-play-container opinion-content'>
      <div className='description-banner'>
        <h2 className='title-generic'>{text.opinion}</h2>
        <p dangerouslySetInnerHTML={{ __html: text.opinion_desc }}></p>
      </div>
      <section className='center-slider m-auto opinion-content'>
        <Slider {...slickerSettings}>
          <div className='slide'>
            <div className='slider-title'>
              <h2>Նորայր Չիլինգարյան</h2>
              <p>Բռնաբարության մասին</p>
              <p className='date text-center'></p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>
              <a href='opinion8'>
                <img src='images/opinion/opinion-item8.jpeg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='slider-title'>
              <h2>Լենա Գևորգյան</h2>
              <p>Բռնաբարության մասին</p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>
              <a href='opinion5'>
                <img src='images/opinion/opinion-item6.jpeg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='slider-title'>
              <h2>Նարե Սահակյան</h2>
              <p>Մարոյի մասին</p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>
              <a href='opinion15'>
                <img src='images/opinion/opinion-item12.jpeg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='child-element'>
              <div className='slider-title'>
                <h2>Անի Սմբատի</h2>
                <p>Մարոյի մասին</p>
                <p className='date text-center'></p>
              </div>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>
              <a href='/opinion1'>
                <img src='images/opinion/opinion-item1.jpeg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='slider-title'>
              <h2>Մարինե Պետրոսյան</h2>
              <p>Ա-ն, Ս-ն,Վ-ն և մնացածի մասին</p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>
              <a href='opinion7'>
                <img src='images/opinion/opinion-item7.jpeg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='slider-title'>
              <h2>Աննա ժամակոչյան</h2>
              <p>Վերապատմումի մասին</p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>
              <a href='opinion2'>
                <img src='images/opinion/opinion-item2.jpeg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='slider-title'>
              <h2>Նունե Հախվերդյան</h2>
              <p>Վերապատմումի մասին</p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>
              <a href='opinion9'>
                <img src='images/opinion/opinion-item9.jpeg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='slider-title'>
              <h2>Վիոլետ Գրիգորյան</h2>
              <p>Վերապատմումի մասին</p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>
              <a href='opinion10'>
                <img src='images/opinion/opinion-item11.jpeg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='slider-title'>
              <h2>Անուշ Քոչարյան</h2>
              <p>Վերապատմումի մասին</p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>
              <a href='opinion3'>
                <img src='images/opinion/opinion-item3.jpeg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='slider-title'>
              <h2>Մարինե Պետրոսյան</h2>
              <p>Ջրին պատմեմի մասին</p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>
              <a href='opinion14'>
                <img src='images/opinion/opinion-item7.jpeg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='slider-title'>
              <h2> Արտավազդ Եղիազարյան</h2>
              <p>Ջրին պատմեմի մասին</p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>
              <a href='opinion16'>
                <img src='images/opinion/opinion-item13.jpeg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='slider-title'>
              <h2>Մարինե Պետրոսյան</h2>
              <p>Վերապատմումի մասին</p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>
              <a href='opinion13'>
                <img src='images/opinion/opinion-item7.jpeg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='slider-title'>
              <h2>Տիգրան Մարտիրոսյան</h2>
              <p>Ջրին պատմեմի մասին</p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>
              <a href='opinion12'>
                <img src='images/opinion/opinion-item10.jpeg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='slider-title'>
              <h2>Արեգ Դավթյան</h2>
              <p>Ռեժիմը խուճապի մեջ էի մասին</p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>
              <a href='opinion17'>
                <img src='images/opinion/opinion-item14.jpeg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='slider-title'>
              <h2>Տիգրան Մարտիրոսյան՝</h2>
              <p>Դոկ թատրոնի մասին</p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>
              <a href='opinion11'>
                <img src='images/opinion/opinion-item10.jpeg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='slider-title'>
              <h2>Արա Նեդոլյան`</h2>
              <p>Էվրիդիկեի մասին</p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>
              <a href='opinion4'>
                <img src='images/opinion/opinion-item4.jpeg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='slider-title'>
              <h2>Հասմիկ Բարխուդարյան</h2>
              <p>Թեք տեղի մասին</p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>

              <a href='opinion6'>
                <img src='images/opinion/opinion-item5.jpeg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='slider-title'>
              <h2>Անի Բրդոյեան-Ղազարեան</h2>
              <p>Անվերնագրի մասին</p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>

              <a href='opinion18'>
                <img src='images/opinion/opinion-item15.jpg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='slide'>
            <div className='slider-title'>
              <h2>Անի Բրդոյեան-Ղազարեան</h2>
              <p>Մայրերի մասին</p>
              <p className='date text-center'></p>
            </div>
            <div className='child-element'>
              <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
              </div>

              <a href='opinion19'>
                <img src='images/opinion/opinion-item15.jpg' alt='icon' className='img-fluid img-no-stretch' />
              </a>
              <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
              </div>
            </div>
          </div>
        </Slider>
      </section>
    </section>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(opinion));
