import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function Freedom() {
  return (
    <div className='main-container'>
      <div className='breadcrumb container'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/opinion6' className='breadcrumb-active'>
          Հայ թեք
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong> Հայ թեք</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item5.jpeg' alt='icon' className='img-fluid' />
        <p>
          «Թեք տեղն» իր տեսակի մեջ Հայաստանում միակն է, տարբեր է պետական թատրոններից թե՛ ձևաչափով, թե՛ մոտեցումներով։
          Տարբեր են նաև այս թատրոնի խնդիրները։ Գնացինք «Թեք տեղ», թատրոնի անդամ, բեմադրող-ռեժիսոր Նանոր Պետրոսյանի և մի
          խումբ դերասանների հետ հանդիպելու համար։ Ինը տարի և տասնմեկ ներկայացում առաջ «Թեք տեղը» հանդես եկավ իր առաջին՝
          Ֆրանկ Ռամեի և Դարիո Ֆոյի «Բռնաբարություն» բեմադրությամբ։ Այն առանձնահատուկ էր ոչ միայն ներկայացվող նյութի և
          ձևաչափի տեսանկյունից, այլև այն պատճառով, որ բեմում էին սիրողական դերասաններ։ Մի խոսքով, խոսելու շատ բան
          ունեինք։
        </p>
        <p>
          Նանորը երկար ու համբերատար բացատրեց, թե ինչպես գտնեմ թեք տեղը. Քաջազնունի 1, 2-րդ հարկ, ուղիղ, աստիճաններով
          վեր, սպիտակ դռնից ներս, պիտի թեքվեմ, էլի աստիճաններ, հետո էլի թեքվել է պետք, հետո էլի… Այս ճամփորդությունն այն
          աստիճան անվերջ է թվում, որ մի պահ սկսում եմ կասկածել, թե սա նոր ներառական ներկայացման մի մասն է, ու ես արդեն
          դարձել եմ դրա մասնակիցը… Ըհը, հասա։ «Թեք տեղն» է՝ բարձր առաստաղով մի ընդարձակ սենյակ, անմշակ պատերը սև
          ներկված, հանդիսատեսի աթոռները հավաքված, պատի տակ շարված, սենյակը հնարավորության սահմաններում թատերական տարածքի
          վերածված։
        </p>
        <p>
          Եվ սա բնավ զարմանալի չէ։ «Թեք տեղը» չի հովանավորվում և ամեն բան այստեղ կատարվում է կամավորության սկզբունքով։
          Թատրոնը շատ է թափառել, տարբեր բեմեր ու ոչ թատերային տարածքներ զբաղեցրել։ Ներկայացումներից երեքն իրականացվել են
          դրամաշնորհային ծրագրերի միջոցներով, մնացած բոլորը՝ թատերախմբի անդամների ջանքերով։ Սա անշուշտ դժվարացնում է
          աշխատանքը։ Նանորը մի քիչ մտահոգ ասում է. «Կան գործեր, որ սպասում են որևէ հովանավորության, որպեսզի սկսենք։
          Դահլիճ չունենք։ Վարձակալությունը մի այլ խնդիր է: Դժվար է տոմս վաճառելու պրոցեսը, որովհետև չկա տոմսավաճառ,
          դժվար է ամեն անգամ բեմի դեկոր տեղափոխելը, որովհետև չկա բեմի բանվոր և այլն, և այլն։ Մենք ներկայացումները ոչ
          միշտ ենք թատրոններում անում ու, ինձ թվում է, երկար ժամանակ չենք էլ անի, քանի որ վերջին փորձառությունը մի
          իսկական սարսափ էր։ Շատերը չեն հասկանա, թե ինչ է նշանակում թատրոնի տարածքը վարձել։ Այն մի օրով են տրամադրում և
          այդ մի օրում ներկայացում բեմ հանելը գրեթե անհնար է։ Նախկինում էլ մենք մեր ներկայացումներն արել ենք տարբեր ոչ
          թատերային տարածքներում, ու, ինձ թվում է, մենք ինչ-որ մի շրջան էլի կշարունակենք այդպես անել»։
        </p>
        <p>
          «Թեք տեղի» հանդիսատեսը շատ չէ, բայց մշտապես աճում է. այս ինը տարիների աշխատանքի արդյունքում հասցրել է հավաքել
          450–500 հոգանոց մշտական հանդիսատես նախկին 50-ի փոխարեն։ Նանորն ասում է. «Պետական որևէ թատրոնի մի դահլիճը միայն
          կարող է այդքան մարդ միաժամանակ տեղավորել և դա գուցե ծիծաղելի թիվ է, բայց Հայաստանում ընդհանուր առմամբ
          ժամանակակից կոնցեպտուալ արվեստով շատ քչերն են հետաքրքրված ու մեր ներկայացումներին այդ հանդիսատեսի
          ներկայությունը ես հաղթանակ եմ համարում»:
        </p>
        <p>
          «Թեք տեղի» ծրագրերում արդեն վաղուց մշտապես ներգրավված են Սիլվանա Չոբանյանը, Սօսի Պըլըխեանը և Գայանե Բալյանը։
          «Իրականում ոչ մեկս չգիտենք՝ թատրոնն ինչ է։ Թատրոնը մետամորֆոզային երևույթ է։ Ինչպես որ չկա կնոջ հաստատուն
          կերպար, հայի հաստատուն կերպար, այդպես էլ թատրոնն է։ Մենք հարցադրում ենք, թե ի՞նչ է այսօրվա թատրոնը և թե ի՞նչ
          միջոցներով կարող ենք ստանալ թատրոնի որևէ այլ, նոր արտահայտչամիջոց։ Կարծում եմ՝ մենք զբաղված ենք մի բանով, որ
          շատ քչերն են Հայաստանում անում։ Մենք հարցի տակ ենք դնում թատրոնի ինչ լինելը և մեր ներկայացումները, լինեն դրանք
          հաջողված, թե ոչ, հստակ կարող եմ ասել, որ եթե չբեմադրվեին մեր կողմից, ապա Հայաստանում դրանք չէին բեմադրվի երբեք
          և ոչ էլ այդ ձևաչափով կբեմադրվեին»,- ասում է Նանորը։
        </p>
        <p>
          Նորարար ու փորձարարական այս թատրոնը համարձակորեն ներկայանում է ոչ ավանդական բեմադրություններով և թատերական
          ընթերցումներով։ Իմպրովիզացիաներ, բեմից դուրս ներկայացումներ, երեխաների ձեռքով պատրաստված ստվերների թատրոն,
          դոկումենտալ ներկայացումներ։ Բեմադրության ձևաչափի մասին խոսելիս Նանորն ասում է. «Ինձ համար կարևոր չի եղել
          երբեք, որ բեմադրությունը լինի նորարարություն, հետաքրքիր, գրավիչ, աչք ծակող մի բան։ Կարծում եմ, յուրաքանչյուրն
          ինքն է որոշում, թե ինչ տեսք և ինչ վիճակ պիտի ունենա իր ներկայացումը՝ թե՛ դերասանական խաղի, թե՛ նկարչական
          ձևավորման և թե՛ հանդիսատեսի ներգրավվածության տեսանկյունից։ Կարևոր չէ ձևաչափը, կարևոր է ցանկությունը, թե տվյալ
          խումբն ինչպես է տեսնում տվյալ գործը։ Մնացած ամեն ինչը պոնտեր են, դրանք արվեստային չեն»։ Թատրոնի հաջողության
          գրավականներից մեկը Նանորը համարում է սիրողական դերասանների ներգրավվածությունը։ Ասում է, թե պրոֆեսիոնալների հետ
          շատ ավելի բարդ է։ Նրանք ունեն հստակ ձևավորված պատկերացում, թե ինչպիսին պետք է լինի դերասանությունը,
          համալսարանում արդեն սովորել են, թե ճիշտ ձևը որն է և նրանց այդ ճիշտ ձևից հանելը շատ ավելի դժվար է։ Սենյակի հենց
          կենտրոնում սեղանի շուրջ նստած են նրանք՝ սիրողական դերասանները։ Փորձի են։ Նոր թատերգության թերթերը նշումներ
          արած սպասում են ընթերցվելու։ Ես իրենց խանգարեցի մի քիչ: Գարիկ Ծատրյանը մանկավարժ է։ Թատերական ինստիտուտ
          ընդունվելու մտքեր է ունեցել, փոխարենը ծանոթներից իմացել է «Թեք տեղի» մասին ու եկել. «Նանորը տեքստերը բերեց,
          մտածում ենք հիմա Համլետի կամ Ջուլիետի շորերը պիտի հագնենք, վազվզենք, փաստորեն՝ չէ։ Բայց մենք մնացինք,
          չվախեցանք, վստահ էինք, որ լավ է լինելու, հետո պարզվեց՝ տաղանդավոր ենք», — ծիծաղում է Գարիկը.
          «Մանկավարժությունն էլ դերասանություն է, գուցե ավելի դժվար։ Իմ հանդիսատեսը միշտ նույնն է, ամեն անգամ նորովի
          ներկայանալ է պետք»։ Տնտեսագետ, բիզնես խորհրդատու Գոհար Մալումյանն աշխատանքի բերումով ստիպված էր լինում
          պրեզենտացիաներ անել ու խնդիր ուներ զարգացնելու մեծ լսարանի առջև խոսելու հմտությունները։ Բեմական հոբբին եղավ
          դրա լուծումը. «Ամեն տարի որոշում էի ու չէի գտնում ոչ մի տարբերակ։ Երբ նոր էի եկել, պատրաստվում էինք «Ջրին
          պատմեմ»-ին, Նանորն ասաց՝ պատմություններ պիտի հավաքենք, մտածում էի՝ ինչպե՞ս, դա՞ էլ մենք պիտի անենք (ծիծաղում
          է)։ Հայելու առաջ էի փորձում, սենյակում փակվում ու փորձեր էի անում։ Էդպես էլ սկսվեց ամեն ինչ»։ Մյուս «զոհս»
          Էլիզա Հմայակյանն է, Թերլեմեզյանի երրորդ կուրսի ուսանող։ «Փոքր ժամանակից հաճախում էի թատրոնի խմբակ, միշտ էլ
          զբաղված երեխա եմ եղել։ Միշտ էլ ուզեցել եմ դերասան դառնալ։ «Խուճապն» իմ առաջին ներկայացումն էր «Թեք տեղում»։
          Եկա, շատ էի ամաչում, չէի կարողանում կարդալ, ժամանակի ընթացքում սկսեցի չամաչել: Նանորը շատ էր մոտիվացնում։
          Տեսա, թե ինչ լավ մարդիկ են։ Երկար ժամանակ աշխատում էինք ու տարիքս չգիտեին, երբ իմացան մի ուրիշ խուճապ սկսվեց։
          Մամայիս տարիքին ե՜ն (մամայի տարիքի կոլեգաները ծիծաղում են — խմբ.)։ Դա իհարկե ոչ մի նշանակություն չունի։ Այս
          միջավայրում ես ինձ լավ եմ զգում։ Երկրորդ ներկայացմանն արդեն շատ ավելի հանգիստ էի»։ Աննա Մաթևոսյանը քիմիկոս է,
          գիտությունների թեկնածու, բայց աշխատում է բանկում ու դեռ հասցնում է դերասանություն անել։ «Իմ այստեղ գալու
          դրդապատճառը Նանորն էր։ Ինքը երևույթ է։ Նանորն ընդունում է մեզ այնպիսին, ինչպիսին որ կանք, չի փորձում փոխել
          մեզ։ Մենք այս ներկայացումների մեջ այն ենք, ինչ կանք։ Երբեք չի ասում՝ այ սրա ձևն այսպես է։ Ընդհակառակը, ասում
          է՝ ոնց զգում ես, էնպես էլ ասա։ Դա ինձ համար շատ մեծ բան է։ Դա է ազատությունը»։ «Սիրողական դերասանները մեծ
          նվիրում ունեն, մղում ունեն փորձելու և փորձերին մասնակցելու,- բացատրում է Նանորը, — Փորձին մասնակցելիս իսկապես
          ամբողջությամբ էդտեղ են, ոչ ոք հեռախոսի մեջ չի, ոչ ոք չի շտապում մի ուրիշ փորձի և, որ կարևոր է, ոչ ոք էդ ճիշտ
          ձևը չգիտի։ Բոլորս մի կետում ենք և փորձում ենք հասկանալ, թե ինչ կարելի է անել ու դա շատ գեղեցիկ ընթացք է, որ իմ
          կարծիքով թատրոնի հիմքում պետք է լինի»։ Ձայնագրիչն անջատում եմ, մարդիկ գործ ունեն անելու։ Թատերախմբի միակ
          պրոբլեմը, թերևս, այն է որ սիրողական դերասաններն աշխատում են և փորձերը հիմնականում երեկոյան ժամերի են լինում,
          հազիվ երկու ժամ տևողությամբ։ Առաջիկա՝ Գոհար Մարկոսյանի «Հուշարձան» ստեղծագործության թատերական ընթերցման փորձը
          պիտի սկսեն։ Նանորն ասում է՝շատ երևանյան է, 80-ականների պատմություն, հետո դառնում է դերասաններին՝ առանց
          շտապելու, շատ դանդաղ, տեքստը հորինելով ենք խոսում։ Սկսեցինք…
        </p>
        <p>Հասմիկ Բարխուդարյան</p>
        <p>Evnmag.com</p>
        <p>01.04.2020</p>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freedom));
