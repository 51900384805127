import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './weconnect.css';
import { scrollIntoView } from './ScrollHelper';
const data = require('./lang/lang.json');

class Mothers extends Component {
  state = {
    showItem: '',
  };

  render() {
    const text = data[localStorage.getItem('language')];
    return (
      <section className='main-container current-container'>
        <div className='breadcrumb container'>
          <a href='/wePlay'>{text.weplay_title}/</a>
          <a href='/mothers' className='breadcrumb-active'>
            {text.mothers}
          </a>
        </div>
        <div className='section-details modal-slider slider-centered'>
          <div className='row m-0 position-relative'>
            <div className='white-decoration'>
              <img src='images/white-svg.svg' alt='asv' />
            </div>
            <div className='col-lg-6 p-0 pr-lg-4'>
              <img src='images/mothers/Tekeyan.png' alt='mothers' />
            </div>
            <div className='main-description-banner col-lg-5 p-lg-0'>
              <p className='mb-0'>{text.mothers}</p>
              <p>{text.mothers_author}</p>
              <div className='info-block'>
                <p dangerouslySetInnerHTML={{ __html: text.mothers_desc }}></p>
                <p dangerouslySetInnerHTML={{ __html: text.mothers_date }}></p>

                <p dangerouslySetInnerHTML={{ __html: text.mothers_cast }}></p>
                <a
                  href='#!'
                  onClick={() => scrollIntoView('video-link-mothers')}
                  className='info-link  d-block mb-2 mt-3'
                >
                  {text.video_soon}
                </a>
              </div>
            </div>
            <a href='/rape' className='col-lg-1 p-0 next-page desktop-next'>
              <p> {text.next}</p>
              <img src='images/arrow.svg' alt='' />
            </a>
          </div>
          <a href='/rape' className='col-lg-1 p-0 next-page mobile-next'>
            <p> {text.next}</p>
            <img src='images/arrow.svg' alt='' />
          </a>
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Mothers));
