import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

let data = require('./lang/lang.json');

function Tribune() {
  const text = data[localStorage.getItem('language')];
  return (
    <section className='main-container'>
      <div className='description-banner'>
        <h2 className='title-generic'>{text.tribune}</h2>
        <p dangerouslySetInnerHTML={{ __html: text.tribune_desc }}></p>
      </div>
      <div>
        <div className='generic-container'>
          <div className='row m-0'>
            <div className='we-read-box col-lg-6'>
              <img src='images/the-tribune/tribune-item3.jpg' className='img-fluid' alt='item' title='item' />
            </div>
            <div className='we-read-box col-lg-6'>
              <p>{text.tribune_title_3}</p>
              <p className='mb-0'>{text.tribune_host_3}</p>
              <div className='we-read-info'>
                <p dangerouslySetInnerHTML={{ __html: text.tribune_desc_3 }}></p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12'>
          <img src='images/we-read/line1.svg' className='img-fluid' alt='item' title='item' />
        </div>
        <div className='generic-container'>
          <div className='row m-0'>
            <div className='we-read-box col-lg-6 show-mob'>
              <img src='images/the-tribune/tribune-item.jpg' className='img-fluid' alt='item' title='item' />
            </div>
            <div className='we-read-box col-lg-6'>
              <p className='row m-0'>{text.shadow_history}</p>
              <p className='row m-0'>{text.armen_kirakosyan}</p>
              <div className='we-read-info'>
                <p dangerouslySetInnerHTML={{ __html: text.shadow_history_title }}></p>
                <p dangerouslySetInnerHTML={{ __html: text.shadow_history_text }}></p>
                <p dangerouslySetInnerHTML={{ __html: text.shadow_history_date }}></p>
              </div>
            </div>
            <div className='we-read-box col-lg-6 show-desktop'>
              <img src='images/the-tribune/tribune-item.jpg' className='img-fluid' alt='item' title='item' />
            </div>
          </div>
        </div>
        <div className='col-12'>
          <img src='images/we-read/line1.svg' className='img-fluid' alt='item' title='item' />
        </div>
        <div className='generic-container'>
          <div className='row m-0'>
            <div className='we-read-box col-lg-6'>
              <img src='images/the-tribune/tribune-item2.jpeg' className='img-fluid' alt='item' title='item' />
            </div>
            <div className='we-read-box col-lg-6'>
              <p>{text.tribune_title_1}</p>
              <p className='mb-0'>{text.tribune_host_1}</p>
              <div className='we-read-info'>
                <p dangerouslySetInnerHTML={{ __html: text.tribune_desc_1 }}></p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12'>
          <img src='images/we-read/line1.svg' className='img-fluid' alt='item' title='item' />
        </div>
        <div className='generic-container'>
          <div className='row m-0 flex-reverse'>
            <div className='we-read-box col-lg-6'>
              <p>{text.tribune_title_2}</p>
              <p className='mb-0'> {text.tribune_host_2}</p>
              <div className='we-read-info'>
                <p dangerouslySetInnerHTML={{ __html: text.tribune_desc_2 }}></p>
              </div>
            </div>

            <div className='we-read-box col-lg-6'>
              <img src='images/the-tribune/tribune-item1.jpeg' className='img-fluid' alt='item' title='item' />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tribune));
