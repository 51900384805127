import Slider from 'react-slick';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './weconnect.css';
import './bem.css';
import { scrollIntoView } from './ScrollHelper';
const data = require('./lang/lang.json');

class KondFest3 extends Component {
  state = {
    showItem: '',
  };

  render() {
    const text = data[localStorage.getItem('language')];
    const slickerSettings = {
      customPaging: (i) => {
        if (i > 4) {
          return <div></div>;
        } else {
          return <button>i</button>;
        }
      },

      slidesToShow: 5,
      slidesToScroll: 1,
      speed: 500,
      centerPadding: '0',
      centerMode: true,
      centerMargin: '0',
      variableWidth: false,
      className: 'bem',
      adaptiveHeight: false,
      autoplay: true,
      dots: true,
      arrow: false,
      autoplaySpeed: 2500,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerPadding: '0',
            centerMode: true,
            centerMargin: '0',
            slidesToShow: 3,
            slidesToScroll: 0,
            adaptiveHeight: false,
            dots: true,
            arrow: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            centerPadding: '0',
            centerMode: true,
            centerMargin: '0',
            slidesToShow: 1,
            slidesToScroll: 0,
            adaptiveHeight: false,
            dots: true,
            arrow: false,
          },
        },
      ],
    };
    return (
      <section className='main-container current-container slider-centered'>
        <div className='breadcrumb container'>
          <a href='/kond_fest'>{text.kond}/</a>
          <a href='/kondfest3' className='breadcrumb-active'>
            {text.kond3}
          </a>
        </div>
        <div className='section-details modal-slider'>
        {[...Array(14)].map((e,i) => 
        { const id = i + 1;
          const image = text[`kond.${id}.image`]
          const textArm = text[`kond.${id}.textarm`]
          const textEng = text[`kond.${id}.texteng`]
          return       <div className='section-details'>
          <div className='m-0 position-relative'>
          <div className='white-decoration'>
              <img src='../images/white-svg.svg' alt='asv' />
            </div>
            <div className='col-lg-6 we-img-container m-4'>
              <img src={image} alt='' />
            </div>
            <div className='w-75 m-5'>
              <p dangerouslySetInnerHTML={{ __html: textArm }}></p>
              <p dangerouslySetInnerHTML={{ __html: textEng }}></p>
            </div>
          </div>
        </div>
        }
        )}
          <a href='/kondFest4' className='col-lg-1 p-0 next-page mobile-next'>
            <p> {text.next}</p>
            <img src='images/arrow.svg' alt='' />
          </a>
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KondFest3));
