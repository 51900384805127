import Slider from 'react-slick';
import React ,{ Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './bem.css';

import { scrollIntoView } from './ScrollHelper';
const data = require('./lang/lang.json');

class Asv extends Component {
  state = {
    showItem: '',
  };

  state = {
    showItem: '',
  };

  render() {
    const text = data[localStorage.getItem('language')];
    const slickerSettings = {
      customPaging: (i) => {
        if (i > 4) {
          return <div></div>;
        } else {
          return <button>i</button>;
        }
      },
      slidesToShow: 5,
      slidesToScroll: 1,
      speed: 500,
      centerPadding: '0',
      centerMode: true,
      centerMargin: '0',
      variableWidth: false,
      className: 'bem',
      adaptiveHeight: false,
      autoplay: true,
      dots: true,
      arrow: false,
      autoplaySpeed: 2000,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerPadding: '0',
            centerMode: true,
            centerMargin: '0',
            slidesToShow: 3,
            slidesToScroll: 0,
            adaptiveHeight: false,
            dots: true,
            arrow: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            centerPadding: '0',
            centerMode: true,
            centerMargin: '0',
            slidesToShow: 1,
            slidesToScroll: 0,
            adaptiveHeight: false,
            dots: true,
            arrow: false,
          },
        },
      ],
    };
    return (
      <section className='asv-modal main-container current-container slider-centered'>
        <div className='breadcrumb container'>
          <a href='/wePlay' dangerouslySetInnerHTML={{ __html: text.weplay_title + '/' }}></a>
          <a href='/asv' className='breadcrumb-active'>
            {text.asv}
          </a>
        </div>
        <div className='section-details modal-slider'>
          <div className='row m-0 position-relative'>
            <div className='white-decoration'>
              <img src='images/white-svg.svg' alt='asv' />
            </div>
            <div className='col-lg-6 p-0  pr-lg-4'>
              <img src='images/asv/asv-bg.jpeg' alt='asv' />
            </div>
            <div className='main-description-banner col-lg-5'>
              <p className='mb-0'>{text.asv}</p>
              <div className='info-block  mb-4'>
                <p> {text.asv_author}</p>
              </div>
              <div className='info-block'>
                <p className='mb-0' dangerouslySetInnerHTML={{ __html: text.asv_desc }}></p>
                <p className='mb-0' dangerouslySetInnerHTML={{ __html: text.asv_date }}></p>
              </div>
              <div className='info-block mt-4 pre '>
                <p className='mt-3 pre' dangerouslySetInnerHTML={{ __html: text.asv_cast }}></p>
              </div>
              <a href='#!' onClick={() => scrollIntoView('video-link-rest')} className='info-link d-block mb-0 mt-4'>
                {text.video_av}
              </a>
              <a href='/opinion' className='info-link mb-2 '>
                {text.see_opinions}
              </a>
            </div>
            <a href='/rewritingthehistory' className='col-lg-1 p-0 next-page desktop-next'>
              <p> {text.next}</p>
              <img src='images/arrow.svg' alt='' />
            </a>
          </div>
          <section className='m-auto'>
            <Slider {...slickerSettings}>
              <div className='slide'>
                <div className='child-element'>
                  <div data-toggle='modal' data-target='#GalleryModal'>
                    <img
                      src='images/asv/item1.jpg'
                      className='img-fluid '
                      alt=''
                      data-target='#carouselGallery'
                      data-slide-to='0'
                    />
                  </div>
                </div>
              </div>

              <div className='slide'>
                <div className='child-element'>
                  <div data-toggle='modal' data-target='#GalleryModal'>
                    <img
                      src='images/asv/item2.jpg'
                      className='img-fluid '
                      alt=''
                      data-target='#carouselGallery'
                      data-slide-to='1'
                    />
                  </div>
                </div>
              </div>
              <div className='slide'>
                <div className='child-element'>
                  <div data-toggle='modal' data-target='#GalleryModal'>
                    <img
                      src='images/asv/item3.jpg'
                      className='img-fluid '
                      alt=''
                      data-target='#carouselGallery'
                      data-slide-to='2'
                    />
                  </div>
                </div>
              </div>
              <div className='slide'>
                <div className='child-element'>
                  <div data-toggle='modal' data-target='#GalleryModal'>
                    <img
                      src='images/asv/item4.jpg'
                      className='img-fluid '
                      alt=''
                      data-target='#carouselGallery'
                      data-slide-to='3'
                    />
                  </div>
                </div>
              </div>
              <div className='slide'>
                <div className='child-element'>
                  <div data-toggle='modal' data-target='#GalleryModal'>
                    <img
                      src='images/asv/item5.jpg'
                      className='img-fluid '
                      alt=''
                      data-target='#carouselGallery'
                      data-slide-to='4'
                    />
                  </div>
                </div>
              </div>

              <div className='slide'>
                <div className='child-element'>
                  <div data-toggle='modal' data-target='#GalleryModal'>
                    <img
                      src='images/asv/item1.jpg'
                      className='img-fluid '
                      alt=''
                      data-target='#carouselGallery'
                      data-slide-to='5'
                    />
                  </div>
                </div>
              </div>

              <div className='slide'>
                <div className='child-element'>
                  <div data-toggle='modal' data-target='#GalleryModal'>
                    <img
                      src='images/asv/item2.jpg'
                      className='img-fluid '
                      alt=''
                      data-target='#carouselGallery'
                      data-slide-to='6'
                    />
                  </div>
                </div>
              </div>
              <div className='slide'>
                <div className='child-element'>
                  <div data-toggle='modal' data-target='#GalleryModal'>
                    <img
                      src='images/asv/item3.jpg'
                      className='img-fluid '
                      alt=''
                      data-target='#carouselGallery'
                      data-slide-to='7'
                    />
                  </div>
                </div>
              </div>
              <div className='slide'>
                <div className='child-element'>
                  <div data-toggle='modal' data-target='#GalleryModal'>
                    <img
                      src='images/asv/item4.jpg'
                      className='img-fluid '
                      alt=''
                      data-target='#carouselGallery'
                      data-slide-to='8'
                    />
                  </div>
                </div>
              </div>
              <div className='slide'>
                <div className='child-element'>
                  <div data-toggle='modal' data-target='#GalleryModal'>
                    <img
                      src='images/asv/item5.jpg'
                      className='img-fluid '
                      alt=''
                      data-target='#carouselGallery'
                      data-slide-to='9'
                    />
                  </div>
                </div>
              </div>
            </Slider>
          </section>

          <div className='modal fade' id='GalleryModal' tabIndex='-1' role='dialog' aria-hidden='true'>
            <div className='modal-dialog' role='document'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                <div className='modal-body'>
                  <div id='carouselGallery' className='carousel slide' data-ride='carousel'>
                    <div className='carousel-inner'>
                      <div className='carousel-item active'>
                        <img className='d-block w-100' src='images/asv/item1.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/asv/item2.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/asv/item3.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/asv/item4.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/asv/item5.jpg' alt='' />
                      </div>
                      <div className='carousel-item '>
                        <img className='d-block w-100' src='images/asv/item1.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/asv/item2.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/asv/item3.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/asv/item4.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/asv/item5.jpg' alt='' />
                      </div>
                    </div>
                    <a className='carousel-control-prev' href='#carouselGallery' role='button' data-slide='prev'>
                      <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                      <span className='sr-only'>Previous</span>
                    </a>
                    <a className='carousel-control-next' href='#carouselGallery' role='button' data-slide='next'>
                      <span className='carousel-control-next-icon' aria-hidden='true'></span>
                      <span className='sr-only'>Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='text-center'>
            <p className='text-center photo-by'>
              {text.images_by} {text.asha}
            </p>
            <div id='video-link-rest' className='video-link'>
              <iframe
                title='a-s-v-and-the-rest'
                height='661'
                src='https://www.youtube.com/embed/c9nedKR7SAs'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            </div>

            <a href='/rewritingthehistory' className='col-lg-1 p-0 next-page mobile-next'>
              <p> {text.next}</p>
              <img src='images/arrow.svg' alt='' />
            </a>
          </div>
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Asv));
