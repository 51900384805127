import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function Freedom() {
  return (
    <div className='main-container'>
      <div className='breadcrumb container'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/opinion11' className='breadcrumb-active'>
          Մայրքաղաքային փաստավավերագրական թատրոնի կատարողական լույսն ու ստվերը
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong> Մայրքաղաքային փաստավավերագրական թատրոնի կատարողական լույսն ու ստվերը</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item10.jpeg' alt='icon' className='img-fluid' />
        <p>
          Վերջին տարիներին թատերական աշխարհում նոր շնչառությամբ հանդես եկող Դոկումենտալ թատրոնն(Ստանիսլավսկու եւ
          Մեյերհոլդի նմանօրինակ արտահայտչամիջոցներից օգտվել են) իբրեւ թատերաձեւ հայկական թատերարվեստի կողքով աննկատ չի
          անցել: Ոչ միայն չի անցել, այլեւ շարունակում է իր տարատեսակ դրսեւորումները տեղի հանդիսատեսին ներակայացնել:
          Այո,՛ քսաներորդ դարից հետո վերածնված ժանրը միատեսակ չէ իր ձեւերում: Այլ հարց է, թե որակապես ինչպիսի՞ն են դրանց
          բեմական վերարտադրության արդյունքները, որոնք անշուշտ իրենց օբյեկտիվ եւ սուբյեկտիվ պատճառներն ունեն: Նույնիսկ
          դրդապատճառները, որոնք պարզելու համար թերեւս իրատեսական կլինի հասկանալ հետեւյալը. ինչո՞ւ է հատկապես քսաներորդ
          դարավերջին ծնված ռեժիսորներին տվյալ թատերատեսակը գրավում: Խնդիրն այն է, որ նշյալ ժամանակաշրջանում ծնվածները
          անցումային արժեհամակարգի սերնդի ներկայացուցիչներ են: Աշխարհայացքային ելակետերում վերջնականապես
          չկողմնորշվածներ, որովհետեւ ԽՍՀՄ-ի փլուզումից հետո թույլատրելիի եւ չի կարելիների չհամակարգված
          համագոյակցությունը սոցիալ-քաղաքական անորոշության եւ բարոյահոգեբանական ապակողմնորոշումների խառնաշփոթ
          գեներացրեց: Գումարած դրան նաեւ, որ աշխարհի տեխնոլոգիական սրընթաց զարգացուման պտուղները: Մարդու հոգեկան ապարատի
          ընկալման հնարավորությունները բազմապատիկ գերազանցող տեղեկատվական գրոհի է ենթարկվում անհատի ներաշխարհը:
          Այդպիսովՙ աստիճանաբար նվազեցնում պիտանիի ու ոչ անհարաժեշտի կշռադատ ընտրության հավանականությունը: Ուստի
          չափազանց դժվար է տվյալ քաոսային միջավայրում անկայուն եւ ինքնորոշման հարցերում երերուն հետաքրքրություններին
          համարժեք դրամատուրգիական նյութ գտնելը: Այնպիսի գիր, որը կբավարարի ցանկությունների, մղումների,
          տրամադրությունների անդադրում այսրոպեականությանն ու դրանց սպասարկող գերճկուն փոխակերպումայնությանը: Մանավանդ,
          երբ հարցը խիստ զգայուն կողմիՙ անձնական կյանքի կարգավորման նրբություններին է առնչվում: Ինչպես սույն նյութի մեկ
          այլ ուսումնասիրության մեջ էինք հանգամանալից մանրամասնել, տեղի դրամատուրգիայում չի գտնվում այդ կնճռոտ
          խնդիրներիՙ զգացմունքի (էմոցիայի) համամարդկային լեզվով մեկնությունը: Ասել է թեՙ գեղարվեստական ընդհանրացում
          հարուցող արծարծումն ու հին ու բարի տիպականացումը: Հետեւաբար, այնպիսի ապատիկ տիրույթում են հայտնվում, որի
          սաղմնավորած գաղափարահուզական վակուումը զգայական վերացարկումով էլ փոխհատուցելն անհնարին է թվում: Ի դեպ,
          հայրենական դրամատուրգիայի հանդեպ անվստահությունն ավագ սերնդի բեմադրիչ- ռեժիսորներից ոմանց պարագայում էլ
          պատճառաբանվում է բարոյախրատական, կրոնամիտ ու վերացարկուն- փիլիսոփայական բովանդակությամբ: Կամ էլ ֆաբուլայի
          թուլությամբ, երբեմն էլՙ բացակայությամբ: Ինչեւիցե, բեմադրառեժիսորական համայնքի երիտասարդ սերնդի
          ներկայացուցիչները, սոցիալ-հոգեբանական ձեւակերպարանափոխման(տրանսֆորմացման) հոլովույթում իրենց որոնածը չգտնելով,
          ակամայից հարափոփոխ իրականության բնօրինակի հույսին են մնում:<strong> «Արվեստը հենց իրականությունն է» </strong>{' '}
          հետմոդեռնիստական կարգախոս-առանձնաձեւին ապավինում, որի թատերաձեւը Docudram կոչվածն է: Միմեսիսի բացարձակացման
          տանող սկզբունք, որն ինքնանպատակ նմանահանման վտանգներ է պարունակում: Ոմանք միջին վիճակագրական դիլետանտի
          հոգետրամաբանությամբ գալիս են այն եզրակացության, որ դժվար թե որեւէ մեկն իրենց անձնականն իրենցից լավ ներկայացնի:
          Ինչ խոսք, առերեւույթ, այս մոտեցումը բավական դյուրին է կարծվում: Այն պատճառով, որ թվում էՙ քիչ թե շատ ուշագրավ
          իրականությունն առանց ստեղծագործական ջանքերի բեմ հանելով կարելի է բեմականացնող ու դերասան դառնալ: Այդկերպ, ամեն
          երկրորդն իրեն թույլ տա արվեստի բարդաճաշակության դուռը թակել: Ավելին, տեսնելով որ ներսից արձագանքող չկա,
          ինքնուրույն բացել եւ ինչպես ներքաղաքական սպրդուն դասականն էր ասումՙ շատ շնորհքով ներխուժել: Սպրդելը, կսպրդի,
          սակայն մեծ հարց է ծագում, թե իրեն հետեւողի վրա ի՞նչ տեսակ ազդեցություն կունենա: Որպես մայրաքաղաքային գրեթե
          բոլոր փաստագրական եւ վավերագրական ներկայացումները դիտած թատրոնի մասնագետ, կարող ենք վստահեցնել, որ բացառիկ
          դեպքերում է հանդիսատեսի ապրումակցում գրանցվում: Մեծավ մասամբ էստրադային նմանակումներին հարող ձեւացումներով
          զվարճախառն հույզեր են հարուցվում: Հետեւողին զուտ անհաղորդ վկայի հոգեվիճակում պահելով: Որակական այս պատկերի
          պատճառները բացատրող փաստերը բավականաչափ են, բայց եղած օրինակներից կկենտրոնանանք նրանց վրա, որոնք իրենց
          ստեղծագործական կյանքում գոնե երկու անգամ դիմել են նշյալ ժանրաձեւերին: Որպեսզի համեմատելու եւ ըստ դրա
          հետեւություններ անելու հիմք ունենանք: Առաջնահերթ պետք է նշեմ իմ սերնդի ռեժիսորներից նրան, որն ամենից շատն է
          այս ժանրաձեւերում իր ուժերը փորձել: Խոսքը <strong> Նանոր Պետրոսյանի </strong>մասին է, որի ռեժիսորական
          աշխատանքներից առնվազն երեքը փաստավավերագրական թատրոնի նմուշներ են: 2015թ. նա, Վավերագրություն-իմպրովիզացիա
          բնորոշմամբ «Վերապատմում» ներկայացումը հեղինակելով, կարելի է ասել առաջին դոկուդրամատիկ քայլն արեց: Սենյակատիպ
          տարածքում կիսաշրջանաձեւ նստած են դերասանական կրթություն չունեցող իննսունականների սերնդի երիտասարդներ: Այսպես
          ասած հաղորդման շրջանակում պատմում են իրենց մանկության հիշողությունները: Համեմված երիտասարդների ետեւում գտնվող
          էկրանին նրանց մանկության օրերի սոցիալ- քաղաքական իրադարձությունների վկայություն տեսանյութերի ցուցադրումով: Ընդ
          որումՙ հաղորդման նմանողական մատուցումն ընդգծվում է հաղորդավարուհուՙ <strong>Նազենի Հովհաննիսյանի </strong>{' '}
          արտահայտչաձեւերի նմանակումով: Այդ թվում նաեւ ըստ իմպրովիզացիոն նպատկահարմարության մեկընդմեջ միջամտվում
          ինտելիեգենտ մանկավարժի, հայրենական պատերազմի մասնակցի եւ էքսցենտրիկ ակտիվիստի ձեւացումներով: Ու ի՞նչն
          արժեւորվեց ներկայացումից հանդիսատեսի զգայական աշխարհումՙ թողնելով տվյալ դրսեւորմանը ընկալողի էմոցիոնալ
          հիշողության մեջ մշտական բնակություն հաստատել: Պատկերացրեքՙ միայն Նազենի Հովհաննիսյանին նմանակելը: Իհարկե
          տրամաբանական հարց կարող է առաջանալ, թե այդ ինչպես է, որ սոցիալական տարբեր ծագման քաղաքացիների ձեւացումն
          անտարբերություն հարուցեց, իսկ հաղորդավարուհուն նմանակելը այդպիսի ազդեցություն թողեց: Չէ՞ որ նմանակումն եւ
          ձեւացումն իբրեւ արտահայտչամիջոցներ նույնական չլինելով, այդուհանդերձ, նման են: Բացատրեմ թե ինչու. ներկայացվող
          կերպարներից երեքը թեեւ տարբեր կարգաիճակի ու հասարակական դիրքի մարդիկ էին, այդուամենայնիվ, շարքային քաղաքացիներ
          են: Իսկ Նազենի Հովհաննիսյանը հեռուստաէկրանից չիջնող եւ հասարակական հետաքրքրությունների զանգվածային շրջանակում
          մնացող հանրային դեմք էր: Պատահական չէ, որ ժամանակին տոնական հեռուստածրագրերում հայտնի մարդկանց կամ քաղաքական
          դեմքերի նմանակումներն էին ամենահետաքրքրական պահերը: Եվ կերպարային կենսական նյութի հետաքրքրականությունը
          ներկայացվելիքի հաջողության երաշխիք լինելը փաստվում է ցցուն օրինակով: 21-րդ դարի հայ թատրոնի լավագույն
          դոկումենտալ ներկայացմամբՙ «Հաղթանակի գենեզիս» բեմական տրիպտիխով: Հետմոդեռնիստական ինքնանմանակումներով հագեցած
          այս ներկայացման ներազդեցիկությունը մի քանի հանգամանքներով էր պայմանավորված: Առաջինն այն էր, որ մեր դերասանական
          համայնքի առաջատարներից Նարինե Գրիգորյանն ու Արման Նավասարդյանն են հետահայաց ինքնախաղի վարպետությամբ մութ ու
          ցուրտ տարիների պատերազմական օրերը պատմում: Երկրորդըՙ բեմում գտնվողները կինեմատոգրաֆից եւ այլնից եւս քիչ թե շատ
          հայտնի մարդիկ են ու իրենց կերպարներին հավելյալ հետաքրքրականություն էր հաղորդում{' '}
          <strong>Արման Նավասարդյանի </strong> կոլորիտային, իսկ <strong> Նարինե Գրիգորյանի </strong>թատերային
          խառնվածքները: Այսինքն նրանք ինքնին հանրահետաքրքիր կերպարներ են: Այլապես այդ «Հաղթանակի գենեզիս»-ի կենսագրական
          ինքնապատումները նրբահմուտ կերպով դրամատուրգիական իմի բերած <strong> Սառա Նալբանդյանի </strong> մեկ այլՙ
          դոկումենտալ թատերագրվածքը եւս նմանատիպ շենշող ազդեցություն կունենար: Տեսարանակարգը գրեթե նույն բեմակավիճակին
          էր հարմարեցված. սեղանի մոտ մարդիկ են նստած: Բայց ինչպես տեսնում ենք, սոցցանցային գրառումներից համահավաքված այդ
          տեսարանաշարի թատերականցված ընթերցանությունը չգրանցեց նույն հաջողությունը: Որովհետեւ կրկին սովորական մարդկանց
          ինչ-ինչ խնդիրներն էին վեր հանվում: Այն էլՙ ոչ երկու հիշյալ համազագայինցիների պես միջազգային կարգի արտիստների
          կողմից: Ասենք ավելին, նույնպես հայերին առնչվող պատերզմական թեմայով մեկ այլ դոկոմենտալ «Մանանա» ներկայացման
          պարագային նորից անթաքույց էր հատկանշված խնդիրների առկայությունը: Սիրիական պատերազմի Քեսաբի դեպքերից հետո
          Հայաստանում ապաստանած եղբայրների ապրումները զուտ սենտիմենտալ-հուզական մակարդակում գտնվող հանդիսատեսին ինչ-որ
          չափով կլանում են: Որքան էլ որ ցավալի դեպքերը մեր հայրենակիցներին են առնչվում, դրանց բեմական ինքնընթերցումը
          ստվերվում է կերպարների եւ դրա մատուցման արտաբեմականությամբ: Մինչեւ, որ դերասնական կրթություն ունեցող բեմադրիչ
          Կարո Բալյանը, նկատելով ներկայացման թույլ կողմը, իր կողմից տրվող հարցերով փորձել էր կերպարներին այլ որակի
          երկխոսության մեջ ներքաշել: Միգուցե նույնիսկ բծախնդրի խստություն թվա նկատումը, բայց իրապատումի դեպքում նույնպես
          որոշիչ է բեմում գտնվողի մասնագիտական հարգը: Եթե անգամ բեմ ելնողը լավ պատմող է, միեւնույն էՙ մեկ, ծայրահեղ
          դեպքումՙ մի քանի ելույթներում համոզիչ կլինի: Դերատեսակային-ժանրաոճային համատեքստում մեկանգամյա օգտագործման
          դերասանացուՙ միաօրինակ կերպարային զգացողությամբ: Ինչո՞ւ, որովհետեւ հոգետեխնիկական հմտության բացակայությանն
          աղերսվող զգայական փորձառությունը արտագեղարվեստական է: Ավելացնենք նաեւ, որ նույնիսկ նմանակում-ձեւացումի պես
          շահեկան արտահայտչամիջոցների պարագայում հույժ կարեւոր է կատարողական վարպետության գործոնը: Ահավասիկ, Նանոր
          Պետրոսյանի կողմից բեմականացրած <strong> Կարեն Ղարսլյանի «Ռեժիմը խուճապի մեջ է»</strong>
          քսանմեկուկես արարով ու 168 գործող անձով պերֆորմատիվ խաղը: Մամուլում տեղ գտած տեքստերից եւ պաշտոնական
          հայտարարություններից համահավաքված քաղաքական տարբեր տրամաչափերի գործիչների եւ լրագրողների տնազաշարքը: Թեեւ այդ
          ձեւացումներն ու նմանակումները, ակնթարթային զվարճալի զգացողություններ էին առաջացնում, այդուհանդերձ, կատարողական
          մակարդակի թողած տպավորությունը թույլ էր: Ամենեւին այնպիսին չէր, որ թվային սիմվոլիկայի համապատկերում
          ներկայացման գաղափարադրույթային նրբությունների վերաբերյալ մտորելու առիթ տար: Անշուշտ, այս ներկայացման
          պարագայում առկա է նաեւ կերպարների հետաքրքրականության վաղեմության ժամկետի գործոնը: Վերջիններս այլեւս այն
          այժմեականությունը չունեն հասարակական հետաքրքրությունների հիթ շքերթում, որ դրանց միջոցով սիրողական
          արհեստավարժություն ունեցողները նրանց բեմահմա դարձնեն: Նրանք աշխարհահռչակ Ստալինն ու Մեյերհոլդը չէին, որ իրենց
          կենսագրական հետաքրքրականությունն առնվազն դարագլուխ կազմեր: Իրենց կերպարները ներառող պիեսներն էլ բեմական
          երկարակեցությամբ շեքսպիրյան հավակնություններ առթեին: Մյուս կողմից էլ նմանատիպ կենսագրության տեր կերպարը
          բեմադրման համար որքան էլ որ շահեկան, խաղային դիտանկյունից բավական բարդ նյութ է: Չափազանց մեծ է տապալման
          հավանականությունը: Կենսափորձով ամբարած այդ ինչպիսի˜ կուտակումներ պետք է ունենաս, որ կարենաս նկարչուհի Ֆրիդա
          Կալոյի, Եսենինի սիրեցյալ Շահանե Նահապետյանի, Ոդիսեւսի կնոջ Պենելոպեի, երգչուհի Էդիթ Պիաֆի, Լեւ Տոլստոյի դստեր
          եւ կնոջ պես կերպարներին հավուր պատշաճի էմոցիոնալ նկարագիր տալ: Ահա թե ինչու ենթադրքում ենք, որ եթե ՆՓԱԿ-ի
          բեմում մեր քաղաքական ու պսեվդոքաղաքական այրերին ներկայացնողները բարձրակարգ արտիստներ լինեին, գուցե թե
          կերպարային զգացողությունն ու դրանից բխող հետեւանքներն այլ արդյունքներ գրանցեին: Սխալական չլիներ էմոցիոնալ
          կողմնորոշման խարխափումից եկող հոգեզգացական հասցեն: Թերացում, որը կխաթարի անգամ ամենաայսօրեական եւ ռեյտինգային
          քաղաքական գործչի մտորումների հիման վրա արված դոկումենտալ բեմականացումդ: <strong>Մխիթար Դանիելյանի</strong>
          հիմնած Թատրոն տանիքում ձեւաչափի տարածքում Նիկոլ փաշինյանի <strong>«Երկրի հակառակ կողմը»</strong> վերնագրով
          գրքացած գրառումների բեմավորումը: Իհարկե տվյալ թատրոնի հիմնադիր Մխիթար Դանիելյանի մասնագիտական հոտառությունը
          չէր դավաճանել իրեն: Որսացել էր, որ հռետորական հարցերով համեմված փաշինյանական գրվածքն էսսեական-հրապարակախոսական
          շունչ ունի: Ուրիշ կերպ չէր էլ կարող լինել: Երեսուն տարի սուր լրագրողական գործունեություն ծավալած հեղինակի
          լեզվահոգեկանում ձեռք բերովի բնազդին հավասարազոր երեւույթ է մտքերն ակամայից էդպես շարադրելը: Ուստիեւ
          Դանիելյանըՙ որպես բեմականացնող-ռեժիսոր, խոսքային մակարդակում աշխատել էր հարազատ մնալ վարչապետի ձեռագրաոճին:
          Արել հրապարակախոսական թատրոնի իլյուստրացիաներով պոստմոդեռնիստական ներկայացում, որից հիշողությանդ մեջ
          ցավալիորեն այլ բան է դաջվում: Ընդամենը դերակատարներից պերֆորմատիվ վարքագիծ ունեցող Մարիամ Ալեքյանի խարիզմատիկ
          լինելը: Մասնավորապեսՙ դեռեւս Զարուհի Անտոնյանի բեմադրած շեքսպիրյան կոնտամինացիայում դերասանուհու ցուցաբերած
          բավակնաչափ էմոցիոնալ թափն ու, ոչ մակերեսային խորությունը: Գործոններ, որոնց ամբողջությունը էմոցիոնալ
          անկեղծության գրավականն է, ու նույնպես կարող են<strong> VERBATIM</strong>-ային ներկայացման կատարողական
          հաջողությանը սպասարկել: Բերեմ փաստացի օրինակը: Նույն Նանոր Պետրոսյանը<strong> «Թեք տեղ» </strong>հարթակում
          վերջերս հանդիսատեսի դատին հանձնեց <strong>Ջրին պատմեմ</strong> հեգեդրամատիկ- դոկումենտալ ներկայացումը: Սա նրա
          լավագույն ներկայացումը կարելի է համարել դերակատարողական օրգանիկության տեսանկյունից: Ըստ որում այս ներկայացման
          մեջ նույնպես դերակատարները չունեն մասնագիտական կրթություն: Բնականաբար, հարց է ծագում. ո՞րն է հաջողության
          ֆենոմենը: Թերեւս այն, որ այդ մոտ մեկ տասնյակ կանանցից յուրաքանչյուրը վերբատիմի սկզբունքով իր շրջապատի որեւէ
          իգական սեռի ներկայայցուցչի կյանքի պատմություն էր լսել, գրառել կամ ձայնագրել եւ դա էր պատմում: Հիմնականում
          անձնական կյանքի խնդիրներին առնչվող այդ դրվագները իբրեւ իրենց կենսահատված վերապատմողների զգայական փորձառությանը
          խորթ չէին: Հետեւապես ենթագիտակցական կանացի սոլիդարությունից եկող բնազդային ապրումակցումով կարողանում էին
          այդչափ համոզիչ լինել: Երբեմն անգամ իրենց պատմությունների հոգեճնշիչ բովանադակություններով վանել: Ուրեմն
          փաստավավերագրական թատրոնում կենսական հիմքի գաղափարահուզական ներքնահատիկը զգալով հասկանալն առավել քան կարեւոր
          է, քանզի իրականությունն այստեղ մի քիչ ավելի անսեթեւեթ է պատումվում: Ասվածը փաստում է այն իրողությունը, որ կին
          դերակատարներից ոմանք դրան հաջորդած <strong>«Ռեժիմը խուճապի մեջ է» </strong>ներկայացման ընթացքում չեն
          ցուցաբերում խաղային այպիսի բնականություն, ինչպիսին զգում էինք<strong> Ջրին պատմեմում :</strong> Հավելենք նաեւ
          այն, որ քաղաքական տարուբերումների այդ հետմոդեռնիստական պարոդիայում ամենից հաջողը Հովհաննես Իշխանյանն էր, որ
          տարիներ ի վեր քաղաքացիական ու քաղաքական գործընթացների պասիվ հայեցող չէ: Որոշակիորեն մասնակցի կարգավիճակով է
          ապրում: Ամփոփելով բերված օրինակների հանրագումարըՙ գալիս ենք այն եզրահանգման, որ կատարողական արվեստի
          ամենաժամանակակից հոսանքների թիկունքում անգամ դեռեւս շրջում է ստանիսլավսկիական հավատընծայության գործոնի
          ուրվականը: Մոգական եթե իրոքի պայմանաձեւից կախված թատաերահռչակ
          <strong> ՉԵՄ ՀԱՎԱՏՈՒՄ </strong>-ի հաճախականությունը:
        </p>

        <p>Տիգրան Մարտիրոսյան</p>
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freedom));
