import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function Freedom() {
  return (
    <div className='main-container'>
      <div className='breadcrumb container'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/opinion17' className='breadcrumb-active'>
          Մի խուճապի բեմադրություն
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong> Մի խուճապի բեմադրություն</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item14.jpeg' alt='icon' className='img-fluid' />
        <p className='mt-3'>
          Դոկումենտալ թատրոնի ժանրն արդեն գրեթե սովորական երևույթ է դարձել երևանյան բեմերում, սակայն Կարեն Ղարսլյանի
          «Ռեժիմը խուճապի մեջ է» պիեսը, միևնույն է, առանձնահատուկ է։ Ամբողջ ստեղծագործությունը բաղկացած է իրական
          հայտարարություններից և մեջբերումներից, որոնցում օգտագործված է «խուճապ» բառը։ Կարեն Ղարսլյանը պատմել է
          ԵՐԵՎԱՆին, թե ինչպես ծնվեց այս զարմանալի ներկայացումը և ինչ շարունակություն կարող է ունենալ։
        </p>
        <p>
          <b>Խուճապի սկիզբը </b>
        </p>
        <p>
          2011 թիվն էր, ռեժիմի խուճապն արդեն կայացած բրենդ էր: Ու հասցրել էր կարգին ծնգլահան անել ՀՀ գրեթե ողջ
          բնակչությանը: Բայց ինձ համար տաղտուկը վերափոխվեց «էվրիկայի», երբ Ֆեյսբուքում աչքս ընկավ ոմն Գևորգ Միքայելյանի
          հոդվածի առաջին նախադասությունը. «Բաղրամյան 26-ում խուճապ էր ընկել»: «Սա գրականություն ա արդեն»,– մտածեցի ու
          իսկույն Google-ի միջոցով սկսեցի հավաքել անցած տարիների ընթացքում ռեժիմի խուճապն ազդարարող բոլոր
          արտահայտությունները: Միայն ութ տարի անց մտքովս անցավ, որ ոգեշնչման մասնակի պատճառ կարող էր լինել «Գյուղացի
          Համբոյի տունը կռիվ էր ընկել» Թումանյանի հայտնի նախադասության հետ անգիտակցական մտազուգորդումը: Գաղափար չունեի,
          թե վերջում ինչ է դառնալու: Մինչ այդ խուճապի մասին հայտարարությունները ծաղրվել էին տարբեր հոդվածներում, իսկ ես
          ցանկանում էի բոլորովին այլ տեսանկյունից դիտարկել խնդիրը: Բլոգային գրառում հրապարակեցի, որտեղ առանց
          մեկնաբանության իրար հետևից շարեցի 2001–2011 թվականների բոլոր արտահայտություններն իրենց տարե ու ամսաթվերով,
          որպեսզի կարողանանք մտորել ախտորոշման իսկության մասին: Այս ուսումնասիրության շնորհիվ ակնհայտ եղավ, որ շատ
          քաղաքական գործիչների ճանապարհը դեպի ռեժիմի հետանցք անցել է հենց խուճապի միջով: Խոսքը ռեժիմի խուճապ ազդարարած
          հատկապես հետևյալ հերոսներիս մասին է՝ Վիկտոր Դալլաքյան, Արտաշես Գեղամյան, Արթուր Բաղդասարյան, Դավիթ
          Շահնազարյան: Հրապարակմանն արձագանքած հերոսներիցս երկուսը՝ բանաստեղծ Մարինե Պետրոսյանն ու քաղաքական գործիչ
          Կարապետ Ռուբինյանն այդուհետ այլևս երբեք չհայտարարեցին, թե ռեժիմը խուճապի մեջ է (չնայած ՀՀ պատգամավոր Ռուբեն
          Ռուբինյանը 2016-ին շարունակեց հոր «գործը»): Ուշագրավ է, որ հրապարակումից հետո՝ հաջորդ տարի՝ 2012 թվականին,
          ռեժիմի խուճապի մասին ասույթները 75%-ով նվազեցին: Բայց խուճապիզմն այդպես էլ չդադարեց ո՛չ 2013-ին, ո՛չ 2014-ին,
          ո՛չ էլ ավելի ուշ: Ու օրերից մի օր, կարծեմ 2015 թվին, որոշեցի խուճապների ծավալուն հավաքածուս վերածել
          կոնցեպտուալ երկի, թատերգության, որը զսպագրի (constrained writing) մեթոդով ստեղծված աբսուրդի ու վերբատիմ
          թատրոնի հիբրիդ է:
        </p>
        <p>
          <b> Վերբատիմ խուճապ</b>
        </p>
        <p>
          Սկզբում ո՛չ բեմադրիչ էի պատկերացնում, ո՛չ էլ դերասան, քանի որ բեմականացումն անհնար էի համարում. գործող անձանց
          թիվն արդեն անցնում էր հարյուրը, ու արարներն էին քսանից ավելի: Բայց մի օր ինքս ինձ հարցրի՝ ինչո՞ւ ոչ: Եվ երբ
          Հայաստանում կա Նանոր Պետրոսյանի պես փորձարար բեմադրիչ, որը մասնավորապես վերբատիմ/վավերագրական թատրոնի ջատագով
          է, անհնարը դառնում է հնարավոր: Նրա ղեկավարած «Թեք տեղ» թատրոնը հայտնի է «Վերապատմում» (2016) և «Ջրինպատմեմ»
          (2019) վավերագրական ներկայացումներով: 2017-ի դեկտեմբերին առաջարկություն արեցի, մի քանի օր անց ընդունեց:
        </p>
        <p>
          <b>Թավշյա փոփոխություններ</b>
        </p>
        <p>
          Հեղափոխությունը խառնեց խաղաքարտերը: Երբ Սերժն ի վերջո տապալվեց, մի կողմից ուրախության արցունքեր էին գլորվում
          աչքերիցս, մյուս կողմից պիեսիս ավարտն էր փշրվում նույն այդ աչքերի առաջ: Սակայն առկախությունը շատ երկար չտևեց:
          Լուծումը վրա հասավ ամենաանսպասելի կողմից՝ նույն այդ անիծյալ ռեժիմից: Ու դրա համար ուզում եմ օգտվել առիթից ու
          մեծ շնորհակալություն հայտնել ՀՀԿ ներկայացուցիչներ Արմեն Աշոտյանին ու Էդուարդ Շարմազանովին, որոնք իշխանությունը
          կորցնելուց անմիջապես հետո վերցրեցին խուճապիզմի էստաֆետային փայտիկն ու հայտարարեցին, որ «Նեոբոլշևիկյան ռեժիմը՝
          Փաշինյանի գլխավորությամբ, բացահայտ խուճապի մեջ է» (Շարմազանով): Նրանք եղան այն պատճառը, որ 21 արարանոց պիեսը
          դարձավ 21 ու կես: Իսկական ներկայացում Բայց ինչպե՞ս մի ժամվա մեջ 250 անգամ կրկնել «խուճապ» բառն առանց
          հանդիսատեսի քթից բերելու: Ինչպե՞ս խաղալ: Սկզբում Նանորը որոշեց, որ կլինի պարզապես բեմական ընթերցանություն,
          որտեղ դերասանները հերթով պիտի պարզապես կարդային տեքստը: Բայց դերասանների կազմը հստակեցնելուց հետո հետզհետե
          արար առ արար գծագրվեց ներկայացումն ու մի գեղեցիկ օր Նանորը հայտնեց, որ կլինի իսկական ներկայացում:
        </p>
        <p>
          <b> Դերաբաշխում</b>
        </p>
        <p>
          12 դերասանով 168 երգ են երգում: Բայց դա արդեն վերջում էր: Գիտեմ, որ դերասանահավաքի առաջին իսկ օրը երկու հոգի
          հրաժարվել է մասնակցել բեմականացմանը: Մնացածները տարակուսանքի մեջ են եղել տեքստի հետ առաջին անգամ առնչվելիս,
          բայց շարունակել են հաճախել հանդիպումներին: Ընթերցումները, իհարկե, ծիծաղելու բազմաթիվ առիթներ էին նվիրում,
          սակայն սկզբում ոչ ոք չէր պատկերացնում պիեսի բեմականացումը: Այս առումով շատ եմ կարևորում տեքստն ու դրա հումորի
          անսովոր բնույթը տեղ հասցնելու, վերծանելու Նանորի անձնվեր աշխատանքը: Սիրողական դերասանական կազմի հետ հանդիպեցի
          ներկայացումից արդեն մեկ ամիս առաջ: Հիացած էի նրանց խաղով, տաղանդով ու ոգևորությամբ: Փորձերի ժամանակ կարողանում
          էին անգամ ինձ՝ տեքստին ատամնացավի չափ ծանոթիս, ծիծաղեցնել: Ի վերջո որոշվեց, որ 12 դերասաններով մարդա
          պլյուս-մինուս 14 գործող անձի դեր կխաղա: Դերերը բաշխվեցին կուրորեն: Օրինակ՝ առաջին գործող անձի դերը ստանձնած
          դերասանն ինքնաբերաբար խաղում էր նաև 13-րդ, 23-րդ, 33-րդ և այլն գործող անձանց դերերը: Չէի ասի «սիրելի», բայց
          զվարճալիներից կամ ուշագրավներից են Արտաշես Գեղամյանի, Նիկոլ Փաշինյանի, Ստյոպա Սաֆարյանի, Զարուհի Փոստանջյանի,
          Վիկտոր Դալլաքյանի ու «Չորրորդ իշխանություն» օրաթերթի թղթակից Հրայր Ավետիսյանի ասույթները: Բայց իմ
          հետազոտության ընթացքում ամենատհաճ անակնկալը եղավ մի երկու դեպքի բացահայտում, երբ «խուճապի» ամենաակտիվ PR-ով
          զբաղվող ընդդիմադիր լրատվամիջոցներից «Ա1+»-ը նենգափոխել էր իրեն հարցազրույց տված գործիչների (Լևոն Զուրաբյանի ու
          Մանվել Եղիազարյանի) խոսքը՝ ներդնելով չասված «խուճապ» բառը: «Ա1+»-ը հրաժարվեց ինձ հայտնել թղթակցի անուն(ներ)ը:
        </p>

        <p>
          <b> Երկխոսության մեջ</b>
        </p>
        <p>
          Սիրում եմ, երբ գիրքը կենդանության նշաններ է ցույց տալիս հատկապես, երբ դա արվում է ընթերցողին ներգրավելու
          շնորհիվ: «Ռեժիմը խուճապի մեջ է» գիրքն ունի այդպիսի բազմաթիվ ինտերակտիվ տարրեր, մասնավորապես հայաստանյան բոլոր
          հետխորհրդային հեղափոխությունների փորձերի իմ նկարազարդումները: Էջը թերթելով՝ ընթերցողն արարում է երկկադրանի
          փոքրիկ անիմացիա յուրաքանչյուր հեղափոխության յուրօրինակ տապալման մասին: Իսկ գրքի վերջում գիրքը հնարավորություն
          է ընձեռում ընթերցողին ի կատար ածել ու վերապրել հեղափոխական «դըմփ-դըմփ-հու»-ն՝ թմբուկ, փայտիկ ու ձեռնափեր
          պատկերող համապատասխան էջերն իրար շրխկացնելով:
        </p>
        <p>
          Անհրաժեշտ էր ինտերակտիվությունը պահել նաև բեմում։ Պատկերացրեք, որ ներկայացում եք դիտում, մեկ էլ կեսից գործող
          անձանցից մի երկուսը ելնում են բեմ ու ընդհատելով խաղը՝ քննարկում իրենց պատկերումը տվյալ թատերգությունում: Այս
          մետաթատրոնի տարրն է մարմնավորվում ֆեյսբուքյան քննարկման ընթացքը ներկայացնող տեսանյութը, որը ցուցադրվել է
          ներկայացման մեջտեղում: Այստեղ թատերգությանս հերոսները՝ Մարինե Պետրոսյանն ու Կարապետ Ռուբինյանը ոչ միայն
          քննարկում են իրենց պատկերումը երկում, այլև հարցեր են ուղղում հեղինակին: Տեսանյութը պիեսը կարծես էլ ավելի
          վավերագրական է դարձնում, քանի որ խոսում են իրենք՝ գործող անձինք, առանց դերասանների միջամտության: Պարադոքսն այն
          է, որ մետաթատրոնի/մետապատումի դերը բուն ստեղծագործության/ներկայացման հորինվածությունը շեշտելն է, մինչդեռ
          թիրախում վավերագրական պիես է բառացի մեջբերումներով: Մյուս կողմից՝ դա թատրոն է թատրոնի մեջ, քանզի ի՞նչ է
          ֆեյսբուքյան քննարկումը, եթե ոչ վիրտուալ թատրոն վիրտուալ հանդիսատեսներով ու գործող անձանցով: Պոստխուճապ
          Աշոտյանով ու Շարմազանովով հետհեղափոխական խուճապիզմը չսահմանափակվեց: Հիմա էլ խուճապի ասեղի վրա են նստել նորօրյա
          ընդդիմադիրները՝ ի դեմս «Ադեկվադ» միաբանության անդամների ու ԶԼՄ-ների: Գիրք չկարդալու հետևանք:
        </p>

        <p>Արեգ Դավթյանի</p>
        <p>Երևան ամսագիր</p>
        <p>02.06.2020</p>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freedom));
