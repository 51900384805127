import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Topnav from './Topnav';
import './MainLayoutWithIcons.css';
import $ from 'jquery';

let data = require('./lang/lang.json');

const MainLayoutWithIconsMobile = ({ children, section, language, history, homePage }) => {
  if (!localStorage.getItem('language')) {
    localStorage.setItem('language', 'arm');
  }
  const text = data[localStorage.getItem('language')];
  const [isHamburgerToggled, setHamburgerToggled] = React.useState(false);
  const toggle = React.useCallback(() => setHamburgerToggled(!isHamburgerToggled), [isHamburgerToggled]);

  const onMobileClick = () => {
    $('.mobile-stage-inner').slideToggle();
  };
  const onMobileClickSchool = () => {
    $('.mobile-school-inner').slideToggle();
  };
  const onMobileClickWe = () => {
    $('.mobile-we-inner').slideToggle();
  };
  const onMobileClickKond = () => {
    $('.mobile-kond-inner').slideToggle();
  };
  const onHamburgerClick = () => {
    $('.mobile-menu').fadeIn('slow');
  };
  const onCloseClick = () => {
    $('.mobile-menu').fadeOut('slow');
  };
  return (
    <>
      <header className='header-mobile'>
        <div className={`full-screen-menu ${isHamburgerToggled ? 'd-block' : 'd-none'}`}>
          <div className='overflow-hidden home'>
            <div className='menu-arrow'>X</div>
            <div className='container-fluid'>
              <div className='line animate animate__animated animate__bounce animate__delay-0 animate__fadeInUp first'>
                <img src='images/menu-line-1.svg' className='img-fluid' alt='line' title='line' />
              </div>

              <div className='line animate animate__animated animate__bounce animate__delay-1s animate__fadeInDown second'>
                <img src='images/menu-line-2.svg' className='img-fluid' alt='line' title='line' />
              </div>
              <div>
                <div className='stage  menu-item animate animate__animated animate__bounce animate__delay-1s animate__fadeInDown'>
                  <p>{text.on_stage}</p>
                  <img src='images/on-stage-circle.svg' className='img-fluid' alt='logo' width='41px' title='logo' />
                  <div className='menu-inner  animate animate__animated animate__bounce animate__delay-0s animate__fadeInUp'>
                    <a href='/weplay'>{text.weplay_title}</a>
                    <a href='/weread'>{text.weread}</a>
                    <a href='/weconnect'>{text.we_connect}</a>
                  </div>
                </div>
                <div className='school menu-item animate animate__animated animate__bounce animate__delay-0s animate__fadeInDown'>
                  <p>{text.the_school}</p>
                  <img src='images/school-circle.svg' className='img-fluid' alt='logo' width='15px' title='logo' />
                  <div className='menu-inner   animate animate__animated animate__bounce animate__delay-0s animate__fadeInUp'>
                    <a href='/platform'>{text.platform}</a>
                    <a href='/lab'>{text.lab}</a>
                    <a href='/tribune'>{text.tribune}</a>
                    <a href='/writing'>{text.writing}</a>
                    <a href='/exposition'>{text.exposition}</a>
                  </div>
                </div>
                <div className='about-us  menu-item  animate animate__animated animate__bounce animate__delay-0s animate__fadeInUp'>
                  <p>{text.about_us}</p>
                  <img src='images/about-us-circle.svg' className='img-fluid' alt='logo' width='41px' title='logo' />
                  <div className='menu-inner  animate animate__animated animate__bounce animate__delay-0s animate__fadeInUp'>
                    <a href='/we'>{text.we}</a>
                    <a href='/coming'>{text.coming}</a>
                    <a href='/opinion'>{text.opinion}</a>
                  </div>
                </div>
                <div
                  className='kond-festival menu-item  animate animate__animated animate__bounce animate__delay-0s animate__fadeInUp'
                  onClick={() => onMobileClickKond()}
                >
                  <p>{text.kond}</p>
                  <img src={`images/kond-circle.svg`} className='img-fluid' alt='logo' width='41px' title='logo' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='hamburger-icon'>
            <div className='float-left'>
              <Topnav cls='button_language' />
            </div>
            <div className={`hamburger ${isHamburgerToggled ? 'is-active' : ''}`} id='hamburger-1' onClick={toggle}>
              <span className='line circle-line'>
                <img src='images/yellow-item1.svg' className='img-fluid' alt='item' title='item' />
              </span>
              <span className='line circle-line circle-line-transparent'>
                <img src='images/green-icon1.svg' className='img-fluid' alt='item' title='item' />
              </span>
              <span className='line circle-line'>
                <img src='images/pink-icon.svg' className='img-fluid' alt='item' title='item' />
              </span>
            </div>
          </div>
        </div>
      </header>

      <div className='home-mobile current-header'>
        <div className={`mobile-header ${homePage ? '' : 'header-bg-line'} `}>
          <div
            id='hamburger-mobile'
            onClick={() => {
              onHamburgerClick();
            }}
          >
            <span className='line circle-line'>
              <img src='images/yellow-item1.svg' className='img-fluid' alt='item' title='item' />
            </span>
            <span className='line circle-line circle-line-transparent'>
              <img src='images/green-icon1.svg' className='img-fluid' alt='item' title='item' />
            </span>
            <span className='line circle-line'>
              <img src='images/pink-icon.svg' className='img-fluid' alt='item' title='item' />
            </span>
          </div>
        </div>

        <div className='mobile-menu'>
          <button
            className='close-btn'
            type='button'
            onClick={() => {
              onCloseClick();
            }}
          >
            X
          </button>
          <div className='mobile-line mobile-stage'>
            <div
              className='menu-mobile-item'
              onClick={() => {
                onMobileClick();
              }}
            >
              <span>{text.on_stage}</span>
              <img src='images/on-stage-circle.svg' className='img-fluid' alt='logo' width='41px' title='logo' />
            </div>
            <span className='mobile-stage-icon mobile-icon'></span>
            <div className='mobile-stage-inner'>
              <div className='mobile-stage-list stage menu-item'>
                <a className='d-flex' href='/weplay'>
                  {text.weplay_title}
                </a>
                <a className='d-flex' href='/weread'>
                  {text.weread}
                </a>
                <a className='d-flex' href='/weconnect'>
                  {text.we_connect}
                </a>
              </div>
            </div>
          </div>
          <div className='mobile-line mobile-school'>
            <div
              className='menu-mobile-item'
              onClick={() => {
                onMobileClickSchool();
              }}
            >
              <span>{text.the_school}</span>
              <img src='images/school-circle.svg' className='img-fluid' alt='logo' width='15px' title='logo' />
            </div>

            <div className='mobile-school-icon mobile-icon'></div>
            <div className='mobile-school-inner'>
              <div className='mobile-school-list school menu-item'>
                <a className='d-flex' href='/platform'>
                  {text.platform}
                </a>
                <a className='d-flex' href='/lab'>
                  {text.lab}
                </a>
                <a className='d-flex' href='/tribune'>
                  {text.tribune}
                </a>
                <a className='d-flex' href='/writing'>
                  {text.writing}
                </a>
                <a className='d-flex' href='/exposition'>
                  {text.exposition}
                </a>
              </div>
            </div>
          </div>
          <div className='mobile-line mobile-we'>
            <div
              className='menu-mobile-item'
              onClick={() => {
                onMobileClickWe();
              }}
            >
              <span>{text.about_us}</span>
              <img src='images/about-us-circle.svg' className='img-fluid' alt='logo' width='41px' title='logo' />
            </div>
            <div className='mobile-we-icon mobile-icon'></div>
            <div className='mobile-we-inner about-us menu-item'>
              <a className='d-flex' href='/we'>
                {text.we}
              </a>
              <a className='d-flex' href='/coming'>
                {text.coming}
              </a>
              <a className='d-flex' href='/opinion'>
                {text.opinion}
              </a>
            </div>
          </div>
          <div className='mobile-line mobile-kond'>
            <div
              className='menu-mobile-item'
              onClick={() => {
                onMobileClickKond();
              }}
            >
              <span>{text.projects}</span>
              <img src={`images/kond-circle.svg`} className='img-fluid' alt='logo' width='41px' title='logo' />
              <div className='mobile-kond-icon mobile-icon'></div>
              <div className='mobile-kond-inner kond menu-item'>
                <a className='d-flex' href='/kond_fest'>
                  {text.kond}
                </a>
                <a className='d-flex' href='/unread'>
                  {text.unread}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayoutWithIconsMobile));
