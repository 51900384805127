import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function Freedom() {
  return (
    <div className='main-container'>
      <div className='breadcrumb container'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/opinion14' className='breadcrumb-active'>
          ՎԱՎԵՐԱ/գրա/ԿԱՆՆ ՈՒ ԱՐՎԵՍՏԸ
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong> ՎԱՎԵՐԱ/գրա/ԿԱՆՆ ՈՒ ԱՐՎԵՍՏԸ</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item7.jpeg' alt='icon' className='img-fluid' />
        <p>
          Վերջերս հաճախ եմ մտածում վավերագրականի և արվեստի փոխհարաբերության մասին։ Վավերագրականը դոկումենտալն ա։ Ուրիշ
          բառով կարելի ա ասել՝ փաստագրական։ Վերջին տարիներին հաճախ եմ լուսանկարներ անում, և առաջինը հենց լուսանկարներն
          էին, որ ինձ դրդեցին լուրջ մտածել էս հարցի շուրջ։ Դրա մասին գրել եմ Մկրտիչ Մաթևոսյանի վերջին ցուցահանդեսին
          անդրադարձող տեքստումս՝ ՄԿՐՏԻՉԻ ՑՈՒՑԱՀԱՆԴԵՍԸ ԳՅՈՒՄՐԻՈՒՄ
          <a href='https://www.marinepetrossian.com/hy/mkrtich-matevosian'>
            https://www.marinepetrossian.com/hy/mkrtich-matevosian
          </a>
          Հետո Լուսինե Հովհաննիսյանի գիրքը կարդացի՝ &quot;Տարին ունի շատ եղանակ&quot; (տես՝ ԿՅԱՆՔԻ ՀԻՇՈՂՈՒԹՅՈՒՆԸ 
          <a href='https://www.marinepetrossian.com/hy/lusine-hovhannisyan-book'>
            https://www.marinepetrossian.com/hy/lusine-hovhannisyan-book
          </a>
           Լուսինեի գիրքը երկու մասից ա կազմված, առաջինը վերնագրված ա ՝ վավերական գրականություն, երկրորդը՝ գրական
          վավերագրություն։ Հետաքրքիր կլիներ հասկանալը՝ ինչով են առաջինն ու երկրորդը տարբերվում իրարից, երբ երկուսում էլ
          իրական մարդիկ և իրականում պատահած դեպքեր են նկարագրված։ Գուցե նրանո՞վ, որ առաջին բաժնի տեքստերում հեղինակը,
          հնարավոր ա, իրան ազատ ա զգացել՝ փոխելու իրականում պատահածի որոշ դետալներ։ Տեսնես ինքը՝ Լուսինեն ի՞նչ կասի
          երկու բաժնի տեքստերի միջև եղած տարբերության մասին։ Վավերա/գրա/կանի ու արվեստի փոխհարաբերության խնդիրը
          հետաքրքիր ա նաև կինոյում և թատրոնում։ Անցած շաբաթ իրար հետևից երկու դիտումի մասնակցեցի, որոնք երկուսն էլ
          քննարկելի են դարձնում էս հարցը։ Հինգշաբթի օրը Espaces կենտրոնում հանդիպում էր Հովհաննես Իշխանյանի հետ,
          ցուցադրվեցին «Նախկինների խրոնիկան»  և «Ընտանեկան ալբոմ» ֆիլմերը։ Երկրորդին վաղուց ծանոթ էի, առաջինը չէի
          տեսել։ «Նախկինների խրոնիկան» վավերագրական ֆիլմ ա, որտեղ հեղինակները՝ Հովհ․ Իշխանյանը և Սոնա Սիմոնյանը, պատմում
          են իրանց սերերի փլուզման և բաժանումի պատմությունը։ Ֆիլմը նկարելու համար հեղինակները նորից հանդիպել են իրանց
          նախկին ընկերուհու և ընկերոջ հետ, հետադարձով միասին պատմում են ու վերլուծում, թե ինչ պատահեց, ոնց և ինչու
          պատահեց։ Այսինքն էս  ֆիլմում հեղինակները միաժամանակ նաև գործող անձ են։ «Նախկինների խրոնիկան» նայելուց ընդամենը
          մեկ օր էր անցել, գնացի «Թեք տեղ»՝ Նանոր Պետրոսյանի և Գայանե Բալյանի «Ջրին պատմեմ» ներկայացումը նայելու, որի
          ժանրը հեղինակները բնորոշել են որպես վավերագրական-դրամա։ Մեծ սենյակ էր, աթոռները կլոր շարված էին, մեծ մասը՝
          զբաղված, մեկին էլ ես նստեցի։ Գիտեի որ ներկայացման մասնակիցները աթոռներին նստածների մեջ էին, բայց չգիտեի ովքեր
          են։ Մի քիչ լուռ նստեցինք, հետո երկու աթոռ ինձանից աջ նստած աղջիկը սկսեց խոսել։ Պատմում էր, թե ոնց փոքր ա եղել,
          մայրը մահացել ա ու ինքը մորը չէր ներում, որ իրան անօգնական թողած՝ հեռացավ։ Լսելը հաճելի չէր, որովհետև
          պատմությունը կեղծ էր հնչում, լեզուն էլ տհաճ էր՝ ռուսերենի և ֆորմալ-անկենդան հայերենի խառնուրդ։ Իսկ դիմացս
          նստած աղջկա դեմքին խորը հուզմունքի արտահայտություն էր։ Ու ես, հիշում եմ, ինքս ինձ հարցրի՝ տեսնես իսկապե՞ս
          հուզվել ա էս աղջիկը, թե սա ներկայացման մաս ա/ վերջում պարզվեց՝ կարծես երկրորդը/։ Առաջին աղջիկը վերջացրեց, մի
          րոպե լռություն տիրեց, հետո սկսեց խոսել ինձանից ձախ նստած աղջիկը։ Պատմում էր թե ոնց ա դավաճանել ամուսնուն, թե
          ինչ ա պատահել և ինչ ա զգացել դրանից հետո։ Պատմությունը հետաքրքիր էր, բայց մի բան պակասում էր։ Եվ սա կրկնվեց
          նաև հաջորդ պատմությունների ժամանակ՝ դրանցից մի քանիսը հետաքրքիր էին, բայց էլի՝ հաճույք չէի կարողանում ստանալ,
          մի բան պակասում էր։ Ուզում էի հասկանալ՝ ինչն ա պակաս, ու հենց էդ ժամանակ էլ ինքս ինձ հարց տվեցի՝ էս մարդիկ
          իրանց հետ պատահա՞ծն են պատմում, թե դերասան են։ Եթե նախօրոք հետաքրքված լինեի, հարցիս պատասխանը կիմանայի, բայց
          նախօրոք չէի հետաքրքրվել, ներկայացումից հետո հարցուփորձ անելով հասկացա՝ «Ջրին պատմեմ» ներկայացման մեջ
          պատմություն պատմողները ոչ իրական գործող անձիք էին, ոչ էլ դերասան։ Պատմություններն իրական էին, դրանք պատմել էին
          իրական մարդիկ, բայց ոչ էն մարդիկ, որոնց ես ներկայացման ժամանակ լսում էի։ «Ջրին պատմեմ»-ի ֆեյսբուքյան իվենթում
          գրված ա՝ «Ներկայացումը բաղկացած է ինը մոնոլոգից, որոնք հավաքագրել  են դերասանները` զրուցելով ինը տարբեր կանանց
          հետ»։ Նոր նկատեցի՝ ներկայացման մասնակիցները ստեղ անվանված են՝ դերասան։ Բայց ես, հիշում եմ, երբ որ իրանցից
          մեկին հարցրի՝ դուք դերասա՞ն եք, ասեց՝ չէ։ Իսկ մի երկուսին էլ դե արդեն անձամբ գիտեի՝ որ դերասան չեն։ Ոնց որ
          հենց ստեղ ա խնդիրը՝ ներկայացման մասնակիցների կարգավիճակի էս անորոշության մեջ։ Դերասան լինել նշանակում ա խաղալ,
          եթե «Ջրին պատմեմ»-ում պատմություն պատմող աղջիկները դերասան լինեին, իրանք երևի թե ուրիշ ձևով պատմեին էդ
          պատմությունները՝ որոշ բաներ կփոխեին, որ պատմությունն ավելի լավ տեղ հասցնեն։ Եթե մյուս տարբերակը լիներ՝ մարդիկ
          հենց իրանց հետ պատահածը պատմեին, էլի պատմելու ձևը կարծում եմ ուրիշ կլիներ, խոսքը երևի ավելի խառը լիներ, ոչ
          էդքան հստակ կառուցված-կապակցված, բայց փոխարենը՝ ավելի կենդանի կլիներ։ Բանավոր խոսք կլիներ։ Իսկ էս դեպքում
          խոսքը գրավորի էր նման, չնայած իհարկե թղթից չէին կարդում։ «Նախկինների խրոնիկան» ֆիլմում հենց նշածս
          երկրորդ տարբերակն էր, երբ մարդիկ իրանց հետ պատահածն էին պատմում։ Բայց միայնակ չէին պատմում, մոնոլոգ չէր,
          դիալոգ էր՝ նախկին ընկերոջ կամ ընկերուհու հետ խոսելով էին պատմում։ Հին եղելությունները հասկանալու համար նոր
          եղելություններ էին սարքել՝ նոր հանդիպումներ, և էդ հանդիպումները կամերայով նկարել էին։ Վավերագրություն էր, բայց
          նաև կինո։ Եվ էդ ֆիլմը նայելիս ես մտածում էի՝ եթե մեր հետ կատարվող ավելի շատ բան սենց վավերագրենք, ավելի լավ
          կհասկանանք ինչ ա մեր հետ կատարվում և էդ դեպքում գուցե կկարողանանք փոխել մեր կյանքը՝ էն ուղղությամբ որ ինքներս
          ենք ուզում։ Մի ուրիշ տեղ դուրս կգանք։ Գուցե փորձե՞նք։ Վերջերս հաճախ եմ մտածում վավերագրականի և արվեստի
          փոխհարաբերության մասին։ Փորձեցի իմ էս տեքստով վավերագրել էդ մտածումներս։
        </p>
        <p>Մարինե Պետրոսյան</p>
        <p>marinepetrossian.com</p>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freedom));
