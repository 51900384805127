import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function Freedom() {
  return (
    <div className='main-container'>
      <div className='breadcrumb container'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/freedom' className='breadcrumb-active'>
          Ներկայացում աղմուկի մասին
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong>Ներկայացում աղմուկի մասին</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item2.jpeg' alt='icon' className='img-fluid' />
        <p>
          <em>
            Վերապատմումը» խնդիր չի դնում, սակայն առիթ է դառնում, որ մտածենք խոսելու, գործելու եղանակների ու տեղերի
            մասին, որով չենք ձայնակցի աղմուկին կամ կվերամտածենք աղմուկը։
          </em>
        </p>
        <p className='mt-3'>
          Միտք չունեի գրել «Վերապատմում» վավերագրություն-իմպրովիզացիա ներկայացման մասին, երբ գնում էի այն դիտելու։
          Մտքովս չէր էլ անցնում, նույնիսկ երբ քննարկում էինք ընկերներիս ու ծանոթներիս հետ։ Միտքն եկավ, երբֆեյսբուքում
          կարդացի Վիոլետ Գրիգորյանի դիտարկումը Մարինե Պետրոսյանի հոդվածի* մասին, քանի որ դա մի կողմով համընկնում էր իմ
          ու Նարէ Սահակյանի խոսակցությանը։ Հետո այլ անդրադարձներ տեսա, ու միտքն իրեն առաջ մղեց՝ չնայած «նոր տեքստ գրելու
          ո՛չժամանակ եւ ո՛չ էլ հավես» ունեի։ Ինչպիսի՜ ծանոթ դրություն շատերի համար, այնպես չէ՞։ Ուրեմն ինչի՞ իմ միտքն էլ
          չգրեմ ֆեյսբուքում ու առաջ անցնեմ։ Ֆեյսբուքում (եւ ոչ միայն) արտահայտվել-լռելու տարակուսանքը հաճախ է առաջանում,
          եւ ոչ միայն ինձ մոտ... Այս տարակուսանքը կար նաեւ «Վերապատմումի» դահլիճում ներկաների շրջանում։ Ներկայացման
          թոք-շոու ձեւաչափը, որտեղ չկա բեմական տարանջատում, իսկ դերասաններից երեքը հանդիսատեսի շարքերում են,
          անհրաժեշտաբար ենթադրում է հանդիսատեսի խոսքը։ Մտածված է հանդիսատեսին դարձնել խաղի մաս, սակայն հանդիսատեսի՝
          արտահայտվել- լռելու տարակուսանքը օբյեկտիվորեն մնում է։ Ի՞նչն է հանդիսատեսին հետ պահում խաղի մեջ մտնել, կապ
          ունի՞ դա հանրային կյանքին մասնակցությունից (ցույցի գնալուց կամ քաղաքացիական գործողություններին միանալուց) հետ
          պահող գործոնների հետ։
        </p>
        <p>
          Մարինե Պետրոսյան, «Ծաղրանկարն ու օպերան», 24.12.2015,
          <a href='http://www.tert.am/am/author/3/1883220'> http://www.tert.am/am/author/3/1883220 </a>(խոսքը գրող
          Մարինե Պետրոսյանի մասին է, և ոչ՝«Վերապատմում» ներկայացման համանուն դերասանուհու)։ " «Վերապատմումի» իմ տեսած
          երկու խաղերում հանդիսատեսի մասնակցության երկու դեպք եմ նկատել։ Առաջինը խաղացողներից մեկի՝ Արփի Բալյանի հոր
          արձագանքն էր, որը լրացում էր ներկայացման մեջ իր կենսագրությանն առնչվող պատումային դրվագին։ Մյուսը՝ արվեստագետ
          Լուսինե Թալալյանի միջամտությունը, որ ճիշտ հակառակը՝ հնչող պատումից շեղող ժեստ էր։ Քարտուղարուհու կերպարին
          ուղղված՝ «էդ ժիլետդ քանիսո՞վ ես առել» հարցի միջոցով ընգծվում էր կերպարի բնութագիրը, դրանով լրացնում այն, բայց
          շեղում պատումից, թեկուզ մեկ կարճատեւ պահ։ Հանդիսատեսի մեծ մասը (այդ թվում ես), սակայն լուռ էր մնում, ինչը չի
          նշանակում, թե հուզականորեն ներգրավված չէր։ Խոսելու տարակուսանքն ու լռելու որոշումը, ինչպես նաեւ ներկայացումից
          առաջ ու վերջում ֆոնային ձայնագրության եւ ներկայացման ընթացքի ականջ ծակող աղմուկը, դարձան ներկայացումը
          հասկանալու իմ հիմնական միջոցները։ Այս ներկայացումը պատումների աղմկոտ հավաքածու է։ Մի խումբ երիտասարդներ
          ներկայացնում են Հայաստանի վերջին տասնամյակների պատմության շուրջ սեփական ընտանիքում լսած պատումները։ Նրանց մեծ
          մասը դերասան չէ՝ սոցիալապես ակտիվ, երբեմն նաեւ «ակտիվիստ» երիտասարդներ են եւ խաղում են իրենք իրենց։ Թեեւ
          բոլորը երիտասարդ են եւ ընտրված այնպես, որ իրենց մանկությունն ապրել են 1990-ականներին՝ խումբը որոշ չափով
          տարասեռ է՝ մոտավոր ներկայացուցչական (Երեւանի կենտրոնից վտարված բնակիչ, մարզից մայրաքաղաք տեղափոխված ակտիվ
          երիտասարդներ, արտագաղթած նախկին հայրենադարձների վերադարձած սերունդ եւ այլն): Նրանց պատումները հատվածային են,
          անջատ հիշողություններ՝ հաճախ ներքուստ հակասական, երբեմն՝ մինչեւ իսկ զառանցական։ Հիմնականում մեկի պատմածը չի
          լրացնում մյուսին, հնչում են միմյանց ընդհատելով ու ոչ գծային ժամանակագրությամբ։ Թոք-շոուի քարտուղարուհու կողմից
          առաջարկվող ինտերնետային տեսա-պատումները կամ հոդվածները գալիս են որոշ չափով համալրելու կամ փաստարկելու դրանք։
          Կան նաեւ ավագ սերնդից սոցիալական տարբեր շերտեր ներկայացնող կերպարներ, որոնք հանդիսատեսի շարքերից իրենց
          պատումներով միջամտում են թոք-շոուի երիտասարդ հյուրերին։ Պատմության ուսուցչի դասագրքային պատումը կառչած է
          ձեւական- պաշտոնականի բացարձակ ճշմարտացիության համոզմունքից, որն ակնհայտ հակադիր է հնչած մյուս պատումներին։
          Տարեց մարդու կերպարը զայրացած, խորհրդային կյանքի հուզական նոստալգիայով, նորագույն քաղաքական կյանքի հանդեպ
          խորին կասկածով մեկն է։ Իսկ բոհեմական կնոջ պատումները զգայականորեն անձնականացված են՝ նրա հայացքում հանրային
          կյանքը տրոհված է՝ ըստ իր սիրային կյանքի փուլերի։ Կա պատումների համակարգման փորձ անող հաղորդավար, որի
          ֆունկցիան, այնուամենայնիվ, շոուի անխափանությունը ապահովելն է, ինչն անհաջողության է մատնվում դրսից՝
          բողոքող-հեղափոխական երիտասարդի մերթընդմերթ ներխուժումներով։ «Քսան հեղափոխություն» արած երիտասարդը առհասարակ
          դուրս է պատումների գործընթացից, դրանք նրան հետաքրքիր չեն. հավանաբար ինքն իրենն ունի։ Նա մղված է հերթական
          հեղափոխության իրականացմանը, որին կոչ է անում միանալ մյուսներին։ Այս կերպարը հուշում է նաեւ թոք-շոուից դուրս
          իրականության մեջ հեղափոխությունների աղմուկի առկայությունը։ Ձայնը, իբրեւ պատումների հնչեցման միջոց, կարեւոր
          բաղադրիչ է ներկայացման մեջ։ Անձայն կամ ականջ խլացնող բարձրաձայն տեսա-պատումներ, «մինչեւ հետեւի շարք չհասնող»՝
          «վատ լսվող» խոսքեր եւ գոռգոռոցներ. սրանք կարծես ընդգծում են պատումների դիրքերն ու խզումները։ Ներկայացման
          աղմուկն, ըստ էության, ներառում էերիտասարդների՝ մինչ այժմ հանրային կյանքում ցածրաձայն կամ խուլ հնչած պատումների
          բարձրաձայնումը։ Սակայն ինչի՞ համար են այս պատումների բարձրաձայնումը, այլ պատումների հետ զուգադրումն ու դրանց
          տրոհումների ցուցադրումը։ Ի՞նչն են դրանք կասկածի տակ դնում, ի՞նչն են կազմաքանդում։ «Չափազանց հետարդիական
          մտադրություն չէ՞ արդյոք. այնինչ՝ մի բան կազմաքանդելու համար պիտի այդ մի բանն ունենաս»,- այս հարցն էր տալիս
          Նարէն, երբ քննարկում էինք ներկայացումը։ Մարինեի հետ բանավեճում մոտավորապես նույն հարցն է հնչեցնում Վիոլետը, թե
          այդ ինչ իշխող պատում է հարցականի տակ դնում ռեժիսոր Նանոր Պետրոսյանը (Նանորի խոսքերով ներկայացման նպատակադրումը
          պատմությունը կասկածի տակ դնելն է)։ Սա ամենից թիրախային հարցն է, որ առաջանում է ներկայացման հանդեպ։ Այն, ինչ
          ներկայացումն է անում, սակայն, արձանագրելն է այդ մեծ պատումի չգոյությունը՝ դրա գոյության հավատի կամ հույսի
          պայմաններում, երբ յուրաքանչյուր սոցիալական խումբ իր սեփական պատումի պատում լինելը կասկածի տակ չի դնում։
          Ներկայացումն արձանագրում է․ մեծ պատումը կազմաքանդված է։ Դրան փոխարինող պատումները կայացած չեն, վարկաբեկված են
          ու դրված կասկածի տակ․ դրանց չեն հավատում երիտասարդները։ Եվ հենց սա է զայրացնում ոչ միայն պատմության ուսուցչի
          կերպարին, այլեւ Մարինեին։ Վերջինս իր հոդվածում առաջարկում է, իհարկե պատմության ուսուցչից տարբերվող՝ սեփական
          պատումն ու շեշտում դրա կարեւորությունը։ Մարինեն որպես հանդիսատես որոշել էր ոչ թե տեղում միանալ
          վերապատմումներին, որով ներկայացման մասը կդառնար, այլ խոսել հետո։ Հոդվածի կենտրոնում իր տեսած 88-ի ու դրա
          նշանակության պատումն է։ Ուրեմն ինչ-որ առումով, իր տեքստը դառնում է ներկայացման շարունակությունը, ոչ թե մնում
          զուտ տեքստ ներկայացման մասին։ Մյուս ընկերներիս հետ խոսակցություններում եւս նկատում եմ պատումների հետ
          համաձայնության-անհամաձայնության քննարկումները։ Թերեւս, սա ներկայացման եւ մեր հանրային կյանքի տրամաբանության ու
          ռիթմի համընկնման հրաշալի ցուցիչ է, որն առկա է՝ անկախ ներկայացման թատերականության չափազանցումներից։
          Ներկայացումն աշխատում է ֆեյսբուքի՝աղմկոտ, սահող ու վերադարձող խոսակցության նման, այն ներգրավում է
          հանդիսատեսին, բայց ո՞ւր է տանում։ Այն ունի համակարգման ներքին ձգտում. հաղորդավարի կերպարի բառերով
          «պատմությունը համակարգման կարիք ունի», որը սակայն անհրաժեշտաբար բացառվում է։ Քանի որ ստեղծված աղմուկի մեջ
          ցանկացած նոր խոսք վերածվում է մի նոր պատումի, դառնում աղմուկի մեջ մի նոր ձայն եւ ոչինչ ավելին։ Հնչող
          պատումները բացահայտում են առասպելականացված իրականությունը, որին նույնիսկ դիմադրել ձգտող ձայնը ոչ թե
          ապակառուցում է այն, այլ միանում է առասպել-պատումների բազմապատկմանը։ Հաղորդավարի հուսալքված հեռանալը բխում է
          աղմուկի տրամաբանությունը փոխելու անկարողությունից, կամ ավելի դաժան՝ հավանական անհնարինությունից։ Ներկայացման
          աղմուկն առաջին հերթին ինքն իր մասին է աղաղակում։ Աղմուկը հնչում է իբրեւ Հայաստանի հանրային կյանքի հիմնական
          բնութագիր, որն իրեն խոսակցություն է ձեւացնում, որտեղ լի են բարձր ու ցածր ձայները, բայց խիստ սակավ՝ բանավեճը,
          շատ են հույզերն ու ապրումները, սակայն հազիվ նկատելի է ապրելը, կան քայլեր, բայց խիստ սակավ է արարքը։ Բացի մեծ
          պատումի բացակայությանից, ներկայացումը արձանագրում է նաեւ առկա պատումների որպես պատում ընկալվելու
          բացակայությունը եւ բացահայտում դրա ցավոտ հետեւանքը։ Այս ներկայացումը վառ ցուցադրում է ոչ միայն ամեն պատումի
          պատում լինելը, այլեւ տեսանելի է դարձնում պատումների հանդեպ մեր վերաբերմունքները։ Յուրաքանչյուր կերպար, իր
          պատումին վերաբերում է իբրեւ պատմության։ Հետեւաբար պատմության որոնումն իսկ դատապարտված է։ Ուրեմն թեեւ
          ներկայացման մտահաղացման մեջ ներդրված է պատմության հանդեպ կասկածը՝ այն ցույց է տալիս անվերապահ ընդունված
          պատումների աղմկոտ հոսանքներում պատմություն ունենալու դժվարինությունը։ Կրկին. խոսել, թե՞լռել, գրել ֆեյսբուքում,
          թե՞ դուրս գրվել ֆեյսբուքից, արտագաղթել, թե՞հեղափոխություն անել։ Այս ծուղակ հարցերը դառնում են Հայաստանի
          խնդիրների մասին մտորող, փոփոխություններով մտահոգ՝ ակտիվ հանրության համար օբյեկտիվ՝թե՛ ներկայացման ներսում, թե՛
          դրանից դուրս։ Եվ դրանք կմնան այդպիսին, քանի դեռ չեն փոխարինվել ինչպե՞ս խոսել, ինչպե՞ս լռել, ինչպե՞ս գրել,
          Հայաստանում ինչպե՞ս ապրել, ո՞ր դեպքում կամ հատկապես ի՞նչ անելու համար երկրից գնալ հարցերով։ Արվեստագետ Տիգրան
          Խաչատրյանի՝ մի առիթով ասած խոսքերով՝«պետք է գտնել ակտիվ լռելու եղանակներ»։ Ակտիվ լռելու կամ ինչպես խոսելու,
          ինչպես ինչն անելու կամ գուցե ինչ-որ բան չանելու մասին հարցեր, որ շատերիս կարող են մտահոգել, շրջապտույտ
          աղմուկին բախվելիս, աղմուկին, որն ի վերջո նաեւ ակտիվ լռության կամ իրական խոսակցության պայմանի պոտենցիալն է
          պարունակում, աղմուկին, որի հետ բախումից հանրային մասնակցության փորձով մարդիկ հաճախ պապանձվում են, կարեւորում
          լռությունը, լքում ֆեյսբուքը, մեկնում երկրից, տեղափոխվում քաղաքից հեռու, փնտրում մտածելու, կարդալու խաղաղ
          անկյուն կամ ուժերը դնում համակարգված քաղաքականություն ձեւավորելու երազանքին։ Իրականության հետ մեզ առերեսող
          «Վերապատմումը» խնդիր չի դնում, սակայն առիթ է դառնում, որ մտածենք խոսելու, գործելու եղանակների ու տեղերի մասին,
          որով չենք ձայնակցի աղմուկին կամ կվերամտածենք աղմուկը։ Ինչպես օրինակ այս դեպքում, եթե խոսել, ապա ոչ ֆեյսբուքյան
          գրառումով, այլ մի տեքստով, որը անշտապ խոսքի ու պատմության երազանքի տեղում հնչեցվելու եւ այդ տեղն ընդլայնելու
          նպատակադրումն ունի՝ չնայած իր իսկ դրած խնդրին մինչեւ վերջ հետեւողական չգտնվելով՝ չի խուսափի աղմուկի մասը
          կազմելուց կամ աղմուկին մի արձագանք դառնալուց։
        </p>
        <p>Աննա ժամակոչյան</p>
        <p> Arteria.am</p>
        <p>04.03.2016</p>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freedom));
