import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import './bem.css';

const data = require('./lang/lang.json');

function Dansteele() {
  const text = data[localStorage.getItem('language')];
  return (
    <section className='main-container current-container'>
      <div className='breadcrumb current-inner-container'>
        <a href='/platform'>{text.platform}/</a>
        <a href='/dansteele' className='breadcrumb-active'>
          {text.dansteele}
        </a>
      </div>
      <div className='section-details modal-slider'>
        <div className='current-inner-container platform-inner slider-centered'>
          <div className='row m-0 position-relative'>
            <div className='white-decoration'>
              <img src='images/white-svg.svg' alt='asv' />
            </div>
            <div className='col-lg-5 pl-0 pr-0'>
              <img src='images/dansteele/dansteele-item.jpg' alt='' class='img-fluid' />
            </div>
            <div className='main-description-banner col-lg-5'>
              <p className='row m-0'>{text.dansteele}</p>

              <div className='info-block pre'>
                <p dangerouslySetInnerHTML={{ __html: text.dansteele_text }}></p>
              </div>
            </div>
            <a href='/tarasyan2' className='col-lg-1 p-0 next-page desktop-next'>
              <p> {text.next}</p>
              <img src='images/arrow.svg' alt='' />
            </a>
          </div>
        </div>

        <div className='modal fade' id='GalleryModal' tabIndex='-1' role='dialog' aria-hidden='true'>
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>×</span>
                </button>
              </div>
              <div className='modal-body'>
                <div id='carouselGallery' className='carousel slide' data-ride='carousel'>
                  <div className='carousel-inner'>
                    <div className='carousel-item active'>
                      <img className='d-block w-100' src='images/kickpack/kickpack-item1.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/kickpack/kickpack-item2.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/kickpack/kickpack-item3.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/kickpack/kickpack-item4.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/kickpack/kickpack-item5.jpeg' alt='' />
                    </div>
                    <div className='carousel-item '>
                      <img className='d-block w-100' src='images/kickpack/kickpack-item1.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/kickpack/kickpack-item2.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/kickpack/kickpack-item3.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/kickpack/kickpack-item4.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/kickpack/kickpack-item5.jpeg' alt='' />
                    </div>
                  </div>
                  <a className='carousel-control-prev' href='#carouselGallery' role='button' data-slide='prev'>
                    <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                    <span className='sr-only'>Previous</span>
                  </a>
                  <a className='carousel-control-next' href='#carouselGallery' role='button' data-slide='next'>
                    <span className='carousel-control-next-icon' aria-hidden='true'></span>
                    <span className='sr-only'>Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a href='/edmonhabetyan' className='col-lg-1 p-0 next-page mobile-next'>
        <p> {text.next}</p>
        <img src='images/arrow.svg' alt='' />
      </a>
    </section>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dansteele));
