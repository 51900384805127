import Slider from 'react-slick';
import { withRouter } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import './bem.css';

const data = require('./lang/lang.json');

function Tarasyan() {
  const slickerSettings = {
    customPaging: (i) => {
      if (i > 4) {
        return <div></div>;
      } else {
        return <button>i</button>;
      }
    },
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 500,
    centerPadding: '0',
    centerMode: true,
    centerMargin: '0',
    variableWidth: false,
    className: 'bem',
    adaptiveHeight: false,
    autoplay: true,
    dots: true,
    arrow: false,
    autoplaySpeed: 2500,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '0',
          centerMode: true,
          centerMargin: '0',
          slidesToShow: 3,
          slidesToScroll: 0,
          adaptiveHeight: false,
          dots: true,
          arrow: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: '0',
          centerMode: true,
          centerMargin: '0',
          slidesToShow: 1,
          slidesToScroll: 0,
          adaptiveHeight: false,
          dots: true,
          arrow: false,
        },
      },
    ],
  };
  const text = data[localStorage.getItem('language')];
  return (
    <section className='main-container current-container slider-centered'>
      <div className='breadcrumb current-inner-container'>
        <a href='/platform'>{text.platform}/</a>
        <a href='/tarasyan' className='breadcrumb-active'>
          {text.urlich_tarasyan}
        </a>
      </div>
      <div className='section-details modal-slider'>
        <div class='current-inner-container platform-inner'>
          <div className='row m-0 position-relative'>
            <div class='white-decoration'>
              <img src='images/white-svg.svg' alt='asv' />
            </div>
            <div className='col-lg-5 pl-0 pr-0'>
              <img src='images/tarasyan/tarasyan-item.jpeg' alt='' />
            </div>
            <div className='main-description-banner col-lg-5'>
              <p className='row m-0'>{text.urlich_tarasyan}</p>
              <div className='info-block'>
                <p dangerouslySetInnerHTML={{ __html: text.urlich_tarasyan_text }}></p>
              </div>
            </div>
            <a href='/dansteele' className='col-lg-1 p-0 next-page desktop-next'>
              <p> {text.next}</p>
              <img src='images/arrow.svg' alt='' />
            </a>
          </div>
        </div>
        <Slider {...slickerSettings}>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tarasyan/tarasyan-sml-item1.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='0'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tarasyan/tarasyan-sml-item2.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='1'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tarasyan/tarasyan-sml-item3.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='2'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tarasyan/tarasyan-sml-item4.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='3'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tarasyan/tarasyan-sml-item5.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='4'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tarasyan/tarasyan-sml-item1.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='5'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tarasyan/tarasyan-sml-item2.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='6'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tarasyan/tarasyan-sml-item3.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='7'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tarasyan/tarasyan-sml-item4.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='8'
            />
          </div>
          <div data-toggle='modal' data-target='#GalleryModal'>
            <img
              src='images/tarasyan/tarasyan-sml-item5.jpeg'
              alt=''
              data-target='#carouselGallery'
              data-slide-to='9'
            />
          </div>
        </Slider>
        <div className='modal fade' id='GalleryModal' tabIndex='-1' role='dialog' aria-hidden='true'>
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>×</span>
                </button>
              </div>
              <div className='modal-body'>
                <div id='carouselGallery' className='carousel slide' data-ride='carousel'>
                  <div className='carousel-inner'>
                    <div className='carousel-item active'>
                      <img className='d-block w-100' src='images/tarasyan/tarasyan-item1.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/tarasyan/tarasyan-item2.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/tarasyan/tarasyan-item3.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/tarasyan/tarasyan-item4.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/tarasyan/tarasyan-item5.jpeg' alt='' />
                    </div>
                    <div className='carousel-item '>
                      <img className='d-block w-100' src='images/tarasyan/tarasyan-item1.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/tarasyan/tarasyan-item2.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/tarasyan/tarasyan-item3.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/tarasyan/tarasyan-item4.jpeg' alt='' />
                    </div>
                    <div className='carousel-item'>
                      <img className='d-block w-100' src='images/tarasyan/tarasyan-item5.jpeg' alt='' />
                    </div>
                  </div>
                  <a className='carousel-control-prev' href='#carouselGallery' role='button' data-slide='prev'>
                    <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                    <span className='sr-only'>Previous</span>
                  </a>
                  <a className='carousel-control-next' href='#carouselGallery' role='button' data-slide='next'>
                    <span className='carousel-control-next-icon' aria-hidden='true'></span>
                    <span className='sr-only'>Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a href='/dansteele' className='col-lg-1 p-0 next-page mobile-next'>
        <p> {text.next}</p>
        <img src='images/arrow.svg' alt='' />
      </a>
    </section>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tarasyan));
