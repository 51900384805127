import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function Freedom() {
  return (
    <div className='main-container'>
      <div className='breadcrumb container'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/opinion4' className='breadcrumb-active'>
          Օրփեոսի պատմությունը էվրիդիկեի աչքերով` նանոր պետրոսյանի ներկայացումում
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong> Օրփեոսի պատմությունը էվրիդիկեի աչքերով` նանոր պետրոսյանի ներկայացումում</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item4.jpeg' alt='icon' className='img-fluid' />
        <p className='mt-3'>
          Ու առաջին միտումն է ներկայացումից հետո՝ պիեսը գտնել ու այդ պոետիկ նամակները բոլ-բոլ կարդալ, իսկ հետո մտածում
          ես՝ իսկ ինչի համար: Նանոր Պետրոսյանի թատերախումբը իինքնուրույն ընտրել եւ թարգմանել է արդի (1974 թ.ծնված)
          հեղիակի պիեսը, որը դերեւս թարգմանված չէ ոչ հայերեն, ոչ էլ ռուսերեն: Թարգմանել է՝ որովհետեւ իրեն պետք էր,
          հետաքրքիր ու կարեւոր, որովհետեւ համապատասխանում էր թատերախմբի տարիներ աջառ որդեգրած, նախորդ բազմաթիվ
          բեմադրություններում արտացոլված հիմնական գաղափարական ուղղվածությանը: Ինչպես արդեն վերնագրիծ է երեեւում, պիեսի
          առանձնահատկությունը նրանում է որ Օրփեոսի պատմությունը դիտարկվում է Էվրիդիկեի աչքով, եւ այդ դիտանկյունից
          Էվրիդիկեի պատմությունը պետք է նորից հասկացվի հանդիսատեսի կողմից, մինչդեռ Օրփեոսի պատմությունը «բնազդաբար»
          հասկանալի էր: Ներկայացման այդ «բնազդային» պատկերացումներից շեղվող լուծումները սկզբից նույնիսկ թվում են
          «տեխնիկական թերություն», սակայն հետդարձ հայացքով դիտելիս համոզվում ես՝ ամեն բան գիտակցաբար էր արված, բայց
          հատուկ տրամաբանության շրջանակում: Առաջին տեսարան՝ Օրփեոսը եւ Էվրիդիկեն սիրում են իրար, լողափում են, կիսամերկ,
          խոսում են իրենց սիրուց ու պատրաստվող ամուսնությունից: Տեսարանը փոքր-ինչ լավ չի երեեւում, բեմի խորքում է, խոսքը
          փոքր-ինչ լավ չի լսվում, թվում է թե դահլիճում աշխատող օդափոխիչի պատճառով, դերասանական խաղը փոքր-ինչ արհեստական,
          կաշկանդված, ոչ հավաստի է թվում: Բայց այն մեր նշած «այլ» իրականության, դիտանկայն տեսակետից ամեն ինչ ընդհակառակը
          ճիշտ է պատկերված, որովհետեւ դա սուտ, արհեստական, վատ իմաստով «խաղացվող» երջանկություն է: Հաջորդ տեսարանում
          Էվրիդիկեն՝ դուրս գալով նախահարսանեական պատրաստումով զբաղված վայրից, ջրին է պատմում /«Ջրին պատմեմ» էր կոչվում
          Նանոր Պետրոսյանի նախորդ ներկայացումը/, որ հարսանիքը նման է թաղման... եւ այս պահին հայտնվում է անդրշիրիմյան
          երկրի տիրակալը, շատ տպավորիչ լուծված մի կերպար՝ նման է միաժամանակ դիակի, սրիկայի, աղքատի, հիվանդի,
          շագանակագույն կաշվե դիմակով, նա խոսում է ձայնագրված անմարդկային ձայնով, եւ Էվրիդիկեին առաջարկում է գնալ իր
          տուն՝ այստեղ, մոտիկ է, որ ստանա իր հորից եկած նամակը, ու Էվրիդիկեն... գնում է: Չհավատալով, որ նամակը կա,
          հասկանալով որ բարի նպատակներով չեն կանչում, բայց գնում է՝ որովհետեւ ուզում այստեղից, ամբողջ այս տեղից, գնալ:
          Ու այնտեղ, իր տանը, Աիդոսը սիրահետելով հարձակվում է Էվրիդիկեի վրա, եւ փախչելով, նա ընկնում է աստիճանից ու
          մահանում: Եվ ընկնում անդրշիրիմ: Անդրշիրիմ ընկնելը ուղեկցվում է ջրային ձայներով, ըստ պիեսի դրանք Շիրմաքարերի
          ձայներ են որոնք կատարում են Երգչախմբի դերը, աղաղակելով նրանք խառնում են լեզուն, լսում ու չես հասկանում սա
          հայերեն է թե արդեն ոչ, սա լեզու է, բայց բոլոր ձայնակցությունները արդեն խառնվել են ու իմաստափոխվել, երկրային
          հիշողությունը, իմաստները կորում են անդրշիրիմ ընկնողի մոտ, նա ապրում է լեզվի քայքայման ընթացքը, եւ այս պահը
          ներկայացման մեջ այդքան իրական է, սարսափելի ու հրճվանք պատճառող, հայտնագործություն պարունակող՝ ահա թե ինչպես են
          իրականում մեռնում, ահա թե ինչ է այդ ժամանակ տեղի ունենում՝ որ այդ պահից ներկայացմանը արդեն վստահած ես, ու
          հավատարիմ: Շատ հազվադեպ պատահող թատերական պահ: Անդրշիրիմում Էվրիդիկեն հանդիպում է իր հորը՝ չնաշխարհիկ
          դերասանական կատարում է Հայրը, մի կերպար, որ սփռում է հայրականության, հայրական սիրո ու առաքինության մթնոլորտ,
          որի մեջ ուզում ես ձուլվես: Բայց Էվրիդիկեն չի ուզում ենթարկվել այդ մթնոլորտին, համառորեն չի ուզում հասկանալ ուր
          է ընկել եւ ով է իր առջեւ, քմահաճորեն ձեւացնում է որ գնացքից է իջել, պահանջում է իրեն հյուրանոց տանեն, մրսել է,
          հոգնած է, հորը սպսավորի տեղ է դնում ու պահանջում տաք լոգարան կազմակերպել: Եվս մի շատ հավաստի տեսարան՝ երբ
          կնոջը նեղն՝ անելանելիի մեջ են գցում հանգամանքները, մարդիկ /տղամարդի՞կ/, նա կոտրում է տրամաբանությունը, չի
          ուզում բանականությանը ենթարկվել, քմահաչ է ձեւանում, անհասկացող, Էվրիդիկեն, թե Անտիգոնան, առաքինի պարոնների
          «արդարացված», ամփոփ կաուցված միջավայրը չի ընդունում, մանկամտորեն՝ չի ընդունում ու վերջ՝ կարոտած հայր, կամ
          անդրշիրիմի տիրակալ՝ ճամպրուկս վերցրեք, ինձ լողարան կազմակերպեք, տաք լողարան, մրսում եմ: Բայց հետո միեւնույնն է
          ծավալվում է Օրփեոսի դասական պատմությունը՝ հայրը իրոք աղջկան նամակ էր գրել ու անդրշիրիմյան տիրակալի հետ
          փոխանցել, Էվրիդիկեին «կրթում» է, հիշեցնում բանական լեզուն ու խոսքը, իր ով-ինչ լինելը, Օրփեոսի հետ էլ են
          նամակագրություն հաստատում, եւ վերջինս գրում է գեղեցիկ, պոետիկ նամակներ որոնք պատկերվում են սպիտակ ձեռագրով
          էկրանի վրա: Արդեն նշեցի որ այլությունը ներկայացման մեջ երբեմն տեխնիկական թերության կերպարանք է ստանում, եւ
          Օրփեոսի շատ պոետիկ նամակները էկրանին՝ վատ են երեւում ու շատ արագ անցնում, կիսատ ես հասցնում կարդալ, առավել եւս
          որ երբեմն դերասանները կանգնում են էկրանի ու ընթերցողի արանքում: Ու առաջին միտումն է ներկայացումից հետո՝ պիեսը
          գտնել ու այդ պոետիկ նամակները բոլ-բոլ կարդալ, իսկ հետո մտածում ես՝ իսկ ինչի համար: Օրփեոսը մեծ պոետ է, ո՞վ
          չգիտի, շատ առաքինի ու տաղանդաշատ պարոն, որը շոգեքարշի անխուսափելությամբ նախապատրաստում է իր մեծահոգի այցը
          անդրշիրիմ, եւ իհարկե իր մոտ ստացվում է գայթակղել անդրշիրիմի տիրակալ պարոնին իր անզուգական պոետիկ երգով, դե
          երկու պարոն իրար միշտ կհասկանան, ու նաեւ երրորդ պարոնը՝ հայրը ամենեւին էլ դեմ չի, միայն թե աղջիկը երջանիկ
          լինի, այսինք՝ լավ ամուսնու ձեռքն ընկնի: Էլ ինչու՞ էր նամակներ գրում: Անդրշիրիմյան պարոնը նույնպես սիրահետում է
          Էվրիդիկեին, երեխայի /տղա երեխայի/ կերպարանք ընդունելով, բայց, իհարկե, հետո ջենթլմենաբար ընդունում է իր
          պարտությունը Օրփեոսից, եւ հուշում իրեն, թե ինչպես դուրս հանի հարսնացուին դեպի լույս: Էվրիդիկեն իրոք սիրում է
          Օրփեոսին, բուռն գրկախառնվում է ճանապարհին, դրանով իսկ ստիպելով իրեն շրջվել ու խախտել Աիդոսի «մենակ մի շրջվիր»
          պայմանը, սիրում է իրարկե եւ հորը, եւ անգամ մեծամիտ չի «մահացած սրիկայի» կերպարանք ունեցող Աիդոսի նկատմամբ,
          բայց այդ ազնվական, բազմատաղանդ՝ կամ դե-յուրե, ինչպես Օրփեոսն է՝ ըստ դերի, այնպես էլ դե-ֆակտո՝ ինչպիսինն են
          Հայրը իր փայլուն դերասանական խաղով, կամ Աիդոսը իր շատ հետաքրքիր բեմական կերպարանքով՝ այդ ազնվական ու
          բազմատաղանդ պարոնների եռանկյունում /որը կարող է քառանկյուն դառնալ երեխայի դեպքում/ նա չունի իր իրենը, տեղը,
          դերը, իմաստը, աշխարհը, արժանիքը, տրամաբանությունը, ռազմավարությունը... Էվրիդիկեն չունի քաղաքացիական հավասար
          իրավունք: Եվ նա որոշում է գնալ, մեռնել երկրորդ ու վերջնական մահով, նորից անցնել մոռացության գետի ջրերով:
          Կարեւոր է, որ այս ամենը ներկայացումում չի ասվում, ու նույնիսկ չի խաղացվում ուղղակի ձեւով: Ասվում ու խաղացվում
          է հիմնականում հակառակը՝ դասական Օրփեոսյան պատմությունը, սակայն, ինչպես ասացի, շեղումներով, թերացումներով:
          Հիմնական շեղում ու թերացումը Էվրիդիկեն է ամբողջությամբ, բոլորին ավելի հարմար կլիներ նրա փոխարեն մի տիկնիկ
          լիներ: Իր խոսքը, գործողությունը, կերպարանքը չի բռնում այն սյուժեին, որն իր համար միշտ կառուցում են ուրիշները:
          Իսկ սեփականը չի կառուցում, չունի՝ Անտիգոնե չի, եւ չունի քաղաքացիական իրավունք, եւ չի նվաճում այն: Քմահաճում է,
          նեղվում, Օրփեոսին իզուր աշխատանք պարտադրում, պոետիկ, պաթետիկ տեսարաններին չի ներդաշնակում իր
          ճամպրուկ-լոգարանով, ի վերջո՝ պարզապես մեռնում, գնում, սկզբից մի աշխարհից, հետո եւ մյուսից: Իսկ բեմադրիչը,
          ամբողջ բեմադրությունը աննկատ իրեն նվագակցում են իրենց «տեխնիկական թերություններով»: Էվրիդիկեի կերպարը
          մարմնավորող դերասանուհին լրիվ համահունչ է կերպարի այսօրինակ ընկալման: Շատ հետաքրքիր ներկայացում է՝ կրկնակի
          խաղով, անուղղակի ասելիքով, նոմինալ ավանդական իմաստների վերախաղարկումով: Հ.Գ. Ասվածը միայն իմ հանդիսատեսական
          ընկալումն է, ամնեւին չեմ պնդում որ բեմադրիչը, թատրոնը հենց այսպես էին ընկալում բոլոր տեսարանները, թեեւ,
          կարծում եմ, ներկայացման ընդհանուր իմաստի վերաբերյալ մեծ տարաձայնություններ չպետք է որ ունենանք:
        </p>
        <p>Արա Նեդոլյան</p>
        <p>arteria.am</p>
        <p>24.07.2019</p>
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freedom));
