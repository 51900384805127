import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function Freedom() {
  return (
    <div className='main-container'>
      <div className='breadcrumb'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/freedom' className='breadcrumb-active'>
          Անկախության վերապատմումը․ անկարողություն եւ բացթողումներ
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong> Անկախության վերապատմումը․ անկարողություն եւ բացթողումներ</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item3.jpeg' alt='icon' className='img-fluid' />
        <p>
          <em>
            Նանոր Պետրոսյանը Թեհրանում է ծնվել, բայց մեծացել Երեւանում։ Կրթությունն էլ է այստեղ ստացել՝ Երեւանի թատրոնի
            եւ կինոյի պետական ինստիտուտի Մնջախաղի բաժնում։ Նանորը տարբեր մշակութային միջավայրերում ավելի շատ լռում է։
            Իրեն շատ եմ ուսումնասիրել կողքից․ կլանող է։ Կարեւոր է նաեւ, որ Հայաստանի անկախացման եւ Արցախյան շարժման
            տարիներին դեռ տասնամյակը նոր բոլորած Նանորի ընտանիքում քաղաքական ու քաղաքացիական զարգացումները միշտ
            քննարկման թեմա են եղել։ Գալով մեր օրերին․ Նանորը 2015թ․-ի վերջում ներկայացրեց «Վերապատմում»
            դոկումենտալ-իմպրովիզացիոն ներկայացումը։ Մասնակիցները հիմնականում 90-ականների ծնունդ են․ վերապատմում են
            Հայաստանի երրորդ հանրապետության սկզբնավորումից մինչեւ այն պահը, երբ նրանցից մեկը՝ լրագրող Անի Հովհաննիսյանը,
            սկսում է պատմել 2008-ի շարժման մասին արդեն որպես մասնակից-ականատես․ այստեղ վերապատումի տարրը սղվում է, եւ
            ցավոք, ներկայացումն ավարտվում։
          </em>
        </p>
        <p className='mt-3'>
          Բայց մինչ այդ․ մասնակից քաղաքացիները որոշ փաստեր (ոչ ամբողջական, նաեւ էմոցիոնալ) են ներկայացնում, էկրանին
          հայտնվում է 88-ի շարժումից կադրեր, Վանո Սիրադեղյանը, երեք նախագահները, 96-ի ընտրությունների օրվա ավարտը եւ
          այդպես շարունակ․․․ Նրանցից միայն մեկն է պրոֆեսիոնալ դերասան՝ Անի Գալստյանը։ Այս ամենին զուգահեռ հանդիսատեսի
          արանքներում մինչանկախության սերունդը ներկայացնող դերասաններն են նստած ՝ Մարինեն Պետրոսյան, Արթուրը Մանուկյան,
          Գայանե Բալյանը (ով գլամուրային քարտուղարուհու դեր է մարմնավորում) եւ պատմության ուսուցիչը՝ Համլետ Չոբանյանը,
          մատը դրած գրքի էջերի արանքում։ Վերջիններս պարբերաբար աղաղակում են իրենց տեսածի մասին․․․ Իրենք պատմողներն են,
          բայց իրենք էլ պատմել չեն կարողանում․ էմոցիոնալ, դասագրքային, մեկ այլ դեպքում՝ ժողովրդական տարածված
          պոռթուկումներով վերջիններս հասկացնել են տալիս, որ անկախության սերնդին (այս դեպքում վերապատմողներին) գրեթե բան
          չեն տվել․ տվածն էլ «մութ ու ցուրտ» տարիների էմոցիան է, «լեւի» լույսն ու անլոգանք օրերը․․․Այս ամենին զուգահեռ
          հայտվում է երիտասարդը (դերում՝ Հայկ Զորիկյանը), ով առաջարկում է «հիմար» պատմությունների փոխարեն իր հետ դուրս
          գալ փողոց եւ հեղափոխություն անել․․․Հաղորդավարը (դերում՝ Մարիա Սեյրանյան) փորձում է համակարգողի դերում լինել եւ
          ի վերջո տեղի զայրույթը տեղի է տալիս եւ դուրս է մղվում հեղափոխություն անող երիտասարդի հետ (կարծում եմ՝ նրա
          զայրույթի պոռթուկմն անհասկանալի է հանդիսատեսին)։ Ներկայացման գլխավոր հարցադրումը պարզ է․ համենայնդեպս այդպես է
          թվում՝ ինչպե՞ս պատահեց կամ նույնիսկ ինչպե՞ս տեղի չունեցավ այն, որ անկախության սերունդը չի կարողանում
          վերապատմել երիտասարդ պետության պատմությունը։ Նանորի հետ խոսեցի մի շարք հարցերի ու հարցադրումների շուրջ․
          բավականին ծավալուն զրույցը պետք է որոշակի մասնատեմ, բայց մինչ այդ մեջբերեմ Նանորի բերած օրինակը, թե ինչքան է
          կարեւոր ինֆորմացիայի փոխանցումը ներկայում արդյունավետություն ունենալու համար․ «Միշել Գոնդրին մուլտիպլիկացիոն
          մի ֆիլմ է նկարել Չոմսկու մասին։ Ամբողջ ֆիլմը հարցազրույց է իր հետ, զուգահեռ` մանկական նկարչություն։ Էս
          պատմությունն առաջին հայացքից կապ չունի մեր նյութի հետ, բայց․․․ Մի տեղ Չոմսկին պատմում է, որ երբ սարքեցին
          գյուղատնտեսական մեքենա` արտը ցանելու համար, այդքան արդյունք չտվեց, ինչքան որ մարդկային ռեսուրսը կարող էր տալ։
          Ու չէին հասկանում` ինչու։ Չոմսկին նշում է, որ տղամարդիկ էին սարքել էդ գյուղատնտեսական սարքերը, բայց հետո
          պարզվել էր, որ հիմնականում կանայք են զբաղվել ցանքով, ու գաղտնիքը կանայք են իմացել եւ փոխանցել սերնդեսերունդ։
          Սա պատմեցի, որ հասկանալի լինի, թե ինչու եմ կարեւորում մեկը մյուսին ինֆորմացիան փոխանցելու, պատմելու
          հանգամանքը»․ ասում է Նանորը։
        </p>
        <p>
          <strong>
            Գիտեմ, որ ներկայացումից առաջ զրուցել ես տարբեր մարդկանց  հետ, ովքեր «վերապատմելու» հնարավորություն են
            ունեցել անկախության սկզբնավորման օրերից: Հետո ընտրություն ես կատարել նրանցից․․․Ներկայացումը նայեցի, ու
            մտածեցի` եթե այդքան կցկտուր են իրենք խոսել, ինչպե՞ս ես, այնուամենայնիվ, վերապատմելու հնարավորություն տվել
            հենց իրենց․արդյո՞ք դա էր վերապատմելու նյութը։
          </strong>
        </p>
        <p>
          «Կցկտուրություն» կար բոլորի խոսքի մեջ, ում հետ խոսել եմ։ Պատճառներից մեկը այն է, որ քիչ գիտեն, երկրորդը, որը
          պակաս կարեւոր չի, որ իրենց չէի ասել, թե ինչու ենք հանդիպում։ Իրենք, երբ համաձայնվեցին հարցազրույցի, հենց էդ
          ժամանակ էլ միացրել եմ դիկտաֆոնը․ ժամանակ չեն ունեցել նախապատրաստվելու։ Շատ հարցերի դեպքում ուղղակի ասել են՝
          «չգիտեմ»։ Հետո այդ հարցազրույցներն ուղարկվել է իրենց, որպեսզի, եթե անհրաժեշտ են գտնում, վերանայեն։ Շատ
          զարմացած էի, կարծում էի՝ բոլորս գիտենք` ինչ է «մութ ու ցուրտը», պարզվեց, որ ոչ․․․ Ու էդ մեծ բաց է, շատ մեծ
          բաց։ Ինձ թվում է` սերնդեսերունդ ինֆորմացիա փոխանցելու մշակույթ չկա․․․ Ու դրա պատճառը դառնությունը չի եղել, որ
          ապրել են մեր ծնողները․․․
        </p>
        <p>
          <strong>
            Վարողը կամ հակարգողը, ով հարցադրումներ է բարձրացնում կոնկրետ թվականների եւ դեպքերի մասին, այսպես ասած,
            արհեստական կերպար է, հարցադրումներն էլ են արհեստական երանգով։ Ինչու՞ հենց այդ կերպով։
          </strong>
        </p>
        <p>
          Ինձ թվում է՝ տարածված կերպար է մեր մեդիայի ոլորտում։ Հենց սկզբից ասեմ՝ միայն Նազենիի կապկումը չի էղել, շատերը
          կան այդպիսին․․․ Հենց երեկ մի տեղ նկարներ տեսա, որտեղ նույն հագուկապով հաղորդավար աղջիկ էր։ Ու, այո, իրենք
          արհեստական են, որովհետեւ ստեղծված կերպարներ են․․․ Ու չունեն կոնկրետ անուն–ազգանուն, ի տարբերություն
          երիտասարդների, ովքեր վերապատմում են։
        </p>
        <p>
          <strong>Եթե դու լինեիր վերապատմողների սեղանի շուրջ, ավելին կարո՞ղ էիր վերապատմել կամ պատմել։</strong>
        </p>
        <p>
          Հա, անշու՛շտ, ես միջինում իրենցից տասը տարի մեծ եմ, եւ գումարած` բավականին քաղաքականացված ընտանիքում եմ
          մեծացել։ Ու շատ դեպքերի ընթացքին, թեեւ տարիքիս, ֆիզիկապես ներկա եմ եղել։ Մեկ էլ ինձ թվում է` բացի քաղաքական
          իրադրաձություններից, կպատմեի նաեւ մշակութային անցումների մասին. կպատմեի մանրամասներ նիստուկացի, մարդկային
          շփումների, հագուկապի, ու էդ ամեն ինչի էվոլուցիայի մասին` ներառելով պատմական անցուդարձերը․․․ Կպատմեի մի ժամանակ
          առաջին դիրքերում, հետո` փողերը գողացած, մի տեղ կուչ եկած մարդկանց մասին կամ իրադարձությունների, որոնք մոռացվել
          են։
        </p>
        <p>
          <strong>
            Հանդիսատեսի արանքներում նստած դերասանները, ովքեր ականատեսն ու ապրողն են եղել այդ թվականների, որեւէ
            նյութական, փաստացի բան չեն ավելացնում, նրանց բացականչությունները հիմնականում էմոցիոնալ ֆոնի վրա են։ Արդյո՞ք
            իրադարձությունների մասնակից կամ ժամանակակից մարդը նույնպես դժվարանու՞մ է պատմել։
          </strong>
        </p>
        <p>
          Եթե ուղղակի իրենց հարցնես, իրենք էլ ձեւակերպված խոսք չեն ասում, երեւի միայն արտիստները, որոնք վերանայել են
          իրենց անցյալը, խոսել են իրենք իրենց հետ, կարող են պատմել․․․ Կամ իսկապես պատմությունով հետաքրքրվող մարդիկ են,
          որ խոսում են ավելի սիստեմատիկ։ Համենայնդեպս, շատերը հենց էդպես էմոցիոնալ են պատմում՝ առանց կոնկրետ դեպքին
          անդրադառնալու։ Բայց ասեմ, որ էդ ամենը դիտավորյալ էր արված, որ իրենք կոնկրետ բան չասեն (ի դեպ` առաջինն ես
          նկատել), որպեսզի իրենց դիրքորոշմամբ ներկա լինեն, որն էնքան, ըստ իս, տեսանելի է երիտասադ վերապատմողների
          խոսքում, մանավանդ կցկտուրության մեջ, ոնց որ դու նկարագրեցիր՝ լսվում է դիրքորոշում, էմոցիոնալ կապ տվյալ գործչի
          կամ դեպքի մասին, այնինչ փաստացի ոչ մի բան չի կարող վերապատմվել։
        </p>
        <p>
          <strong>
            Երիտասարդները կամ վերապատմողները արդեն իսկ անկախացած Հայաստանի սերունդն են։ Նրանք անընդհատ թերահավատությամբ
            կամ արհամարհանքով են մոտենում իրենց սերնդակցին, ով պարբերաբար ներս է մտնում ու առաջարկում, պնդում, որ դուրս
            գան ու հեղափոխություն անեն։ Կարծու՞մ ես անկախության սերունդն այդ քաղաքացիական կամքը չունի փոփոխություններ
            կատարելու՞։ Ինչու՞ են նրանք ստվերում թողնում «ակտիվ» քաղաքացուն։
          </strong>
        </p>
        <p>
          Ստվերում չեն թողնում։ Ընդհակառակը, երբ հաղորդավարն իր խոսքում ասում  է` «քաղաքացինե՛ր», վերապատմողները խոսքը
          իրենից «վերցնում» են եւ սկսում հախուռն ինչ- որ անուններ տալ․ նրանք չեն ենթարկվում հաղորդավարի լռեցնելու
          պահանջին։ Իրենք դերասան չեն, ու ինչպես պապիկի խոսքերն են ծաղրում, որպես «ստեղծված», խաղարկային խոսք, էնպես էլ`
          բողոքողինը։ Ի նկատի ունեմ` իրենց վերաբերմունքը էնպես է, ինչպես հանդիսատեսինը կլինի, եթե զրույցի բռնվի էդ
          մարդու հետ։ Չեմ կարծում, որ հատուկ է քամահրական վերաբերմունքը բողոքողին։ Ես որ չէի ուզի լիներ այդպես, ու
          հաստատ կվերանայվի այս քո հարցից հետո։ Ինձ համար, որպես անկախությանը ծնված զուգահեռ մեկին, ամենից հետաքրքիր
          պահն սկսվեց այն պատումից, երբ Անի Հովհաննիսյանն սկսեց որպես մասնակից պատմել 2008թ․-ի շարժման մանրամասներից ,
          էմոցիաներից եւ մտորումերից։ Այդ պատումին զուգահեռ ներկայացումն ավարտեց։ Ի՞նչ ես կարծում՝ 2008թ․-ին տեղի
          ունեցածը 8 կամ 18 տարի անց նույնպես «վերապատումի» չի վերածվում կամ վերածվելու։ Այո՛, անխոս, վերավծելու է։ Անին
          էդտեղ արդեն գործող անձ է, ոչ վերապատմող, այսինքն մի տեղից լսած, կամ սովորած, կամ հետաքրքրված, կամ հուշերում
          զետեղված ինֆորմացիա չունի, այլ պատմում է որպես մասնակից։ Ինքն էդտեղ լիիրավ ներգրավված է եղել ու նույնիսկ
          եզրակացություն է անում ու մեզ հետ կիսվում  շատ ինտիմ ներքին փոփոխությունով։
        </p>
        <p>
          <strong>
            Ողջ ներկայացման ընթացքում, կարծես մեղադրանքի կարմիր թել էր անցնում․ մարդկանց, ովքեր չեն պատմել տեսածը,
            մեդիան, որը մասնատել, այլ լույսի տակ է ներկայացրել ինֆորմացիան, դասագրքերը, որոնք լղոզել են
            իրականությունը․․․ Ո՞րն է հիմնական խնդիրը կամ բացթողումն, ըստ քեզ, որի պատճառով մենք վերապատմել գրեթե չենք
            կարողանում․․․ Երբ ընդամենը խոսքը գնում է մեկ քառորդ դարի պատմության մասին։
          </strong>
        </p>
        <p>
          Չեմ ուզում մեծ-մեծ խոսել, բայց ինձ թվում է, որ մենք ուղղակի չենք խոսում մեր երեխաների հետ։ Մանավանդ փոքր
          երկրներում, իմ կարծիքով, քաղաքացին պարտավոր է իմանալ իր, հարեւան երկրի, ամբողջ տարածաշրջանի, մեծ
          գերտերությունների պատմությունը, մշակույթը, արվեստը։ Հիմա մենք էս ենք, փոքր ենք, պիտի իմանանք։ Մեծ
          պետությունների իմ տարեկիցները կարող են եւ չիմանալ, բայց ինձ համար դա ինքնուրույնությունս մի քիչ պահելու միակ
          ձեւն է... Եվ վերջում, չնայած, որ հարցադրումներն իրապես շատ են․ ինչու՞ այդ սեղանի շուրջ չկար մեկը, ով կարող էր
          վերապատմել պատմությունը հնարավորինս մոտ իրականությանն ու փաստերին։ Բացառու՞մ ես, որ անկախության սերունդից
          այդպիսի մեկը կա։
        </p>
        <p>
          Անուշ ջան, եկանք բուն թեմային։ Նույնիսկ, եթե վերապատմող երիտասարդները իմանային պատմությունը ծայրից ծայր,
          իրադարձությունների ընթացքն ու ծակուծուկերը, մի պատմություն չէր կարող լիներ, որ քեզ գոհացներ, որովհետեւ
          պատմությունը ինստիտուցիաներին է պատկանում եւ իրականության հետ շատ քիչ աղերս ունի։ Չեմ կարծում, որ չպատմեցին,
          միգուցե ոչ այն, ինչ մենք կուզեինք լսել կամ պատկերացնել․․․ Իշխանյան Վահանն ասում է` բերեիր այնպիսի մարդու, ով
          ինստիտուցիայի անունից չի խոսում, ով ինքն իր կարծիքն է ասում, ըստ իրեն` վերապատմողները միայն ինստիտուցիաների
          անունից էին խոսում էլի... Այն ժամանակ չպատասխանեցի, բայց հիմա ասեմ` միգուցե պատմությունը հենց իրենց է
          պատկանում։ Ազատյանը իր գրքում խոսում է գերմանացիների գծագրված,  կանախամտածված ճակատագրի մասին մեր տարածաշրջանի
          համար, որը եւ դարձել է մեր պատմությունը։ Վահանի մեկնաբանությունից հետո մտածում եմ` գուցե, իրոք, Սայիդն ինձ վրա
          էդքան մեծ ազդեցություն է ունեցել, ու իսկական «բունտս» եղել է արեւմտականության դեմ պայքարը... Եսիմ... Քեզ հետ
          խոսալուց եմ մտածում․․․էս ու՜ր հասա... Հ․Գ․Գլխավոր լուսանկարում ներկայացման ազդագիրն է․ ձեւավորող` Նարե Բալյան,
          նկարը` Հովհաննես Արմենակյանի։ ներկայացումը իրականցվել է «Մշակույթի եւ կառավարման լաբորատորիայի» «Հարավային
          կովկասի տարածաշրջանային արվեստ և մշակույթ» ծրագրի շրջանակներում՝ հարավային կովկասում շվեյցարական
          համագործակցության գրասենյակի ֆինանսական աջակցությամբ (SCO)։
        </p>
        <p>Անուշ Քոչարյան</p>
        <p>Hetq.am</p>
        <p>13.05.2016</p>
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freedom));
