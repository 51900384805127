import Slider from 'react-slick';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import './weplay.css';
import './bem.css';
const data = require('./lang/lang.json');
function NextArrow(props) {
  const { onClick } = props;
  return (
    <button type='button' className='slick-next pull-right slick-arrow d-block' onClick={onClick}>
      <img src='images/next-arrow.svg' className='img-fluid' alt='arrow' title='arrow' />
    </button>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <button type='button' className='slick-prev pull-left slick-arrow d-block' onClick={onClick}>
      <img src='images/prev-arrow.svg' className='img-fluid' alt='arrow' title='arrow' />
    </button>
  );
}
function platform() {
  const text = data[localStorage.getItem('language')];
  const slickerSettings = {
    infinite: true,
    centerPadding: '10px',
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    centerMode: true,
    variableWidth: false,
    centerMargin: '0',
    className: 'bem',
    autoplay: true,
    arrow: false,
    dots: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '0',
          slidesToShow: 1,
          slidesToScroll: 0,
          adaptiveHeight: false,
          dots: true,
          prevArrow: <PrevArrow />,
          nextArrow: <NextArrow />,
        },
      },
    ],
  };

  const state = [
    {
      title: text.edmon,
      year: 2019,
      image: 'images/platform/platform-item5.jpeg',
      ref: '/edmonHabetyan',
    },
    {
      title: text.urlich,
      year: 2019,
      image: 'images/platform/platform-item4.jpeg',
      ref: '/ulrichMeyerHorsch',
    },
    {
      title: text.kickpack_title,
      year: 2019,
      image: 'images/platform/platform-item2.jpeg',
      ref: '/kickpack',
    },
    {
      title: text.ostuzina_title,
      year: 2019,
      image: 'images/platform/platform-item1.jpeg',
      ref: '/ostruzina',
    },
    {
      title: text.urlich_tarasyan,
      year: 2020,
      image: 'images/platform/platform-item3.jpeg',
      ref: '/tarasyan',
    },
    {
      title: text.dansteele,
      year: 2021,
      image: 'images/platform/platform-item6.jpg',
      ref: '/dansteele',
    },
    {
      title: text.tarasyan,
      year: 2021,
      image: 'images/platform/platform-item7.jpeg',
      ref: '/tarasyan2',
    },
    {
      title: text.nur,
      year: 2022,
      image: 'images/platform/platform-item8.jpg',
      ref: '/nur',
    },
    {
      title: text.bezrukov,
      year: 2022,
      image: 'images/platform/platform-item9.jpg',
      ref: '/platform/9',
    }
  ];

  const prefix = 'platform';
  const getData = (field) => data[prefix][field][localStorage.getItem('language')];


  return (
    <section className='main-container main-container-decoration platform-container we-play-container'>
      <div className='description-banner'>
        <h2 className='title-generic'>{getData('title')}</h2>
        <p dangerouslySetInnerHTML={{ __html: getData('description') }}></p>
      </div>
      <section className='center-slider the-platform-slider'>
        <Slider {...slickerSettings}>
          {
            state.map(({title, year, image, ref}, index) =>
            <div className='slide' key={`platform-${index}`}>
              <div className='slider-title'>
                <h2>{title}</h2>
                <h2>{year}</h2>
              </div>
              <div className='child-element'>
                <div className='deco1 animate animate__animated animate__bounce animate__delay-0 animate__fadeInDown'>
                  <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
                </div>
                <a href={ref}>
                  <img src={image} alt='icon' className='img-fluid' />
                </a>
                <div className='deco2 animate animate__animated animate__bounce animate__delay-0 animate__fadeInDown'>
                  <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
                </div>
              </div>
            </div>
            )
          }
        </Slider>
      </section>
    </section>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(platform));
