import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function Freedom() {
  return (
    <div className='main-container'>
      <div className='breadcrumb container'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/opinion8' className='breadcrumb-active'>
          Ասք բռնության մասին
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong> Ասք բռնության մասին</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item8.jpeg' alt='icon' className='img-fluid' />
        <p>
          Այսպես, հանդիսավոր ասեմ, ռեժիսոր Նանոր Պետրոսյանի ներկայացման մասին, որի անունը ես դեռ չեմ ասի։ Կասեմ վերջում։
          Հենց սկզբից ես նկատում էի այսպես կոչված ռեժիսորական «նախոդկաներ», ու ուզում էի դրանց մասին գրել, իսկ հետո
          կամաց կամաց պարզ դարձավ, որ դրանք պարզապես նախոդկաներ չեն, կամ պարզապես ձեւ չէ, որ գուցե այլ տեղ էլ է
          կիրառվել։ Օրինակ, այն որ դերասանները դահլիճում էին սկզբից, իսկ հետո իրենք իրենց աթոռները վերցրին ու բարձրացան
          բեմ։ Ու դա պատահական նախոդկա չէր։ Ի դեպ, նախոդկաներից՝ նախոդկա էր Մարի Դոլմազյանը իր ալտով։ Այդ գործիքը եւս
          մեկ արտահայտման շերտ էր, եւս մի ինտերպրետացիա, եւս մի ձայն։ Իսկ ի՞նչ էին արտահայտում դերասանները․ մեկը՝ շատ
          անհանգիստ, անընդհատ տանջվող։ Ժամանակ առ ժամանակ, երբ էլ չէր դիմանում, վեր էր կենում, բայց ձեռքը աթոռից չէր
          թողնում, վախենում էր թողնել իր աթոռը։ Աթոռը ապահովություն էր տալիս։ Իսկ ապահովության զգացողությունը թմրանյութի
          ազդեցության տակ հալյուցինացիայի պես է, այն իրական չէ, սակայն այնքան է ձգում, որ հնարավոր չէ դիմադրել։ Այդ
          աղջիկը իրենցից միակն էր, ումից երեւում էր, որ նա հասկանում է որ բնավ լավ վիճակում չէ, զգում է դա իր բոլոր
          բջիջներով։ Դա այն մասին է, որ շատ կարեւոր է հասկանալ, որ(երբ) դու հեչ էլ լավ վիճակում չես։ Կոնֆորմիզմը, ինձ
          թվում է, նաեւ դրանից է գալիս՝ երբ մարդիկ լավ էլ գոհ են իրենց կյանքից, բնավ չեն հասկանում որ նրանք իրականում
          վատ են։ Լինի դա ամուսնացած զույգ, կորպորացիայում աշխատող ինժեներ, բանկի կլերկ, պետական չինովնիկ, կամ նույնիսկ
          փողոցով անցնող քաղաքացի։ Եթե նրանք գոնե հասկանային, ինչ վատ են, դա արդեն կարող էր առաջին քայլը լինել։
          Դերակատարներից մնացածը, կարծես, անհամեմատ ավելի հանգիստ էին վերաբերվում կատարվողին։ Ինչպես եւ ցանկացած
          հասարակության, կամ ընկերության, կամ խմբի մեջ։ Փոքր մասն է գիտակցում, իսկ ավելի փոքր մասը անցնում ավելի առաջ։
          Այս դեպքում ֊ գործողություն է նույնիսկ աթոռը թողնել։ Բայց այն պահանջում է սովորական դարձածից ազատվելու
          համարձակություն, կամք։ Որոշում։
        </p>
        <p>
          Որոշումը մահվան պես սարսափելի է։ Պատահական չէ, որ «decide» բառի արմատը հենց այն մասնիկն է, որ մենք հանդիպում
          ենք նաեւ «homicide» եւ «suicide» բառերում։ Ու այո, մնացածն էլ չեն որոշում։ Նրանք թվում են հանդարտ։ Մարին,
          կարծես միայն մեկ անգամ ինքնամոռաց թողեց իր տեղը, երբ իր ալտը արտահայտում էր իր ցավն ու սարսափը, բայց երբ
          նկատեց, որ իր տեղում չէ, իսկույն իրեն հավաքեց, ու զբաղեցրեց այն։ Անշուշտ, մենք բռնության ականատես էինք։
          Հասմիկը խոսում էր դանդաղ։ Արտահայտվում էր։ Նարեն նույնպես։ Շատ դանդաղ։ Իրենց մեկնաբանություններից պարզ էր
          դառնում որ ինչ որ մեկի սահմանը անցել են։ Հետո՝ որ վատ է լինելու, իրենցն էլ կանցնեն։ Որ սառնասրտորեն ու դաժան։
          Ընդ որում Նարեն ոնց որ ընդդիմադիր մամուլ լիներ՝ ամբողջ ճշմարտությունը չէր ասում, մի մասը ասում էր, իսկ մի մասը
          բաց թողնում, ոնց որ վախենար ձայնավորելուց։ Այնքանը թույլատրելի սահմաններում էր, իսկ մնացած բառերը բարձրաձայն
          ասելու համարձակություն չէր հերիքում։ Երբեմն հնչեցնել այդ բառերը կարողանում էր այն լավ գիտակցող աղջիկը։
          Գիտակցող, որ հարմար չէ, որ ճնշված է։ Մնացածը արտահայտում էին որոշակի տագնապ, որոշակի մտահոգություն, բայց
          բավական սառնասրտորեն։ Երբ ուրիշների մասին էր ֊ ու պետք է պարզ լիներ, որ իրենց հետ էլ կլինի, ինձ նույնիսկ մի
          պահ թվաց որ ոնց որ հեռուստացույց դիտեն։ Բայց ոչ մի փոխելու փորձ, ընդամենը դանդաղ արտահայտություններ։ Այդ
          դանդաղությունը, ինձ թվում է այն մասին է, որ երբ քո սահմանները անցնում են արագ ֊ եկավ, վրաերթեց ֊ անմիջապես
          ռեակցիան լինում է ֊ կամ փախուստ, կամ դիմադրություն։ Որովհետեւ այդ ժամանակային չափողականության մեջ գիտակցումը
          գալիս է, ու դրան կարող է հետեւել պրոտեստը։ Պրոտեստը չի հետեւում երբ ժամանակը աննկատ է սլանում։ Օրինակ՝ աննկատ
          բարձրացրին հարկերը, քիչ քիչ ամրապնդվեց ինֆլյացիան, դանդաղ, բայց պարզ դարձավ որ արտահայտվելը արդեն վտանգավոր է,
          երեկ աշխատանքի տեղը խորհուրդ չէր տրվում մտնել սոցիալական ցանցեր, իսկ այսօր չաթ մտնելու համար ադմինիստրատիվ
          տուգանք է, երեկ ամուսինը նկատողություն արեց, որ պատառաքաղը սխալ ես բռնում, իսկ այսօր՝ որ դու ես մեղավոր, որ
          զարդուցիչը չզանգեց, ու քեզ ծեծ է հասնում։ Այն մասին է, երբ իջեցնես գորտին եռացող ջրի մեջ՝ նա ցավը կզգա, կթռնի
          դուրս, իսկ եթե նրան իջեցնես գոլ ջրի մեջ, ու քիչ քիչ ջրի տաքությունը բարձրացնես՝ չի էլ նկատի ինչպես եփվեց, ու
          բնականաբար, մահացավ, սակայն դուրս չի թռնի։
        </p>
        <p>
          Հա, պիեսը կոչվում է «Բռնաբարություն»։ Ինչպես նշեցի, սա ընդհանուր առմամբ բռնության մասին էր՝ առանց շեշտելու ֊
          սեռական է այն, թե քաղաքական, ընտանեկան թե գողական, ոստիկանական, թե կորպորատիվ, մանկապարտեզում թե աշխատանքի
          վայրում։ Ու վերջում, բնականաբար, դերակատարները վերցրին իրենց աթոռները ու միացան դահլիճին։ Քանի որ նրանք
          ընդամենը մեզնից մի քանիսն էին, իսկ մենք բոլորս այդ նույն վիճակում ենք, պարզապես չնկատելու ենք տալիս ճիշտ իրենց
          պես։ Ու շատ կարեւոր էր, որ Նարեն հենց այսպես, մեր պես վերարկուով էր նստած։ Որ շեշտվի, որ մեզնից չի տարբերվում,
          կամ ավելի ճիշտ մենք իրենից չենք տարբերվում, նույն վիճակում ենք։ Ու գուցե պատահական չէ, որ նրանք մեզ էին
          նայում, իսկ մենք՝ իրենց։ Ու ինձ թվում է, պիեսի անունը կարող էր լինել «Հայելի»։ Գնացինք նայեցինք ինքներզ մեզ։
          Շնորհակալություն, Նանոր։
        </p>

        <p> Նորայր Չիլինգարյան</p>
        <p> norayr.am</p>
        <p>24.10.2012</p>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freedom));
