import Slider from 'react-slick';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './weconnect.css';
import './bem.css';
const data = require('./lang/lang.json');

class KondFest5 extends Component {
  state = {
    showItem: '',
  };

  render() {
    const text = data[localStorage.getItem('language')];
    const slickerSettings = {
      customPaging: (i) => {
        if (i > 4) {
          return <div></div>;
        } else {
          return <button>i</button>;
        }
      },

      slidesToShow: 5,
      slidesToScroll: 1,
      speed: 500,
      centerPadding: '0',
      centerMode: true,
      centerMargin: '0',
      variableWidth: false,
      className: 'bem',
      adaptiveHeight: false,
      autoplay: true,
      dots: true,
      arrow: false,
      autoplaySpeed: 2500,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerPadding: '0',
            centerMode: true,
            centerMargin: '0',
            slidesToShow: 3,
            slidesToScroll: 0,
            adaptiveHeight: false,
            dots: true,
            arrow: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            centerPadding: '0',
            centerMode: true,
            centerMargin: '0',
            slidesToShow: 1,
            slidesToScroll: 0,
            adaptiveHeight: false,
            dots: true,
            arrow: false,
          },
        },
      ],
    };
    return (
      <section className='main-container current-container slider-centered'>
        <div className='breadcrumb container'>
          <a href='/kond_fest'>{text.kond}/</a>
          <a href='/kondfest5' className='breadcrumb-active'>
            {text.kond5}
          </a>
        </div>
        <div className='section-details modal-slider'>
          <div className='row m-0 position-relative'>
            <div className='white-decoration'>
              <img src='images/white-svg.svg' alt='asv' />
            </div>
            <div className='col-lg-6 p-0 pr-lg-4'>
              <img src='images/kondFest5/img.png' alt='kondfest' />
            </div>
            <div className='main-description-banner col-lg-5'>
              <b>
                <p dangerouslySetInnerHTML={{ __html: text.kond5 }}></p>
              </b>
              <div className='info-block'>
                <p dangerouslySetInnerHTML={{ __html: text.kond_fest_5_desc }}></p>
              </div>
              <br />
              <div className='row m-0'>
                <i style={{ backgroundImage: "url('images/pdf.png')", height: ' 25px', width: '25px' }}></i>
                <div className='info-block'>
                  <a href={`images/kondFest5/${localStorage.getItem('language')}.pdf`} download={text.kond5}>
                    {text.downloadkond5}
                  </a>
                </div>
              </div>
            </div>
            <a href='/kondFest1' className='col-lg-1 p-0 next-page desktop-next'>
              <p> {text.next}</p>
              <img src='images/arrow.svg' alt='' />
            </a>
          </div>

          <Slider {...slickerSettings}>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_1.jpg' alt='' data-target='#carouselGallery' data-slide-to='0' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_2.jpg' alt='' data-target='#carouselGallery' data-slide-to='1' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_3.jpg' alt='' data-target='#carouselGallery' data-slide-to='2' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_4.jpg' alt='' data-target='#carouselGallery' data-slide-to='3' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_5.jpg' alt='' data-target='#carouselGallery' data-slide-to='4' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_6.jpg' alt='' data-target='#carouselGallery' data-slide-to='5' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_7.jpg' alt='' data-target='#carouselGallery' data-slide-to='6' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_8.jpg' alt='' data-target='#carouselGallery' data-slide-to='7' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_9.jpg' alt='' data-target='#carouselGallery' data-slide-to='8' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_10.jpg' alt='' data-target='#carouselGallery' data-slide-to='9' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_11.jpg' alt='' data-target='#carouselGallery' data-slide-to='10' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_12.jpg' alt='' data-target='#carouselGallery' data-slide-to='11' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_13.jpg' alt='' data-target='#carouselGallery' data-slide-to='12' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_14.jpg' alt='' data-target='#carouselGallery' data-slide-to='13' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_15.jpg' alt='' data-target='#carouselGallery' data-slide-to='14' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_16.jpg' alt='' data-target='#carouselGallery' data-slide-to='15' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_17.jpg' alt='' data-target='#carouselGallery' data-slide-to='16' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_18.jpg' alt='' data-target='#carouselGallery' data-slide-to='17' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_19.jpg' alt='' data-target='#carouselGallery' data-slide-to='18' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/kondFest5/img_20.jpg' alt='' data-target='#carouselGallery' data-slide-to='19' />
            </div>
          </Slider>

          <div className='modal fade' id='GalleryModal' tabIndex='-1' role='dialog' aria-hidden='true'>
            <div className='modal-dialog' role='document'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                <div className='modal-body'>
                  <div id='carouselGallery' className='carousel slide' data-ride='carousel'>
                    <div className='carousel-inner'>
                      <div className='carousel-item active'>
                        <img className='d-block w-100' src='images/kondFest5/img_1-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_2-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_3-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_4-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_5-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_6-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_7-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_8-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_9-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_10-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_11-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_12-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_13-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_14-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_15-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_16-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_17-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_18-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_19-large.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/kondFest5/img_20-large.jpg' alt='' />
                      </div>
                    </div>

                    <a className='carousel-control-prev' href='#carouselGallery' role='button' data-slide='prev'>
                      <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                      <span className='sr-only'>Previous</span>
                    </a>
                    <a className='carousel-control-next' href='#carouselGallery' role='button' data-slide='next'>
                      <span className='carousel-control-next-icon' aria-hidden='true'></span>
                      <span className='sr-only'>Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a href='/kondFest6' className='col-lg-1 p-0 next-page mobile-next'>
            <p> {text.next}</p>
            <img src='images/arrow.svg' alt='' />
          </a>
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KondFest5));
