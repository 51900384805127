import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import Slider from 'react-slick';
import './weplay.css';

let data = require('./lang/lang.json');

function wePlay() {
  const text = data[localStorage.getItem('language')];
  const slickerSettings = {
    customPaging: (i) => {
      if (i > 11) {
        return <div></div>;
      } else {
        return <button>i</button>;
      }
    },
    infinite: true,
    centerPadding: '5px',
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 500,
    centerMode: true,
    variableWidth: false,
    // className: 'bem',
    centerMargin: '0',
    adaptiveHeight: false,
    autoplay: true,
    dots: true,
    prevArrow: <div />,
    nextArrow: <div />,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '0',
          slidesToShow: 1,
          slidesToScroll: 0,
          adaptiveHeight: false,
          dots: true,
          prevArrow: <div />,
          nextArrow: <div />,
        },
      },
    ],
  };
  const slides = [
    {
      title: text.unexampled,
      year: 2022,
      path: '/unexampled',
      image: 'images/anorinak/poster.jpg',
    },
    {
      title: text.belgrad,
      year: 2022,
      path: '/belgrad',
      image: 'images/belgrad/Poster.jpg',
    },
    {
      title: text.confused,
      year: 2022,
      path: '/confused',
      image: 'images/confused/poster.jpg',
    },
    {
      title: text.someone,
      year: 2022,
      path: '/someone',
      image: 'images/someone/poster.jpg',
    },
    {
      title: text.hercules,
      year: 2023,
      path: '/hercules',
      image: 'images/hercules/poster.jpg',
    },
    {
      title: text.belated,
      year: 2023,
      path: '/belated',
      image: 'images/belated/poster.jpg',
    },
  ];
  return (
    <>
      <section className='main-container main-container-decoration we-play-content we-play-container'>
        <div className='description-banner'>
          <h2 className='title-generic'>{text.weplay_title}</h2>
          <p dangerouslySetInnerHTML={{ __html: text.weplay_desc }}></p>
        </div>
        <section className='center-slider m-auto we-dots'>
          <Slider {...slickerSettings}>
            <div className='slide'>
              <div className='slider-title'>
                <h2>{text.rape}</h2>
                <p className='date text-center'>2012</p>
              </div>
              <div className='child-element'>
                <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration1.svg' alt='icon' className='img-fluid ' />
                </div>
                <a href='/rape'>
                  <img src='images/slider-item1.jpg' alt='icon' className='img-fluid img-no-stretch' />
                </a>
                <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
                </div>
              </div>
            </div>
            <div className='slide'>
              <div className='slider-title'>
                <h2>{text.maro}</h2>
                <p className='date text-center'>2013</p>
              </div>
              <div className='child-element'>
                <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
                </div>

                <a href='/maro2'>
                  <img src='images/slider-item6.jpg' alt='icon' className='img-fluid img-no-stretch' />
                </a>
                <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
                </div>
              </div>
            </div>
            <div className='slide'>
              <div className='slider-title'>
                <h2>{text.asv}</h2>
                <p className='date text-center'>2014</p>
              </div>
              <div className='child-element'>
                <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
                </div>
                <a href='/asv'>
                  <img src='images/slider-item5.jpg' alt='icon' className='img-fluid img-no-stretch' />
                </a>
                <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
                </div>
              </div>
            </div>

            <div className='slide'>
              <div className='slider-title'>
                <h2>{text.rewrite}</h2>
                <p className='date text-center'>2015</p>
              </div>
              <div className='child-element'>
                <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
                </div>
                <a href='/rewritingthehistory'>
                  <img src='images/slider-item2.jpg' alt='icon' className='img-fluid img-no-stretch' />
                </a>
                <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
                </div>
              </div>
            </div>
            <div className='slide'>
              <div className='slider-title'>
                <h2>{text.maro}</h2>
                <p className='date text-center'>2016</p>
              </div>
              <div className='child-element'>
                <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
                </div>

                <a href='/maro'>
                  <img src='images/slider-item8.jpg' alt='icon' className='img-fluid img-no-stretch' />
                </a>
                <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
                </div>
              </div>
            </div>
            <div className='slide'>
              <div className='slider-title'>
                <h2>{text.line}</h2>
                <p className='date text-center'>2017</p>
              </div>
              <div className='child-element'>
                <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
                </div>
                <a href='/line'>
                  <img src='images/slider-item4.jpg' alt='icon' className='img-fluid img-no-stretch' />
                </a>
                <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
                </div>
              </div>
            </div>
            <div className='slide'>
              <div className='slider-title'>
                <h2>{text.ttw_title}</h2>
                <p className='date text-center'>2018</p>
              </div>
              <div className='child-element'>
                <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
                </div>

                <a href='/tellToTheWater'>
                  <img src='images/slider-item10.jpg' alt='icon' className='img-fluid img-no-stretch' />
                </a>
                <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
                </div>
              </div>
            </div>
            <div className='slide'>
              <div className='slider-title'>
                <h2>{text.mo_title}</h2>
                <p className='date text-center'>2019</p>
              </div>
              <div className='child-element'>
                <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
                </div>

                <a href='/masterOhan'>
                  <img src='images/slider-item9.jpg' alt='icon' className='img-fluid img-no-stretch' />
                </a>
                <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
                </div>
              </div>
            </div>
            <div className='slide'>
              <div className='slider-title'>
                <h2>{text.regime}</h2>
                <p className='date text-center'>2019</p>
              </div>
              <div className='child-element'>
                <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
                </div>
                <a href='/regimeInPanic'>
                  <img src='images/slider-item3.jpeg' alt='icon' className='img-fluid img-no-stretch' />
                </a>
                <div className='deco2'>
                  <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
                </div>
              </div>
            </div>
            <div className='slide'>
              <div className='slider-title'>
                <h2>{text.evredite}</h2>
                <p className='date text-center'>2019</p>
              </div>
              <div className='child-element'>
                <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
                </div>
                <a href='/eurydice'>
                  <img src='images/slider-item7.jpg' alt='icon' className='img-fluid img-no-stretch' />
                </a>
                <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
                </div>
              </div>
            </div>
            <div className='slide'>
              <div className='slider-title'>
                <h2>{text.unrecognized_title}</h2>
                <p className='date text-center'>2020</p>
              </div>
              <div className='child-element'>
                <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
                </div>

                <a href='/unrecognized'>
                  <img src='images/slider-item11.jpg' alt='icon' className='img-fluid img-no-stretch' />
                </a>
                <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
                </div>
              </div>
            </div>
            <div className='slide'>
              <div className='slider-title'>
                <h2>{text.gibrion}</h2>
                <p className='date text-center'>2021</p>
              </div>
              <div className='child-element'>
                <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
                </div>
                <a href='/gibrion'>
                  <img src='images/slider-item12.jpg' alt='icon' className='img-fluid img-no-stretch' />
                </a>
                <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
                </div>
              </div>
            </div>
            <div className='slide'>
              <div className='slider-title ml-5'>
                <h2>{text.talk_to_me}</h2>
                <p className='date text-center'>2021</p>
              </div>
              <div className='child-element'>
                <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
                </div>
                <a href='/talkToMe'>
                  <img src='images/slider-item13.jpg' alt='icon' className='img-fluid img-no-stretch' />
                </a>
                <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
                </div>
              </div>
            </div>
            <div className='slide'>
              <div className='slider-title'>
                <h2>{text.plenar}</h2>
                <p className='date text-center'>2021</p>
              </div>
              <div className='child-element'>
                <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
                </div>
                <a href='/plenar'>
                  <img src='images/slider-item14.jpg' alt='icon' className='img-fluid img-no-stretch' />
                </a>
                <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                  <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
                </div>
              </div>
            </div>
            {slides.map(({ title, year, path, image }) => (
              <div className='slide'>
                <div className='slider-title'>
                  <h2>{title}</h2>
                  <p className='date text-center'>{year}</p>
                </div>
                <div className='child-element'>
                  <div className='deco1 animate animate__animated animate__bounce animate__fadeInDown'>
                    <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
                  </div>
                  <a href={path}>
                    <img src={image} alt='icon' className='img-fluid img-no-stretch' />
                  </a>
                  <div className='deco2 animate animate__animated animate__bounce animate__fadeInDown'>
                    <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </section>
      </section>
    </>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(wePlay));
