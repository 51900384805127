import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import './weconnect.css';
let data = require('./lang/lang.json');

function Lab() {
  const text = data[localStorage.getItem('language')];
  return (
    <section className='main-container'>
      <div className='description-banner'>
        <h2 className='title-generic text-uppercase'>{text.lab}</h2>
        <p dangerouslySetInnerHTML={{ __html: text.lab_desc }}></p>
      </div>

      <section className='center-slider  lab-container'>
        <div className='generic-container'>
          <div className='row m-0'>
            <div className='col-lg-6'>
              <img src='../images/lab/lab-item1.jpeg' alt='icon' className='img-fluid img-box' />
            </div>
            <div className='col-lg-6'>
              <p className='row m-0'>{text.lab_ad_club_title}</p>
              <p dangerouslySetInnerHTML={{ __html: text.lab_ad_club_desc }}></p>
              <div className='row sml-block'>
                <a href='/masterOhan' className='col-lg-7 mobile-pb'>
                  <div class='lab-details lab-mobile-details'>
                    <img src='images/master-ogan.jpeg' alt='' />
                    <div>
                      <p className='m-0' dangerouslySetInnerHTML={{ __html: '«' + text.mo_title + '»' }}></p>
                      <p dangerouslySetInnerHTML={{ __html: text.mo_author }}></p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12'>
          <img src='images/we-read/line1.svg' className='img-fluid' alt='item' title='item' />
        </div>
        <div className='generic-container'>
          <div className='row m-0 flex-reverse'>
            <div className='col-lg-6'>
              <p className='row m-0'>{text.lab_tech_amateur_title}</p>
              <p dangerouslySetInnerHTML={{ __html: text.lab_tech_amateur_desc }}></p>
              <div className='row sml-block lab-mobile-details'>
                <a href='/line' className='col-lg-5'>
                  <p className='mb-0'>{text.line} 2017</p>
                  <p dangerouslySetInnerHTML={{ __html: text.line_author }}></p>
                  <img src='images/slider-item4.jpg' alt='' />
                </a>
                <div className='col-lg-1'></div>
                <a href='/maro2' className='col-lg-5'>
                  <p className='mb-0'>{text.maro} 2013 </p>
                  <p dangerouslySetInnerHTML={{ __html: text.mo_author }}></p>
                  <img src='images/slider-item6.jpg' alt='' />
                </a>
              </div>
            </div>
            <div className='col-lg-6'>
              <img src='../images/lab/lab-item2.jpeg' alt='icon' className='img-fluid img-box' />
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Lab));
