import Slider from 'react-slick';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './bem.css';

import { scrollIntoView } from './ScrollHelper';
const data = require('./lang/lang.json');

class plenar extends Component {
  state = {
    showItem: '',
  };

  render() {
    const text = data[localStorage.getItem('language')];
    const slickerSettings = {
      customPaging: (i) => {
        if (i > 4) {
          return <div></div>;
        } else {
          return <button>i</button>;
        }
      },
      slidesToShow: 5,
      slidesToScroll: 1,
      speed: 500,
      centerPadding: '0',
      centerMode: true,
      centerMargin: '0',
      variableWidth: false,
      className: 'bem',
      adaptiveHeight: false,
      autoplay: true,
      dots: true,
      arrow: false,
      autoplaySpeed: 2500,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerPadding: '0',
            centerMode: true,
            centerMargin: '0',
            slidesToShow: 3,
            slidesToScroll: 0,
            adaptiveHeight: false,
            dots: true,
            arrow: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            centerPadding: '0',
            centerMode: true,
            centerMargin: '0',
            slidesToShow: 1,
            slidesToScroll: 0,
            adaptiveHeight: false,
            dots: true,
            arrow: false,
          },
        },
      ],
    };
    return (
      <section className='main-container current-container slider-centered'>
        <div className='breadcrumb container'>
          <a href='/wePlay'>{text.weplay_title}/</a>
          <a href='/plenar' className='breadcrumb-active'>
            {text.plenar}
          </a>
        </div>
        <div className='section-details modal-slider'>
          <div className='row m-0 position-relative'>
            <div className='white-decoration'>
              <img src='images/white-svg.svg' alt='asv' />
            </div>
            <div className='col-lg-6 p-0 pr-lg-4'>
              <img src='images/plenar/1.jpg' alt='plenar' />
            </div>
            <div className='main-description-banner col-lg-5 p-lg-0'>
              <p className='mb-0'>{text.plenar}</p>
              <p className='mb-0'>{text.plenar_author}</p>
              <div className='info-block'>
                <p dangerouslySetInnerHTML={{ __html: text.plenar_desc }}></p>
                <p dangerouslySetInnerHTML={{ __html: text.plenar_date }}></p>
                <p dangerouslySetInnerHTML={{ __html: text.plenar_cast }}></p>
                <a
                  href='#!'
                  onClick={() => scrollIntoView('video-link-talkToMe')}
                  className='info-link  d-block mb-2 mt-3'
                >
                  {text.video_soon}
                </a>
              </div>
            </div>
            <a href='/unexampled' className='col-lg-1 p-0 next-page desktop-next'>
              <p> {text.next}</p>
              <img src='images/arrow.svg' alt='' />
            </a>
          </div>
          <Slider {...slickerSettings}>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/plenar/item-sml1.jpg' alt='' data-target='#carouselGallery' data-slide-to='0' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/plenar/item-sml2.jpg' alt='' data-target='#carouselGallery' data-slide-to='1' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/plenar/item-sml3.jpg' alt='' data-target='#carouselGallery' data-slide-to='2' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/plenar/item-sml4.jpg' alt='' data-target='#carouselGallery' data-slide-to='3' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/plenar/item-sml1.jpg' alt='' data-target='#carouselGallery' data-slide-to='4' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/plenar/item-sml2.jpg' alt='' data-target='#carouselGallery' data-slide-to='5' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/plenar/item-sml3.jpg' alt='' data-target='#carouselGallery' data-slide-to='6' />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img src='images/plenar/item-sml4.jpg' alt='' data-target='#carouselGallery' data-slide-to='7' />
            </div>
          </Slider>
          <div className='modal fade' id='GalleryModal' tabIndex='-1' role='dialog' aria-hidden='true'>
            <div className='modal-dialog' role='document'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                <div className='modal-body'>
                  <div id='carouselGallery' className='carousel slide' data-ride='carousel'>
                    <div className='carousel-inner'>
                      <div className='carousel-item active'>
                        <img className='d-block w-100' src='images/plenar/item1.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/plenar/item2.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/plenar/item3.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/plenar/item4.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/plenar/item1.jpg' alt='' />
                      </div>
                      <div className='carousel-item '>
                        <img className='d-block w-100' src='images/plenar/item2.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/plenar/item3.jpg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/plenar/item4.jpg' alt='' />
                      </div>
                    </div>
                    <a className='carousel-control-prev' href='#carouselGallery' role='button' data-slide='prev'>
                      <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                      <span className='sr-only'>Previous</span>
                    </a>
                    <a className='carousel-control-next' href='#carouselGallery' role='button' data-slide='next'>
                      <span className='carousel-control-next-icon' aria-hidden='true'></span>
                      <span className='sr-only'>Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a href='/unexampled' className='col-lg-1 p-0 next-page mobile-next'>
            <p> {text.next}</p>
            <img src='images/arrow.svg' alt='' />
          </a>
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(plenar));
