import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import './Topnav.css';

class Topnav extends Component {
    handleChange = (e) => {
        let lang = this.props.language.language;
        if (!lang) {
            lang = localStorage.getItem('language');
        }
        this.props.setLanguage(lang === 'en' ? 'arm' : 'en');
    };

    render = (props) => {
        const lang = localStorage.getItem('language');
        if (!lang) {
            localStorage.setItem('language', 'arm');
        }
        return (
            <div>
                <button href='!#'
                        className={this.props.cls}
                        onClick={(e) => this.handleChange(e)}>
                    {localStorage.getItem('language') === 'arm' ? 'EN' : 'ARM'}
                </button>
            </div>
        );
    };
}

const mapDispatchToProps = (dispatch) => ({
    setLanguage: (language) => {
        dispatch({type: 'SET_LANGUAGE', language});
    },
});

const mapStateToProps = (state) => {
    return {language: state.language};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topnav));
