import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import './weconnect.css';
let data = require('./lang/lang.json');

function ExpositionPage() {
  const text = data[localStorage.getItem('language')];
  return (
    <section className='main-container connect'>
      <div className='description-banner'>
        <h2 className='title-generic'>{text.exposition}</h2>
        <p dangerouslySetInnerHTML={{ __html: text.exposition_desc }}></p>
      </div>
      <div className='we-connect-section'>
        <div className='generic-container'>
          <div className='row m-0'>
            <div className='col-lg-6 pl-connect_desc0'>
              <img src='images/exposition/2.png' alt='' />
            </div>
            <div className='main-description-banner col-lg-6'>
              <p className='row m-0' dangerouslySetInnerHTML={{ __html:text.exposition_title_1}}></p> 
              <p className='row m-0'dangerouslySetInnerHTML={{ __html:text.exposition_subtitle_1}}></p>
              <div className='info-block'>
                <p dangerouslySetInnerHTML={{ __html: text.exposition_text_1 }}></p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12'>
          <img src='images/we-read/line1.svg' className='img-fluid' alt='item' title='item' />
        </div>
        <div className='generic-container'>
          <div className='row m-0 mobile-reverse'>
          <div className='main-description-banner col-lg-6'>
              <p className='row m-0' dangerouslySetInnerHTML={{ __html:text.exposition_title_2}}></p> 
              <p className='row m-0'dangerouslySetInnerHTML={{ __html:text.exposition_subtitle_2}}></p>
              <div className='info-block'>
                <p dangerouslySetInnerHTML={{ __html: text.exposition_text_2 }}></p>
              </div>
            </div>
            <div className='col-lg-6 pl-0 animals_image'>
              <img src='images/exposition/1.png' alt='' />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExpositionPage));
