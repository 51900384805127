import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import './weconnect.css';
let data = require('./lang/lang.json');

function weConnect() {
  const prefix = 'we_connect';
  const { state } = data[prefix];
  const getData = (field) => data[prefix][field][localStorage.getItem('language')];

  const getStateData = (field) => field[localStorage.getItem('language')];

  return (
    <section className='main-container connect'>
      <div className='description-banner'>
        <h2 className='title-generic'>{getData('title')}</h2>
        <p dangerouslySetInnerHTML={{ __html: getData('description') }}></p>
      </div>
      <div className='we-connect-section'>
        {state.map(({ title, subtitle, image, text }, index) => (
          <React.Fragment key={`${prefix}-${index}`}>
            <div className='generic-container'>
              {index % 2 ? (
                <div className={`row m-0 mobile-reverse`}>
                  <div className='col-lg-6 pl-0'>
                    <img src={image} alt='' />
                  </div>
                  <div className='main-description-banner col-lg-6'>
                    <p className='row m-0' dangerouslySetInnerHTML={{ __html: getStateData(title) }}></p>
                    <p className='row m-0' dangerouslySetInnerHTML={{ __html: getStateData(subtitle) }}></p>
                    <div className='info-block'>
                      <p dangerouslySetInnerHTML={{ __html: getStateData(text) }}></p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={`row m-0`}>
                  <div className='main-description-banner col-lg-6'>
                    <p className='row m-0' dangerouslySetInnerHTML={{ __html: getStateData(title) }}></p>
                    <p className='row m-0' dangerouslySetInnerHTML={{ __html: getStateData(subtitle) }}></p>
                    <div className='info-block'>
                      <p dangerouslySetInnerHTML={{ __html: getStateData(text) }}></p>
                    </div>
                  </div>
                  <div className='col-lg-6 pl-0'>
                    <img src={image} alt='' />
                  </div>
                </div>
              )}
            </div>
            {index !== state.length - 1 && (
              <div className='col-12'>
                <img
                  src={`images/we-read/line${index % 2 ? 2 : 1}.svg`}
                  className='img-fluid'
                  alt='item'
                  title='item'
                />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </section>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(weConnect));
