import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
let data = require('./lang/lang.json');

function kondEvent(props) {
  const text = data[localStorage.getItem('language')];
  const { id } = props.match.params;
  const image = text[`kond.${id}.image`]
  const textArm = text[`kond.${id}.textarm`]
  const textEng = text[`kond.${id}.texteng`]
  return (
    <section className='main-container current-container'>
      <div className='section-details'>
        <div className='row m-0 position-relative'>
        <div className='white-decoration'>
					  <img src='../images/white-svg.svg' alt='asv' />
					</div>
          <div className='col-lg-6 pl-0 we-img-container'>
            <img src={image} alt='' />
          </div>
          <div className='main-description-banner col-lg-5'>
            <p dangerouslySetInnerHTML={{ __html: textArm }}></p>
            <p dangerouslySetInnerHTML={{ __html: textEng }}></p>
          </div>
        </div>
      </div>
    </section>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(kondEvent));
