import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function Freedom() {
  return (
    <div className='main-container'>
      <div className='breadcrumb container'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/opinion5' className='breadcrumb-active'>
          “Բռնաբարություն” ՆՓԱԿ-ում
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong> “Բռնաբարություն” ՆՓԱԿ-ում</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item6.jpeg' alt='icon' className='img-fluid' />
        <p>
          Նորարար փորձառական արվեստի կենտրոնում երեկ կայացել է իտալացի դրամատուրգ, դերասան եւ ռեժիսոր, նոբելյան
          մրցանակակիր Դարիո Ֆոյի եւ իր կնոջ` դերասանունի, դրամատուրգ Ֆրանկա Ռամեի “Բռնաբարություն” պիեսի հիման վրա
          բեմադրված ներկայացման պրեմիերան, որի ռեժիսորն է Նանոր Պետրոսյանը, նկարիչը` Նվարդ Երկանյանը: ՆՓԱԿ-ի բեմում հինգ
          կին էր` Գայանե Բալյանը, Նարե Գրիգորյանը, Սոնա Հովհաննիսյանը, Հասմիկ եւ Մերի Դոլմազյանները: Վերջինս “խոսում” էր
          միայն իր հավատարիմ ընկեր ջութակի միջոցով: Ներկայացման ընթացքում հնչող երաժշտությունը հենց նա էր հեղինակել: Այս
          կանայք ալեգորիաների կամ դառը ճշմարտության, անուրջներից հեռու, դաժան, մաքսիմալ իրական, բայց միեւնույն ժամանակ
          վերացական խոսքերի ու շարժումների միջոցով պատմում էին իրենց պատմությունը` չխնայելով հանդիսատեսի նյարդերը: Մինչ
          “Բռնաբարության” պրեմիերան, մոտ 2 տարի ռեժիսորը նախապատրաստական աշխատանքներն է իրականացրել: Ներկայացման մեջ
          հանդես են գալիս ոչ պրոֆեսիոնալ դերասանուհիներ, որոնք տարբեր մասնագիտություններ ունեն, սակայն համախմբվել են
          բեմադրությունն իրականացնելու գաղափարի շուրջ: “Այսօր Երեւանում ապրում ենք մի հասարակարգում, որտեղ մարդը ճնշված
          է, նրա արժանապատվությունը ոտնահարված է: Անպայման չէ կնոջ հանդեպ բռնությունն ուղիղ իմաստով հասկանալ:
          Նախագահական ընտրություններից հետ, երբ հասկանում ես, որ ձայնդ տեղ չի հասել, երբ պատմամշակութային արժեք ունեցող
          շենք են քանդում եւ այլն, այս ամենը եւս կարելի է որպես բռնություն դիտարկել”,-BRAVO.am-ին ասել է իր ռեժիսորական
          դեբյուտը ներկայացրած Նանոր Պետրոսյանը:
        </p>
        <p>Լենա Գևորգյան</p>
        <p> bravo.am</p>
        <p> 25.10.2012</p>
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freedom));
