import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function Freedom() {
  return (
    <div className='main-container'>
      <div className='breadcrumb container'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/opinion7' className='breadcrumb-active'>
          ԾԱՂՐԱՆԿԱՐՆ ՈՒ ՕՊԵՐԱՆ
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong> ԾԱՂՐԱՆԿԱՐՆ ՈՒ ՕՊԵՐԱՆ</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item7.jpeg' alt='icon' className='img-fluid' />
        <p>
          Շաբաթ օրը ներկայացում նայեցի։ «Վերապատմում» վավերագրություն- իմպրովիզացիա։ Նանոր Պետրոսյանի ներկայացումն ա։
          Մոտավորապես գիտեի ինչ պետք ա լինի ներկայացման մեջ՝ տարիքի բերումով 88-ն ու հետագա տարիները չտեսածները իրանց
          լսածների հիման վրա վերապատմում են մեր ոչ հեռավոր անցյալը։ Նանորը նախ հարցազրույցներ էր արել մի խումբ
          երիտասարդների հետ, հետո դրա հիման վրա կառուցել ներկայացումը։ Մեծ հետաքրքրությամբ սպասում էի։ Տեսել էի, որ
          Նանորը իվենթի էջում շարադրել ա ներկայացման կոնցեպտը, նաեւ մի երկու հարցազրույց ա տվել, բայց դրանք չնայեցի,
          թողեցի հետոյին, որ ոչ մի չափով չկանխորոշեն տպավորությունս։ Օրը եկավ, Սարալանջի պողոտայից բարձրացող սառցակալած
          նեղլիկ փողոցով բարեհաջող հասա Եվրասիա հիմնադրամի շենքը, որտեղ խաղացվում էր ներկայացումը։ Դահլիճը լիքն էր ու
          տաք։ Բեմ չկար, ավելի ճիշտ՝ ամբողջ դահլիճն էր բեմ։ Նայողներս նստած էինք մեր աթոռներին, իսկ պատմողները,
          հիմնականում պատանեկանությունից նոր հեռացած տարիքի երիտասարդներ (ոչ դերասաններ), նստած էին մեր դիմաց՝ հակառակ
          ուղղությամբ դրված աթոռներին եւ սպասում էին, որ խաղը սկսվի։ Լույսերը հանգան, խաղն սկսվեց։
        </p>
        <p>
          Խաղն սկսվեց, սենց ասեմ, տխուր ձեւով։ Անցնելով հանդիսատեսի միջով, երիտասարդներին մոտեցավ
          մինի-բարձրակրունկ-գլամուր ոճի մի օրիորդ ու սկսեց հարցեր տալ 88-ի մասին։ Օրիորդի խոսքի հնչերանգն էնքան անբնական
          էր, որ տհաճություն էր առաջացնում եւ ցանկություն, որ օրիորդը շուտով հեռանա, բայց ավաղ, պարզվեց օրիորդը Վարողն
          էր եւ պետք ա հեռանար միայն ներկայացման ամենավերջում՝ արցունքախեղդ ու զայրացած։ Սրա մասին դեռ կպատմեմ, իսկ հիմա
          վերադառնանք հարցուպատասխանին։ Երիտասարդները պատմում էին, թե ինչ ա եղել 88-ին, որը իրանք չէին տեսել։ Պատմում
          էին՝ լսածով։ Պատմում էին անհավես, խառը, թարսուշիտակ, ու լիքը բան սխալ էին պատմում։ Սենց՝ մինչեւ վերջ, ամբողջ
          ներկայացման ընթացքում։ Վարողի հարցերը 88-ից կամաց կամաց առաջ գնացին, հասան 96-ին (96-ը մի քիչ առանձնանում էր
          նրանով, որ սրա մասին ոչ թե պարզապես թարսուշիտակ ու սխալ էին պատմում, այլ մեծ մասը ասում էին՝ բան չգիտենք),
          հետո էլի առաջ գնացին, հասան Քոչարյանի ու 2003-ի դեպքերին, հետո էլի առաջ գնացին: Էական ոչ մի բան չէր փոխվում՝
          նույն անհավես, թարսուշիտակ պատմությունները։
        </p>

        <p>
          Պատմող երիտասարդները ներկայացման մեկ շերտն էին միայն։ Կար նաեւ մեկ ուրիշ շերտ՝ բացականչողների շերտը, որ 88-ը
          տեսած սերունդն էր ներկայացնում (սրանք, ի տարբերություն երիտասարդների, դերասաններ էին, բայց նստած էին
          հանդիսատեսի հետ խառը): Ամենաբարձր բացականչողը Պապին էր՝ բոլորին ընդհատում էր անկապ ռեպլիկ-բղավոցներով, որոնց
          մեջ ոչ տրամաբանություն կար, ոչ հետեւողականություն։ Բայց ռեպլիկների մի մեծ մասը քաջ ծանոթ էին, ասենք էս կարգի՝
          «Կերան էս ժողովրդին, կերան», եւ այլն։ Մյուս բացականչողը Կինն էր, որը նստած էր ճիշտ իմ հետեւի աթոռին եւ
          հիշատակվող համարյա բոլոր դեպքերի հետ կապված ուներ անձնական հիշողություններ՝ «էդ օրը ես Անդոյի հետ էի», «Հետո
          գնացինք Աշոտենց տուն», եւ այլն։ Երրորդ բացականչողը պատմության Ուսուցիչն էր, որը հղումներ էր անում դասագրքից եւ
          անընդհատ վեճի մեջ էր համարյա բոլուր խոսողների հետ։ Չորրորդ բացականչողը (երիտասարդներից մեկի) Հայրն էր, որը
          տեղից բարձրանում ու գովերգում էր Սովետի ժամանակները։ Եւ վերջապես կար մեկը, որը ոչ թե պարզապես բացականչող էր,
          այլ Չհամբերող՝ ներխուժում էր բեմ ու սկսում էր գոռալ, թե ինչո՞վ եք զբաղված, եկեք գնանք հեղափոխություն անենք։ էս
          Չհամբերողը, ի տարբերություն մյուս Բացականչողների, ոչ թե անցյալի ականատեսներից էր, այլ ներկայի ներկայացուցիչը,
          դատելով որոշ դետալներից, քաղաքացիական շարժումների ներկայացուցիչը։ Չհամբերողի ներխուժումը բեմ կրկնվեց երեք
          անգամ։ Առաջին երկու անգամները Վարողը փորձում էր Չհամբերողին համոզել որ հեռանա, Վարողին օգնության էր գալիս
          Նանորը, միասին կարողանում էին խանգարողին հեռացնել, ու ներկայացումը շարունակվում էր։ Երրորդ անգամ նույնպես ի
          վերջո Նանորին հաջողվեց Չհամբերողին հեռացնել, բայց Վարողը հուսահատ հեկեկում էր եւ նույնպես թողեց գնաց։ Մնացին
          երիտասարդները։ Տիրեց լռություն։ Եվ լռության մեջ, ներկայացման վերջում, լրագրող Անի Հովհաննիսյանը որ հենց Անի
          Հովհաննիսյան էր խաղում, հանկարծ սկսեց պատմել ոչ թե որպես վերապատմող, այլ որպես մասնակից՝ ներսից։ 2008-ից էր
          պատմում, թե ոնց կար հաղթանակի սպասում ու չկար վախ, մահվան մասին ընդհանրապես չէիր մտածում, մահն ի՞նչ կապ ուներ։
          Բայց եղան կրակոցներ ու եղան մահեր։ Ու դրանից հետո ինչ հանրահավաքի կամ երթի էլ մասնակցեր, ոգևորության հետ
          միասին միշտ կար վախը, կար գիտակցությունը, որ հանգիստ կարող են կրակել։ Անին պատմում էր, իսկ Բացականչողները չէին
          բացականչում, ոչ էլ մինի-բարձրակրունկ-գլամուր-շոու ոճի Վարողը կար։ Մարդը պատմում էր ու պատմածը պատմություն էր,
          ծաղրանկար չէր։ Իսկ մինչեւ էդ ներկայացման մեջ ամբողջը ծաղրանկար էր եղել։ Ծաղրանկար, որից ծիծաղդ չէր գալիս։ Ու
          ոչ էլ լացդ էր գալիս։ Ամեն ինչ իջեցված էր մի հարթություն, որտեղ ոչ տաք կար ոչ սառ, գոլ էր ամեն ինչ ու
          դատարկ-անիմաստ։
        </p>

        <p>
          Արդեն ասեցի, որ իվենթի էջում դրված էր ներկայացման հղացք-կոնցեպտը, նաեւ մի քանի հարցազրույց՝ Նանորի եւ
          խաղացողների մասնակցությամբ։ Ներկայացումից հետո նստեցի, ուշադիր կարդացի-նայեցի դրանք։ Կոնցեպտը բավական խճճված
          էր, բայց տալիս էր բանալին, իսկ հարցազրույցները հաստատում էին, որ բանալին ճիշտ եմ տեսել։ Արարատ
          հեռուստաընկերության «Բարեւ ես եմ» հաղորդաշարին տված հարցազրույցում Նանորը սենց ա ձեւակերպում ներկայացման
          խնդիրը՝ «կասկածի տակ դնել պատմությունը»։ Հետո նորից՝ «կասկածի տակ դնել պատմություն երեւույթը»։ Իսկ ներկայացման
          մասնակիցներից Մուրադ Վարձելյանը նույն հարցազրույցի վերջում, դարձյալ խոսելով ներկայացման առանցքային գծի մասին,
          կրկնում ա Նանորի ասածը պատմությունը կասկածի տակ դնելու մասին, հետո ավելացնում՝ «պատմությունը սխալ ա , ինքը չի
          կարող լինել ճիշտ»։ Մուրադն իհարկե ներկայացման բեմադրիչը չի, բայց կարծես իրա տված էս ձեւակերպումը ճշգրիտ
          արտացոլում ա ներկայացման, ոնց որ ինքն ա ասում՝ «մեջտեղի գիծը», էն գիծը, որի արդյունքում ստացվել ա էն, ինչ որ
          ստացվել ա։ Բայց ես, ամեն դեպքում, ելնեմ միայն Նանորի ասածից՝ կասկածի տակ առնել պատմությունը, եւ փորձեմ տեսնել
          թե դա ոնց ա իրագործված ներկայացման մեջ։
        </p>

        <p>
          Կասկածի տակ ենք առնում պատմությունը։ Շատ լավ։ Իսկ ո՞ւր ա էն, ինչը կասկածի տակ ենք առնում, ո՞ւր ա Հայաստանի
          անցած 27 տարիների պատմությունը։ Չլինի՞ դասագրքերում։ Ներկայացման մեջ, ոնց որ ասեցի, կա պատմության
          Ուսուցիչ-բացականչողը։ Լսելով էդ բացականչողի ասածները, կարելի ա մտածել, թե Լեւոն Տեր-Պետրոսյանը դեռ Հայաստանի
          նախագահն ա ու ինքն էլ գրել ա մեր պատմության դասագրքերը։ Այնինչ բոլորս էլ գիտենք, որ իրականությունը ճիշտ
          հակառակն ա՝ էսօր իշխանության մեջ չեն ոչ Լեւոն Տեր-Պետրոսյանը, ոչ էլ Ղարաբաղ կոմիտեի անդամներից որեւէ մեկը։ Եվ
          Ղարաբաղ կոմիտեն էլ մեր պատմության դասագրքերում ոչ միայն կենտրոնական դեր չունի, այլ հաճախ ուղղակի չկա։ Ուրեմն
          ո՞ւմ ա ներկայացնում էդ ուսուցիչ-բացականչողի իբրեւ թե «հավաքական» (ոնց որ Նանորն ա ասում հարցազրույցում)
          կերպարը: Ոչ մեկին։ Ոչ միայն հավաքական չի, այլեւ տակը ընդհանրապես դատարկ ա։ Ղարաբաղ կոմիտեն եւ ընդհանրապես՝
          ամբողջ 88-ի շարժումը համարյա արտաքսված են ոչ միայն մեր դասագրքերից, այլ նաեւ հեռուստատեսային էկրանից։ Բայց չէ՞
          որ կան մասնակից վկաները, չէ՞ որ էդ շարժումը շշմելու զանգվածային էր, դրան հայաստանցիների մի մեծագույն մասն էր
          մասնակցում։ Ո՞նց ա պատահել, որ ներկայացման երիտասարդներից գոնե մեկը 88-ի շարժման մասնակցի սերունդ չէր,
          մասնակցի՛, ոչ թե հեռու ականատեսի։ Բոլորը նենց էին վերապատմում 88-ն ու հետագա տարիները, ոնց որ դրանց մասին
          իրանց պատմողը՝ հայրը, մայրը, պապը կամ տատը ոչ թե մասնակից կամ գոնե ականատես էին եղել, այլ իմացել էին իրանց
          հարեւան Վալոդի պատմածով։ Հարցազրույցում Նանորն ասում ա, թե Բացականչողների «հավաքական» կերպարները, որոնք 88-ը
          տեսած սերունդն են ներկայացնում, նույնպես «գեներացվել են» երիտասարդների պատմածների հիման վրա։ Փաստորեն դուրս ա
          գալիս, հենց դրա արդյունքում ենք ունեցել, որպես Հայաստանի Հանրապետությունը հիմնադրած սերունդը ներկայացնող
          «հավաքական կերպարներ», մի ախմախ պապի, մի ծիծաղելի պատմության ուսուցիչ, կորած Անդոյի մասին հոգոց հանող մի կին
          եւ Սովետի ժամանակները փառաբանող մի պապա։ Ես ինքս հենց էդ սերնդից եմ՝ 88-ին մասնակցած սերնդից։ Ես 88-ը տեսել եմ
          ներսից։ Տեսել եմ էն, ինչ կատարվում էր Օպերայում։ Իսկ կատարվում էր էն, որ ստեղծվում էր Հայաստանի
          Հանրապետությունը։ Չկար ու ստեղծվում էր՝ Մոսկվայի դեմ պայքարում։ Էդ ա պատմությունը։ Ես տեսել եմ։ Ու մենակ ես
          չէ, որ տեսել եմ։ Ու եթե պատմությունը կասկածի տակ դնելու խնդիր կար, հենց է՛ս պատմությունն ա, որ պետք ա կասկածի
          տակ դրվեր։ Բայց եթե մի բանը կասկածի տակ ես առնում, էդ բանը պետք ա ցույց տրված լինի, որ երեւա՝ ի՛նչն ես կասկածի
          տակ առնում։ Հակառակ դեպքում պատմությունը ոչ թե կասկածի տակ ա առնվում, այլ ջնջվում ա։ Վերացվում։
        </p>

        <p>
          Գիտեմ, քանի որ անձնական փորձի մասին հիշատակեցի, տեղիք եմ տալու պնդումի, թե ես սուբյեկտիվ եմ, մեր սերունդը
          ներկայացման մեջ հաճելի գույներով չի ներկայացված, դրա համար եմ ջղայնացել։ Նման մի պնդում արդեն իսկ կար Արա
          Նեդոլյանի հետ Նանոր Պետրոսյանի քննարկման մեջ։ Կանխելով առարկումը ասեմ, որ այո, ես սուբյեկտիվ եմ։ Ցանկացած
          մարդկային կարծիք սուբյեկտիվ ա, քան որ հիմնում ա էդ կարծիքն արտահայտողի ունեցած փորձի, գիտելիքի,
          պատկերացումների վրա։ Բայց սուբյեկտիվ լինել չի նշանակում սխալ լինել։ Արվեստն էլ ա միշտ սուբյեկտիվ։ Էդ թվում՝ էս
          ներկայացումը։ Ու խնդիրը բնավ սուբյեկտիվության փաստի մեջ չի, այլ դրա արդյունքում ստեղծվող բովանդակության։ Ի
          դեպ, ներկայացման մեջ, ոնց որ վերեւում պատմեցի, կա մի բացականչող՝ Անհամբերը, որը 88-ը տեսած մեր սերնդից չի,
          նորերից ա, ամենավերջին քաղաքացիական շարժումներից։ Նանորը հարցազրույցի մեջ ասում էր, որ երիտասարդները, դատելով
          հարցազրույցներից, հիմնականում շատ լավ են վերաբերվում վերջին քաղաքացիական շարժումներին։ Եթե մի պահ ընդունենք,
          թե մյուս բացականչողների ծաղրանկարային բնույթը երիտասարդների պատմածներից էր գալիս, ուրեմն ինչո՞ւ ա Անհամբերի
          կերպարը նույնպես ծաղրանկարային՝ ոնց որ մյուս բացականչողներինը։ Եւ ընդհանրապես՝ ինչո՞ւ ա ծաղրանկարը, գրոտեսկը
          ընտրված որպես ներկայացման հիմնական տոնայնություն։ Որտեղի՞ց ա ծնվել Վարողի գլամուրային կերպարը։ Չէ՞ որ Վարողը
          հենց էն կերպարն ա, որը սկսում ա ներկայացումը, տալիս ա հարցերը եւ կանխորոշում ա ամբողջ խոսակցության
          տոնայնությունը։ Տվյալ դեպքում՝ գլամուր-ծաղրանկարային։ Մի՞թե գլամուրով հնարավոր ա կասկածի տակ դնել
          պատմությունը։
        </p>

        <p>
          Եվ միայն վերջին պահին, ներկայացման ավարտից ընդամենը երկու րոպե առաջ, երբ որ բեմից հեռանում ա Վարողը եւ լռում
          են Բացականչողները, Երիտասարդների խոսքը հանկարծ փոխվում ա, նույն մարդիկ են, բայց տոնայնությունն ուրիշ ա, եւ Անի
          Հովհաննիսյանը պատմում ա 2008-ի իրա փորձի մասին։ Այ եթե գտնվեր մեկը, որ կասկածի տակ առներ Անիի պատմածը,
          կպարզվեր՝ ներկայացումը նոր ա սկսվում, նորից ա սկսվում։ Բայց ավաղ, ներկայացումն ավարտվեց։
        </p>

        <p>Մարինե Պետրոսյան</p>
        <p>Tert.am</p>
        <p>24.12.2015</p>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freedom));
