import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

let data = require('./lang/lang.json');

function we() {
  const text = data[localStorage.getItem('language')];
  return (
    <section className='main-container current-container'>
      <div className='section-details'>
        <div className='row m-0 position-relative'>
        <div class='white-decoration'>
					  <img src='images/white-svg.svg' alt='asv' />
					</div>
          <div className='col-lg-6 pl-0 we-img-container'>
            <img src='images/we/we-item.jpeg' alt='' />
          </div>
          <div className='main-description-banner col-lg-5'>
            <h2 className='title-generic text-uppercase'>{text.we}</h2>
            <p dangerouslySetInnerHTML={{ __html: text.we_text }}></p>
          </div>
        </div>
      </div>
    </section>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(we));
