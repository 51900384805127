import Slider from 'react-slick';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './weconnect.css';
import { scrollIntoView } from './ScrollHelper';
const data = require('./lang/lang.json');

class MasterOhan extends Component {
  state = {
    showItem: '',
  };

  render() {
    const text = data[localStorage.getItem('language')];
    const slickerSettings = {
      customPaging: (i) => {
        if (i > 4) {
          return <div></div>;
        } else {
          return <button>i</button>;
        }
      },
      slidesToShow: 5,
      slidesToScroll: 1,
      speed: 500,
      centerPadding: '0',
      centerMode: true,
      centerMargin: '0',
      variableWidth: false,
      className: 'bem',
      adaptiveHeight: false,
      autoplay: true,
      dots: true,
      arrow: false,
      autoplaySpeed: 2500,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerPadding: '0',
            centerMode: true,
            centerMargin: '0',
            slidesToShow: 3,
            slidesToScroll: 0,
            adaptiveHeight: false,
            dots: true,
            arrow: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            centerPadding: '0',
            centerMode: true,
            centerMargin: '0',
            slidesToShow: 1,
            slidesToScroll: 0,
            adaptiveHeight: false,
            dots: true,
            arrow: false,
          },
        },
      ],
    };
    return (
      <section className='main-container current-container'>
        <div className='breadcrumb container'>
          <a href='/we-play'>{text.weplay_title}/</a>
          <a href='/masterOhan' className='breadcrumb-active'>
            {text.mo_title}
          </a>
        </div>
        <div className='section-details modal-slider slider-centered'>
          <div className='row m-0 position-relative'>
            <div className='white-decoration'>
              <img src='images/white-svg.svg' alt='asv' />
            </div>
            <div className='col-lg-6 p-0 pr-lg-4'>
              <img src='images/master-ohan/master-ohan-item.jpeg' alt='master-ohan' />
            </div>
            <div className='main-description-banner col-lg-5 p-lg-0'>
              <p className='mb-0'>{text.mo_title}</p>
              <p>{text.mo_author}</p>
              <div className='info-block'>
                <p dangerouslySetInnerHTML={{ __html: text.mo_desc }}></p>
                <p dangerouslySetInnerHTML={{ __html: text.mo_date }}></p>

                <p dangerouslySetInnerHTML={{ __html: text.mo_cast }}></p>
                <a
                  href='#!'
                  onClick={() => scrollIntoView('video-link-master-ohan')}
                  className='info-link  d-block mb-2 mt-3'
                >
                  {text.video_av}
                </a>
              </div>
            </div>
            <a href='/regimeInPanic' className='col-lg-1 p-0 next-page desktop-next'>
              <p> {text.next}</p>
              <img src='images/arrow.svg' alt='' />
            </a>
          </div>

          <Slider {...slickerSettings}>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img
                src='images/master-ohan/master-ohan-sml-item1.jpeg'
                alt=''
                data-target='#carouselGallery'
                data-slide-to='0'
              />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img
                src='images/master-ohan/master-ohan-sml-item2.jpeg'
                alt=''
                data-target='#carouselGallery'
                data-slide-to='1'
              />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img
                src='images/master-ohan/master-ohan-sml-item3.jpeg'
                alt=''
                data-target='#carouselGallery'
                data-slide-to='2'
              />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img
                src='images/master-ohan/master-ohan-sml-item4.jpeg'
                alt=''
                data-target='#carouselGallery'
                data-slide-to='3'
              />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img
                src='images/master-ohan/master-ohan-sml-item5.jpeg'
                alt=''
                data-target='#carouselGallery'
                data-slide-to='4'
              />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img
                src='images/master-ohan/master-ohan-sml-item1.jpeg'
                alt=''
                data-target='#carouselGallery'
                data-slide-to='5'
              />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img
                src='images/master-ohan/master-ohan-sml-item2.jpeg'
                alt=''
                data-target='#carouselGallery'
                data-slide-to='6'
              />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img
                src='images/master-ohan/master-ohan-sml-item3.jpeg'
                alt=''
                data-target='#carouselGallery'
                data-slide-to='7'
              />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img
                src='images/master-ohan/master-ohan-sml-item4.jpeg'
                alt=''
                data-target='#carouselGallery'
                data-slide-to='8'
              />
            </div>
            <div data-toggle='modal' data-target='#GalleryModal'>
              <img
                src='images/master-ohan/master-ohan-sml-item5.jpeg'
                alt=''
                data-target='#carouselGallery'
                data-slide-to='9'
              />
            </div>
          </Slider>
          <div className='modal fade' id='GalleryModal' tabIndex='-1' role='dialog' aria-hidden='true'>
            <div className='modal-dialog' role='document'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                <div className='modal-body'>
                  <div id='carouselGallery' className='carousel slide' data-ride='carousel'>
                    <div className='carousel-inner'>
                      <div className='carousel-item active'>
                        <img className='d-block w-100' src='images/master-ohan/master-ohan-item1.jpeg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/master-ohan/master-ohan-item2.jpeg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/master-ohan/master-ohan-item3.jpeg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/master-ohan/master-ohan-item4.jpeg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/master-ohan/master-ohan-item5.jpeg' alt='' />
                      </div>
                      <div className='carousel-item '>
                        <img className='d-block w-100' src='images/master-ohan/master-ohan-item1.jpeg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/master-ohan/master-ohan-item2.jpeg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/master-ohan/master-ohan-item3.jpeg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/master-ohan/master-ohan-item4.jpeg' alt='' />
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block w-100' src='images/master-ohan/master-ohan-item5.jpeg' alt='' />
                      </div>
                    </div>
                    <a className='carousel-control-prev' href='#carouselGallery' role='button' data-slide='prev'>
                      <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                      <span className='sr-only'>Previous</span>
                    </a>
                    <a className='carousel-control-next' href='#carouselGallery' role='button' data-slide='next'>
                      <span className='carousel-control-next-icon' aria-hidden='true'></span>
                      <span className='sr-only'>Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p></p>
          <div id='video-link-master-ohan' className='video-link'>
            <iframe
              title='master-Ohan'
              height='661'
              src='https://www.youtube.com/embed/gaH2eDJmkaA'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            ></iframe>
          </div>
          <a href='/regimeInPanic' className='col-lg-1 p-0 next-page mobile-next'>
            <p> {text.next}</p>
            <img src='images/arrow.svg' alt='' />
          </a>
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MasterOhan));
