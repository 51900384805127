import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function Freedom() {
  return (
    <div className='main-container container'>
      <div className='breadcrumb'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/opinion9' className='breadcrumb-active'>
          «Վերապատմում». ներկայացում որոնողական տեխնիկայով
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong> «Վերապատմում». ներկայացում որոնողական տեխնիկայով</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item9.jpeg' alt='icon' className='img-fluid' />
        <p>
          Հայաստանում վերջերս բեմադրված <a href='rewriting-the-history.html'>«Վերապատմում» </a> թատերական ներկայացումը
          դարձավ այն փորձը, որտեղ կիրառվեց հարց տալու հիմնարար իրավունքն ու սկզբունքը: «Վերապատմումը» փոքր խմբի ուժերով
          ստեղծված դոկումենտալ իմպրովիզացիա է, որը բեմադրված է վերբատիմ (verbatim) տեխնիկայով: Տեքստն ու գործողությունը
          հիմնված են լինում իրական մարդկանց հարցազրույցների վրա, որոնք նախապես ձայնագրվում են, սղագրվում ու հետո
          վերածվում դրամատուրգիական տեքստերի ու դերասանական նյութի: Այդպիսի տեխնիկայով են աշխատում նաև գրողները, որոնք
          առավելագույնս մոտեցնում ու միմյանց փոխկապակցում են գրականությունն ու լրագրությունը: 2015թ. Նոբելյան
          մրցանակակիր Սվետլանա Ալեքսիևիչը այդ տեխնիկայով է գրում իր վեպերը՝ օգտագործելով առաջին դեմքով ներկայացվող
          հարցազրույցների ձևաչափը: Վերբատիմ թարգմանաբար նշանակում է «բառացի»: Վերբատիմը ժանր չէ, այլ գործիք, որի միջոցով
          կարելի է թե՛ պամֆլետներ, թե՛ դրամաներ բեմադրել: Բայց բոլոր դեպքերում պիտի լինի վավերագիր հումք, քանի որ
          ենթադրվում է, որ ներկայացումները արագ ու արդիական են, ինչպես թռուցիկ-լրագրերը ու միաժամանակ՝ մի քիչ ավելին,
          քան օրվա թեժ թեման որսած լրագրողական նյութերը: Այդ տեխնիկայով արված ներկայացումների էությունը ռիթմն է, նրանք
          արագ են ծնվում, արագ վերափոխվում, ու արագ էլ մահանում են, եթե լավ չեն վերափոխվում մի քանի անգամ խաղալուց հետո:
          «Վերապատմումը» (գաղափարի հեղինակ ու ռեժիսոր Նանոր Պետրոսյան) կառուցվում է այն հարցի շուրջ, թե ինչ գիտեն
          երիտասարդները Հայաստանի վերջին մի քանի տասնամյակների պատմության մասին: Այսինքն, իրենց ծնողների երիտասարդության
          ու Հայաստանի անկախության տարիների կայացման մասին: Ռեժիսորը հարցազրույցներ է վարել քսանամյա երիտասարդների հետ
          ու նրանց ասածները համադրելով ու մոնտաժելով՝ ստեղծել դրամատուրգիա: Երիտասարդները, որոնք դերասաններ չեն, խաղում
          են իրենց դերերը՝ երբեմն անգիր արած կտորներ ասելով, երբեմն դիմելով իմպրովիզացիայի: Բոլորը նստած են այնպես,
          ինչպես սովորաբար նստում են հեռուստատեսային շոուների ժամանակ ու բանավեճի պատրանք ստեղծում: Կա նաև այս թատերական
          շոուն վարողի կերպար, որը հարց է տալիս, ընդհատում կամ շարունակում է թոք-շոուի պերսոնաժների հուշերի հոսքը
          (ընտանիք, 1990-ականների կենցաղային պայմաններ, քաղաքական գործիչքների մասին կցկտուր մտքեր, Արցախի պատերազմ,
          նախագահական ընտրություններ, 2008 թ. մարտի 1 և այլն ):
        </p>
        <p>
          Ներկայացումը խնդիր է դնում. կասկածի տակ առնել պատմությունն ու պարզել, թե ովքեր են պատմություն գրողները: Եվ
          պարզվում է, որ պատմությունը նման է շիլայի, որտեղ ով, ինչ հասցրել ավելացրել, խմբագրել, աղավաղել կամ ջնջել է:
          Երիտասարդների հուշերն ու մտքերը այնքան խառն ու հատվածային են, որ բարդ է ոչ միայն հասկանալ նրանց, այլև պարզապես
          հետևել մենախոսություն-երկխոսություններին: Ի վերջո, որքան մարդ, այնքան պատմություն: Եվ որքան պատմություն,
          այնքան մեկնաբանություն: Դրան գումարած՝ մեկնաբանության մեկնաբանություն… Պատմությունը անըմբռնելի է որպես
          վավերագրություն, բայց միշտ վերապատմելի է որպես իմպրովիզացիա: Եվ ուրեմն, միշտ սուբյեկտիվ է: Ահա այսպիսի պարզ ու
          անգամ տրիվիալ եզրակացություն է արվում «Վերապատմումը» դիտելուց հետո: Էքսպերիմենտալ այս թատերական ներկայացման
          թերի կողմերին (հիմնականում կապված սակավ թատերայնության հետ) զուգահեռ աշխատում է նաև շատ հաճելի ու բովանդակալից
          իլյուստրատիվ ֆոնը: Այն ամենն, ինչի մասին խոսվում կամ հիշատակվում է, իր վիզուալիզացիան է ստանում էկրանին՝
          ինտերնետային որոնողական համակարգերի միջոցով: Բեմադրիչը փնտրում է պատմությունը վերապատմողներին, բայց ամենից լավ
          ու շատ թատերային կերպով դա անում է ավտոմատացաված համակարգը. մեր սիրելի բարեկամ Google-ը: Ներկայացման մեկ այլ
          գործող անձը՝ սեքսապիլ քարտուղարուհին, բեմում նստած Google-ին հրահանգներ է տալիս ու խոշորացնում էկրանին այն
          պատմական դեպքերը, վայրերը, առարկաներն ու հանրագիտարանային եզրերը, որոնց մասին թեկուզ հպանցիկ հիշատակվում է:
          Ասենք, խոսեցին հոսանքազրկված տարիների կամ Բուզանդի փողոցի բնակիչների մասին, ու միանգամից տեսանք, թե այդ
          բանալի-բառերով ինչ է առաջարկում ինտերնետը: Տեսանք Փավստոս Բուզանդի դիմանկարը (ու՞մ մտքով կանցներ անել դա) կամ
          Վիքիփեդիայում տեղ գտած մի հատուկ գլուխ՝ անվանված «մութ ու ցուրտ տարիներ» (իսկ ինչու՞ ոչ՝ դա արդեն միֆ է,
          թեկուզ բացասական ու անհարգալից երանգով): Google-ն իր առաջին էջով ու ինֆորմացիայի հիերարխիկ դասավորությամբ
          ամենից լավն է վերապատմում պատմությունը: Ի վերջո, պատմության հետ ծանոթանալ ցանկացողները հենց այսպես էլ վարվելու
          են: Երիտասարդները, որոնք իրենց մաշկի վրա չեն զգացել Հայաստանի ցնցումնային փոփոխությունները, այդ մասին
          տեղեկանալու են ոչ այնքան ծնողներից կամ կարծրացած դասագրքերից, այլ ավելի շուտ՝ «որոնել» կոճակը սեղմելով ու
          իրենց հարցը google անելով: Կարևորը, իմանաս, թե ինչպես և ինչ բառերով ձևակերպել հարցը: Ամենակարևորը հարցն է:
        </p>

        <p>Նունե Հախվերդյան</p>
        <p>Media.am</p>
        <p>03.09.2016</p>
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freedom));
