import './weconnect.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

function Freedom() {
  return (
    <div className='main-container'>
      <div className='breadcrumb container'>
        <a href='/opinion'>Կարծիք /</a>
        <a href='/opinion10' className='breadcrumb-active'>
          «Վերապատմումի» վերապատմում
        </a>
      </div>
      <div className='description-banner opinion-container'>
        <p>
          <strong>«Վերապատմումի» վերապատմում</strong>
        </p>
      </div>
      <div className='opinion-container'>
        <img src='images/opinion/opinion-item11.jpeg' alt='icon' className='img-fluid' />
        <p>
          Անցած տարեվերջին գնացի դիտելու անցած դարավերջի մասին Նանոր Պետրոսյանի բեմադրությունը` «Վերապատմում.
          վավերագրություն- իմպրովիզացիա»: Ներկայացման ֆեյսբուքյան էջից արդեն տեղեկացել էի, որ 90- ականներից հետո ծնված
          «երեխաները» պատմելու են 90-ականներից առաջ եղած դեպքերի մասին` որպես վավերագրություն: Աֆիշը հրաշալի
          ներկայացնում էր ժանրն ու գաղափարը` 88-ի միտինգների խունացած սև-սպիտակ լուսանկար, որի ճակատային շարքի մի քանի
          ցուցարար գունավոր ճեպախզբզիկ ընդգծվել էին, ինչպես դպրոցական դասագրքերում են երեխաները խզբզում մեր մեծերի
          նկարների վրա: Ախ, այս երեխաները, ամեն ինչ փչացնում են: Գնալուց կորա: «Ազատություն» պողոտայով մի երկու անգամ
          վերուվար անելուց հետո տաքսիստը հարցրեց` վարչապետի տան մոտ ա՞, վարչապետի տան տեղը չգիտեի: Տաքսիստը ցույց տվեց:
          Մթության մեջ տեսա հսկա առանձնատան ուրվագծերը. ուրեմն գնում էի մի ներկայացման, որ գուցե ասեր, թե ինչպես 88-ի
          շարժումից ծնվեց այս դղյակը: Դե դղյակների շարժումն էլ վաղուց է սկսվել, ու դրա արդարացումը Հայաստանի անկախության
          տարիքն ունի: Երբ օպերայի «ժողովրդի ծոցից» կոմկուսի դաչաների վրա մատ թափ տվողները, որ քննադատում էին
          իշխանությունը ամրոցներով ազգից պատնեշածների վրա, իրենք տեղավորվեցին այնտեղ, հուսախաբվածներին բացատրեցին, թե
          իշխանավորը պիտի լավ ապրի, որ արժանավայել ներկայացնի երկիրը: Բայց պոստմոդեռնիզմն ասում է` պետք չէ պատմությունը
          գնահատել` հետագա դեպքերից ելնելով. «Պատմությունը գրելուց հիշիր, որ Վաթերլոուի ճակատամարտի առավոտը չգիտեիր, որ
          Նապոլեոնը պարտվելու է»: Պոստմոդեռնիզմը իզուր չհիշեցի. ներկայացումը պատմության մասին դրա դրույթներն էր ասես
          ոգեկոչում. հրաժարվել մետանարատիվից, ընդհանրական, լեգիտիմ սխեմաներից, բեմ հանել քաղաքական պատմությունից դուրս
          մնացածներին, հայտնաբերել անտեսված, շրջանցված կամ լուսանցքային միկրոպատմություններ` «պատմությունը ներքևից»,
          լուրջ ընդունել իռացիոնալը, քանի որ նաև հավատալիքներով ու երազանքներով, զգացմունքներով ու կրքերով է
          պայմանավորված մարդկային խմբերի գործունեությունը, և հակառակը` էնքան էլ լուրջ չընդունել ռացիոնալ, ակադեմական
          գրառվածը, առհասարակ` հարցի տակ դնել պատմության մասնագիտացումը, արժևորել շարքային ականատեսների վկայությունները
          և այլն: Այս ամենն, իհարկե, կարևոր է մեր պատմաբանության պարագայում, բայց ինչպե՞ս էր գործադրվել ներկայացման մեջ:
        </p>
        <p>
          Նախ` ժանրը` վավերագրական թատրոն, վերբատիմ (լատ.` բառացի, բառ առ բառ), որ ներկայացումը կառուցում է իրական
          մարդկանց հետ նախապես արված հարցազրույցների բառացի խոսքերից: Հարցազրույցների հետ աշխատելու տարբեր եղանակների
          պատճառով արդեն վերբատիմ թատրոնը ինքնուրույն տեսակ է ընկալվում: Նման ժանրը սովորաբար կյանքի է կոչվում` հանուն
          թատրոնի դեմոկրատացման ու ակտուալացման, որպեսզի իշխող մեդիայի շրջանցած թեմաներն ու դիտակետերը բեմ հանի` առանց
          գեղարվեստական պաճուճանքների: Բայց ինչո՞ւ է Նանորը դրա անհրաժեշտությունն զգացել Հայաստանի նման փոքր երկիր ու
          մեծ ֆեյսբուք ունենալու պարագայում, երբ 88-ի մասին բոլոր պատմությունները, ի տարբերություն Սարոյանի ասածի, արդեն
          պատմված են հազար անգամ` սրճարաններից փոխանցվելով համացանցային տիրույթ (ներկայացման ժամանակ մտածում էի` տեսնես
          ի՞նչ ընթացք կունենային 88-ի դեպքերը, եթե էն օրերին ինտերնետ լիներ, ֆեյսբուքյան աղմուկի պարագաներում հնարավոր
          կլինե՞ր անկախությունը): Գուցե ուզեցել է հենց աղմո՞ւկը բեմ հանել, որպեսզի մենք` ասուններս, տեսնենք ունիվերսալ
          մտավորականից մինչև «ժողովրդի ձայն» կոչվածի ու սրանց արանքում տեղավորվող բոլոր հնարավոր ձայների համահարթեցումը`
          համացանցային «խոսքի ազատության» պայմաններում: Այս միտումով հնարավոր կլիներ բացատրել բեմադրության աուդիո
          անգրավչությունը, բայց դրա համար հարկավոր էր շեշտել բաբելոնյան անհոդաբաշխության «տհաճությունը», որպեսզի
          ներկայացումը չկախվի արանքում: (Այս թեմայով հետաքրքիր կլինի Աննա Ժամակոչյանի «Ներկայացում աղմուկի մասին»
          գրությունը): Ուրեմն երկրորդ` ինտերնետը, որ Սովետի փլուզումից ու անկախության հռչակումից տարիներ անց միայն պիտի
          վերջապես իրագործեր անկատար հեղափոխությունը` բազմակարծության, խոսքի ազատության տարածքներ ստեղծելով փակվող ու
          ճնշումների ենթարկվող ԶԼՄ-ների պայմաններում, մյուս կողմից էլ` փսորված ամբողջատիրականի փոխարեն դնելով տարբեր
          «ցիտատներից» կազմված էկլեկտիկ մի տեքստ, ինչպիսին ներկայացնում է հենց ինքը ներկայացումը: Այդպես ներկայացման
          ընթացքում հնչող ձայներից մեկը, որ ուղղում-ժխտում-լրացնում է պաշտոնական պատմության տեքստը, ինտերնետից է: Իսկ
          պաշտոնականը հնչում է հանդիսատեսի մեջ նստած դերասանի` պատմության դասատուի բերանից (Համլետ Չոբանյան): Երրորդ`
          շարքային մարդկանց պատմությունները, որ ներկայացնում էին մի կողմից` հանդիսատեսի դիմաց նստած «իրական»` իրենք
          իրենց անունից խոսող երիտասարդները, մյուս կողմից` հանդիսատեսի մեջ նստած դերասանները` մի պապիկ (Արթուր
          Մանուկյան), մի կին (Մարինե Պետրոսյան) ու մի անհամբեր երիտասարդ (Հայկ Զորիկյան), որ ժամանակ առ ժամանակ բեմ էր
          վազում` ջղայնանալով նստած խոսողների վրա, հեղափոխություն էր ուզում և` հիմա՛: Իսկ նրանց կապող մեդիումը զրուցավար
          հաղորդավարուհին էր (Մարիա Սեյրանյան): Այսպիսով, «Վերապատմումի» մեջ վերբատիմը համադրված էր խաղարկային տարրերի
          հետ:
        </p>
        <p>
          Դերասանները օտարացման կամ ցուցադրական մեթոդով ներկայացնում էին արխետիպերի, «եռաչափ» հոգեբանական խորքով
          կերպարների փոխարեն` «միաչափ» ու մի հատկանշի մարմնավորումների` նրանց հանդեպ դիստանցված վերաբերմունքով, ինչի
          պատճառով ծաղրանկար ու դիմակ էին ընկալվել բանաստեղծ Մարինե Պետրոսյանի գրախոսականում և թատերագետ Արա Նեդոլյանի
          ֆեյսբուքյան մեկնաբանություններում: Հանդիսատես- հասարակությունը կարող էր անմասնակից դիտորդ լինել, կարող էր
          հրահրվել իր միջից հա «անկապ» դուրս տվողների միջամտություններից ու ինքն էլ դառնալ ներկայացման ձայներից (բայց դե
          Հայաստանում հանդիսանքային մշակույթը դեռ նման ավանդույթներ չի ձևավորել): Այսպիսով, ներկայացումը կարծես խոսում
          էր Հայաստանի նորագույն պատմության մի ողջ ժամանակաշրջանի մասին` ձայն տալով պաշտոնական պատմագրությանը,
          պաշտոնական մեդիային և դրանից դուրս մնացած համացանցային պատմություններին, իրական մարդկանց ու հասարակ ժողովուրդ
          ասածին և մտավորականներին` հանձին խոսելու կամ լռելու ընտրություն ունեցող հանդիսատեսի: Այսինքն` ներկայացումը
          ենթադրում էր ընդգրկել Հայաստանի բնակչության ողջ երանգապնակը: Թերևս իսկապես այդպես լիներ, եթե հանդիսատեսը
          մայրաքաղաքի էլիտան չլիներ, ու, առհասարակ, սա ոչ թե ներկայացում կոչվեր, այլ ինչ-որ այլ հանրային միջոցառման
          անվան տակ «ներխուժեր» ծայրամասային կամ մայրաքաղաքից դուրս պատահական մի դահլիճ: Ներկայացում «չներկայացողը»
          կարող էր վերածվել փոխազդու (ինտերակտիվ) դիալոգի. հանդիսատեսը կմիջամտեր, ու դրան կարձագանքեին դերասաններն ու
          երիտասարդները` ապահովելով ներկայացման վերնագրում եղած, բայց չստացված իմպրովիզացիան: Իսկ ի՞նչն էր ստացվել.
          բարեբախտաբար, ներկայացումը չէր տեղավորվում միայն պատմության հանդեպ պոստմոդեռնիստական հայացքի ծիրում: Գուցե
          պատմական դեպքերը պատմասացությամբ ավելի հասկանալի ու հավաստի են, քան պատմաբանական մոդելների կառուցմամբ ու
          վերլուծությամբ, բայց ներկայացման մեջ, կամա-ակամա, խնդրականացվում էր նաև պատմասացությունը: Նախ` երիտասարդների
          խոսակցությունը կառուցված էր հեռուստատեսային ռեալիթի-շոուի նմանությամբ, ու նրանց ձայն էր տալիս Նազենի
          Հովհաննիսյանին բացահայտ հիշեցնող մի «հաղորդավարուհի»: Եվ զանգվածային մեդիայի նմանակումը շեշտում էր նաև
          մարդկանց անկեղծությունը ուղղորդող-շահարկող, մեդիա-պրոդուկտի վերածող գործոնը: Հետո` երիտասարդներն իրականում ոչ
          թե պատմում, այլ վերապատմում էին իրենց ծնողներից ու մտերիմներից լսած պատմությունները: Բերնեբերան փոխանցովի
          վկայությունն արդեն ոչ թե, կամ ոչ միայն միկրոպատմությունները որպես պատմություն լեգիտիմացնելու խնդիր ուներ, այլ,
          կրկին` կամա-ակամա, հարցադրում էր հենց բուն պատմելու գործողությունը: Երիտասարդների պատմածները արթնացնելու էին
          հանդիսատեսի սեփական հուշերը կամ լսածները` բեմից հնչողի հետ համադրությամբ կամ հակադրությամբ ցույց տալով, թե
          ինչպես է գործում հիշողությունը` հաճախ խմբագրվելով ու վերակառուցվելով հետագա դեպքերի լույսի ներքո` որպես
          ընտրովի ու փոփոխական «տեղեկատվություն»` ըստ հիշողի քաղաքական դիրքավորման: Անցյալի կարևոր հատկանիշներից մեկն
          այն է, որ երբ ու ինչքան նայում ես` փոխվում է: Հիշողության ու պատմելու գործընթացը հրաշալի ներկայացված է,
          օրինակ, Բիգաս Լունայի «Սպասուհին Տիտանիկից» ֆիլմում` երևակայության ու իրականության միահյուսմամբ: Բայց եթե
          ֆիլմի հերոսը «Տիտանիկի» ծով ելնելու պատմական դեպքին իր անձնական մասնակցության դրվագը, որ իրականում
          մասնակցություն էլ չի, այլ լուռ ներկայություն, պատմում ու վերապատմում է հուզիչ մանրամասներով` հանդիսատեսի
          պահանջարկի համապատասխան ավելի ու ավելի «լավացնելով» ու գեղարվեստականացնելով, ապա ներկայացման պատմություններն
          առանձնապես գրավիչ չէին: Երիտասարդների խոսքը հանդիսատեսին չէր հմայում, նրանք «լավ» չէին պատմում, իսկ լավ պատմել
          նշանակում է ստեղծագործել, դրվագը կառուցել, գործողությունների զարգացում, կոնֆլիկտ ու հանգուցալուծում ստեղծել:
          Այսինքն` հորինել: Մինչդեռ իրականությունը հնարավորինս ճշգրիտ վերարտադրելու փորձը ենթադրում է կցկտուր, անկապ ու
          հակասական պատառիկներ` ըստ պատմողի տեսադաշտի, որի զուգահեռ, հատվող, սկզբից ու վերջից շարունակվող թելերը
          այլուրեք են, ուրիշ մասնակիցների տեսադաշտում. պատմողը միայն մի հատվածում է, քանի դեռ չարք Թուլաթ կամ բարք
          Ֆիրդուսի (դասական հեղինակ) չի դարձել` այստեղ, այնտեղ, ամենուրեք: Այսինքն` քանի դեռ պատումը ամենագետ երրորդ
          դեմքի դիտակետից չէ, որը իր ձեռքը կառնի բոլոր տարբեր գույների թելերը և ի մի բերելով` կկծկի ողջ իրադարձության
          կծիկը` իր պատգամելի նախշի սիրույն: Ահա թե ինչու ամենագետ երրորդ դեմքով պատումի հետևողական շարադրանքն ու
          պատճառահետևանքային ժամանակագրական կառուցումը, որ ավելի բնորոշ էր մինչմոդեռնին, 20-րդ դարասկզբից սկսած` զանց է
          առնվում արվեստի ու գրականության շատ գործերում` փոխարինվելով հատվածային, կցկտուր, պատահական շարակարգով` որպես
          իրականության ընկալման ավելի հավաստի պատկերի: Եվ ներկայացման վավերական տեքստը ինչ-որ առումով ասես cut-up
          գեղարվեստական հնարքով է աշխատում` համադրվելով խաղարկվող հատվածների հետ և ստեղծելով զուգադրվող ու հակադրվող
          տեքստերով համաժամանակյա պատում: Այդպիսի բազմապատկումը պատմությունը դարձնում է պատմություններ, միակ
          «ճշմարտությունը»` տրոհված հակասական պատառիկներից հյուսվող-քանդվող-այլաշարավերահյուսվող «ճշմարտություններ»: Այս
          առումով` երիտասարդների պատմությունները կարող են ոչ միայն կամ ոչ այնքան վավերական, այլ հենց գեղարվեստական
          ընկալվել, այսինքն` վավերականը գեղարվեստական կառուցմամբ է դառնում վավերական: Ու սա անտեսելը նշանակում է տուրք
          տալ ռեալիթի շոուների կոմերցիոն հնարքին, երբ խաղարկվում է իսկականությունը որպես գրավիչ ապրանք: Երիտասարդների
          վկայությունները վկայում էին նաև մարմնի և իշխանության փոխահարաբերության մասին: Նրանց զրույցը չի գրավում, քանի
          որ «սեքսի» չի: Իսկ «սեքսի» չի, քանի որ նրանք իրենց սեփական մարմիններով ներկա չեն եղել 88-ի դեպքերին, իսկ այ
          «էլեկտրիկ Երևանի» տեսանյութերն ու 2008-ի մասին պատմողի խոսքը գրավիչ է` մարմնական մասնակցության գործոնի
          բերումով: Ականատեսի փորձը էրոտիկ լիցք ունի: Հեղափոխությունների, պատերազմների, աղետների «հմայքը» բոլոր
          օրենքների առկախումն է, երբ ամեն ինչ կարելի է: Մարմինները ազատագրվում են առօրյա կյանքի պայմանականություններից,
          տաբուներից, ճիշտ ու բարոյական համարվող կանոններից: Խախտելի է ամեն ինչ, վերանում են բոլոր արգելքները, ու
          մարմինը հայտնվում է բացարձակ ազատության մեջ: Ահա այդ մարմնի ու իշխանության բիրտ մեքենայի հանդիպադրումն է, որ
          հմայում է. ի՞նչ կա ավելի գրավիչ, քան տանկերի շարասյան առջև միայնակ կանգնած անզեն մարդը, ի՞նչ կա ավելի «սեքսի»,
          քան ջրցան մեքենայի ուժեղ շիթի դեմ կանգնած թաց մարմինը (երկու դեպքն էլ` 1989-ի չինացի «տանկ-մարդու» ու
          «Էլեկտրիկ Երևանի» ջրվող երիտասարդի պատկերը լավագույն լուսանկարների պատմություն են մտել): Մարմնի ու բռնություն
          մարմնավորող մեքենայի, զենք ու տեխնիկայի հանդիպման վիզուալացումը լավ հաջողություն ունի նաև առևտրային էրոտիզմի
          ոլորտում, և հաճախ գրավչության ակնկալիքը խաչվում է հենց այս սպառողական վերաբերմունքի հետ:
        </p>
        <p>
          Իսկ երիտասարդների վերապատմումն էլ, խաչվելով հանդիսատեսի միջի Կնոջ վերապատմումին, շարունակում է մարմնի թեման:
          Կինը ներկա է եղել դեպքերին սեփական մարմնով և այդ իրադարձությունները կարող է «վկայել» միայն իր մարմնի
          հիշողություններով, անձնական կյանքի պրիզմայով դիտված` ըստ իր ու տվյալ պահի դեպքերի հետ ուղղակիորեն կապված
          տղամարդու սիրային կապի: Նրա ռեպլիկների «անարժեքությունը» հիշեցնում է, թե ինչպես է Պատմությունը դուրս նետում
          քաղաքականորեն արտաբերվելու լեզու չունեցող կյանքերը: Պատմությունը, բայց ոչ արվեստը: Կինը չի կարող բացատրել, թե
          ինչպես է խորտակվել իր անձնական կյանքը` հեղափոխության ու պատերազմի ատամնանիվների տակ ընկնելով. տղամարդիկ եկել
          ու գնացել են` ռազմի դաշտ, հարթակ կամ հրապարակ: Այն օրերին իրեն գնահատել են. լուրջ գործի լծված տղամարդկանց
          գուրգուրել է, փայփայել, խնամել, կռվածի վերքն է վիրակապել, հետապնդվողին ապաստան տվել, նրանց տներն ու օպերայի
          հարթակն է ավլել, ու իրեն էլ հայրենասիրական գործի մասնակից են համարել, գովել ու կենացն են խմել, խոստումներ
          տվել: Հեղափոխությունից ու պատերազմից հետո տղամարդիկ գնացել են իրենց ուրիշ լուրջ գործերին, ու մնացել է ինքը`
          միայնակ ու ծիծաղելի` արդեն հետհեղափոխական օրինավոր կյանքի (գուցե նաև բեմադրողի) դատափետող հայացքի ներքո` որպես
          մերկ ու հեշտ մարմին: Այդպիսի քաղաքական համրերից է նաև Պապիկը, որ, ըստ ամենայնի, նախկին Սովետի
          բանվորա-գյուղացիական խավից է ու Թումանյանի ծերունու պես` իր «հին ադաթից ընկել է, զրկվել, նորն էլ չգիտի, թե ինչ
          է եկել»: Չէ, նորը գիտի` աշխատանքով վաստակած ապահով ծերության փոխարեն` ամոթալի քարշ տրվող գոյություն, բայց դե
          դա քաղաքական պահանջներով չի կարող ներկայացնել ու դատապարտված է Պատմության աղբանոցը նետվելու: Բայց արվեստը
          հաճախ հենց նման «աղբանոցներից» է պեղում իր նյութը, և եթե խնդիրը նրա «համրությունը» ցույց տալն է, ուրեմն
          ռեժիսորը նրան «լեզու» չի տալիս:
        </p>

        <p>
          Եվ այսպես, ստացվում է պարադոքս, որ իրական անտեսված վկայությունները, երիտասարդների վավերական խոսքի փոխարեն,
          ներկայացվում են դերասանների խաղարկային մասով` նրանց «անարժեք» միկրոբլթոցներով, որոնք ոչ մի տեղ չունեն ու չեն
          կարող ունենալ մեծ պատմության մեջ: Բազմաթիվ կանայք, ծերեր ու երեխաներ, որոնց կյանքի բարելավմանն են խոստանում
          լծվել բոլոր հեղափոխություններն ու քաղաքականությունները, բայց իրենք բացակա են պատմությունից, քանի որ անլեզու
          են, միայն փնչում, «բլթցնում», տնքում ու հառաչում են, ու դրա համար էլ վտարված են մեծ գաղափարների
          հերոսապատումից: «Տիտանիկն» էլ իզուր չհիշեցի. Քամերունի հայտնի բլոկբասթերից կարճ ժամանակ անց էր, որ Բիգաս
          Լունան նկարեց իր «Տիտանիկը»` առաջինի` էպիկական ռոմանտիկ դրամայի փոխարեն, լույսերով ողողված նավում ծավալվող
          սիրո ու հերոսական անձնազոհության փոխարեն, ծեր կնոջ փայլուն ու հավաստի հիշողության հետաթարթմամբ վեր հառնող
          պատմության փոխարեն` ոչ միայն ստեղծելով ամեն դրվագի լույսն իր ստվերով, հիշողությունն իր մշուշով, սերն ու
          անձնազոհությունն իր եսակենտրոն պրագմատիկ նմանակմամբ, արվեստն իր «կեղծիքով», այլև` «Տիտանիկն» առանց Տիտանիկի:
          Ճիշտ այդպես նաև «Վերապատմումն» է 88-ի մասին` առանց 88-ի: Ներկայացումը Հայաստանի անկախության ու արցախյան
          պատերազմի մասին բեմադրվող ու նկարահանվող հերոսական ու գեղեցիկ պատմությունների փոխարեն` բեմ է հանում այդ նույն
          պատմությունների զանցած ստվերները, աննշմար ու անպաթոս կյանքերի ուրվագծերը, որոնց պատվանդանի վրա են վեր խոյանում
          կոթողային դյուցազներգությունները: Պապիկն ու կինը «Մի վախեցիր» ֆիլմի Արմանի պես չեն վերափոխվում ու հերոսանում
          պատերազմի դաշտում, որպեսզի սեփական կյանքի գնով ապացուցեն մեծ գաղափարների արժեքը, նրանց մասին երևի
          բանաստեղծներն են հիշում: Ու ի տարբերություն Մաքսիմ Գորկու` հեղափոխության շնչով գրված «Վալախական լեգենդի»
          անեծքի`
        </p>
        <div>
          <p> Իսկ դուք աշխարհում կապրեք </p>
          <p> Որդերի նման կույր ու սին,</p>
          <p> — Ձեր մասին ո՛չ զրույց կասեն, </p>
          <p> Ո՛չ երգեր կերգեն ձեր մասին. ․․ </p>
          <p>hետհեղափոխական հայացքով` ինչպես Տանյա Հովհաննիսյանը. </p>
          <p> Հեյ, գեշությամբ զարդարուննե՛ր-թերինե՛ր… </p>
          <p> Հեյ, դո՛ւք-դո՛ւք եք պահում երկինքը մեր գլխավերեւում, </p>
          <p> Դո՛ւք եք պահում արեւը կայտառ- մեր գլխավերեւում, </p>
          <p> Դո՛ւք եք զորացնում ծաղիկները ցածում- դաշտերում, </p>
          <p> Դո՛ւք եք սրբում- հայելիները- որ գեղեցիկներն երեւան այնտեղ </p>
          <p> ու իրենց տեսնեն… </p>
        </div>
        <p>
          Ներկայացումն, այսպիսով, Հայաստանի անկախացման հերոսական պատմությունից վտարվածներին է բեմ հանում ու տարիքի
          բերումով չմասնակցածներին: Իսկ ինչո՞ւ հապա 88-ի ու դրա գործող անձանց, վավերական պատմության հիշատակմամբ ու
          շեշտումով է ծանուցվում: Մանավանդ, որ 88-ը դեռ պատմություն չէ, պատմություն կարող է դառնալ միայն այն ժամանակ,
          երբ կբացվեն ԿԳԲ-ի արխիվները, իսկ առայժմ դեռ քաղաքական կապիտալ է, որ շահարկվում է տարբեր քաղաքական գործիչների
          կողմից` տարբեր ձևերով ու նպատակներով: Իսկ գուցե Նանոր Պետրոսյանի այս բեմադրությունը նույնպե՞ս այդ քաղաքական
          կապիտալով արվեստ անելու փորձ է: Բայց սա «Վերապատմումի» իմ վերապատմումն է, իսկ ներկայացումը կարծես մի տեսակ
          ինքնագիտակից մետաշեշտեր չունի:
        </p>

        <p>Վիոլետ Գրիգորյան</p>
        <p>Inknagir.com</p>
        <p>29.04.2016</p>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freedom));
