import Slider from 'react-slick';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import './weplay.css';
import './bem.css';
const data = require('./lang/lang.json');
function NextArrow(props) {
  const { onClick } = props;
  return (
    <button type='button' className='slick-next pull-right slick-arrow d-block' onClick={onClick}>
      <img src='images/next-arrow.svg' className='img-fluid' alt='arrow' title='arrow' />
    </button>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <button type='button' className='slick-prev pull-left slick-arrow d-block' onClick={onClick}>
      <img src='images/prev-arrow.svg' className='img-fluid' alt='arrow' title='arrow' />
    </button>
  );
}

function kondFestival() {
  const text = data[localStorage.getItem('language')];
  const slickerSettings = {
    infinite: true,
    centerPadding: '20px',
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 500,
    centerMode: true,
    variableWidth: false,
    centerMargin: '0',
    className: 'bem',
    autoplay: true,
    arrow: false,
    dots: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '0',
          slidesToShow: 1,
          slidesToScroll: 0,
          adaptiveHeight: false,
          dots: true,
          prevArrow: <PrevArrow />,
          nextArrow: <NextArrow />,
        },
      },
    ],
  };

  return (
    <section className='main-container main-container-decoration kond-container we-play-container'>
      <div className='description-banner'>
        <h2 className='title-generic'>{text.kond}</h2>
        <p dangerouslySetInnerHTML={{ __html: text.kond.desc }}></p>
      </div>
      <section className='center-slider'>
        <Slider {...slickerSettings}>
          {[...Array(6)].map((e, i) => (
            <div className='slide' key={`kond${i}`}>
              <div className='slider-title'>
                <h2>{text[`kond${i + 1}`]}</h2>
              </div>
              <div className='child-element'>
                <div className='deco1 animate animate__animated animate__bounce animate__delay-0 animate__fadeInDown'>
                  <img src='images/decoration1.svg' alt='icon' className='img-fluid' />
                </div>
                <a href={`/kondfest${i + 1}`}>
                  <img
                    src={window.location.origin + `/images/kond_fest/kond${i + 1}.jpg`}
                    alt='icon'
                    className='img-fluid'
                  />
                </a>
                <div className='deco2 animate animate__animated animate__bounce animate__delay-0 animate__fadeInDown'>
                  <img src='images/decoration2.svg' alt='icon' className='img-fluid' />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </section>
    </section>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANGUAGE', language }),
});

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(kondFestival));
